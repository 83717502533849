@import "../../../colors.scss";

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.loading-icon {
  fill: $petroleum-80;
  animation: spin 2s linear infinite;
}