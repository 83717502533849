@import '../../variables';
@import '../../colors';

.modal-background {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  transform: translateZ(0);
  @include transparentColorMixin($petroleum-100, .95);
}

.modal-inner {
  /* Positioning */
  box-sizing: border-box;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  padding: 1.6rem;
  overflow-y: auto;

  /* Other */
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 425px) {
    left: 50%;
    height: auto;
    max-width: $narrowContentWidth;
    transform: translate(-50%, 3.2rem);
    max-height: calc(100% - 3.2rem)
  }
}

.modal-close {
  text-decoration: none;
  align-self: flex-end;
  font-size: 1.6rem;
  font-weight: bold;
  color: white;
  margin-bottom: 0.8rem;
}

.modal-content {
  background-color: white;
}