@import '../../colors';

.select-wrapper {
  display: inline-block;
  position: relative;
  margin: 0.4rem 0 0.4rem 0;

  &:after {
    content: ""; //url(./ChevronDown.svg);
    width: 3.2rem;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    border-left: 1px solid $petroleum-80;

    // Arrow image
    background-image: url(./ArrowDown.svg);
    background-repeat: no-repeat;
    background-position: center;

    pointer-events: none;
  }
}

.fullWidth {
  width: 100%;
  margin-bottom: 30px;
}

.select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: inherit;

  color: $petroleum-80;
  border: 1px solid $petroleum-80;
  border-radius: 4px;
  background-color: white;
  padding: 0.8rem 4rem 0.8rem 0.8rem;
  font-weight: bold;
  font-size: 1.2rem;
}

/* Remove default arrow in IE 11 */
.select::-ms-expand {
  display: none;
}