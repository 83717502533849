.card-list {
  list-style: none;
  margin: 0;
  padding: 0;

  > li {
    margin-bottom: 0.8rem;
  }
}

.card-list--collapsed {
  z-index: 0;
  position: relative;
  perspective: 1000px;
}

.card-list--collapsed > li {
  position: relative;
  transform-origin: 50% 0;

  &:hover {
    transform: none;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.15);
  }

  &:nth-child(n+2) {
    height: 10px;
    overflow: hidden;

    *:not(a) {visibility: hidden}
  }

  &:nth-child(n+5) {
    display: none;
  }

  &:nth-child(1) {
    z-index: 6;
    transform: translateY(0) scale(1);
  }

  &:nth-child(2) {
    z-index: 5;
    transform: translate3d(0, -38px, -30px);
  }

  &:nth-child(3) {
    z-index: 4;
    transform: translate3d(0, -80px, -100px);
  }

  &:nth-child(4) {
    z-index: 3;
    transform: translate3d(0, -120px, -150px);
  }
}