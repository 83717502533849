/* autoprefixer grid: autoplace */
@import '../../colors';


.main-header {
  
  grid-area: header;
  box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.15);
  z-index: 1;
  font-size: 1.4rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  // position: fixed;
  // bottom: 0;
  // height: 88px;
  // width: 221px;

  .company-logo {
    height: 4rem;
    margin-left: 1.6rem;
  }

  .building-name {
    margin-left: 1.6rem;
    flex-grow: 1;
  }

  .building-and-contract {


    .dropdown-wrapper {
      margin-right: 0.8rem;
    }

    .text {
      font-size: 1.6rem;
      margin: 1.6rem 0.4rem 1.6rem 0.4rem;
    }
  }

  .user-context {
    display: flex;
    align-items: center;

    .notification-panel {
      padding-right: 1.6rem;
    }

    .logout-button {
      border-left: 1px solid $petroleum-10;

      > svg {
        fill: $petroleum-100;
        margin: 0;
        padding: 1.6rem;
        box-sizing: content-box;
        &:hover {
          opacity: .5;
        }
        //IE-11 support, can't use auto
        @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
          width: 20px;
          height: 20px;

        }
      }

    }
  }

}