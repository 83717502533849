@import '../../colors';

$input-box-height: 2.9rem;
$label-font-size: 1.4rem;
$label-line-height: 1;

@mixin move-label() {
  transform: translate(0, 0.4rem) scale(.75);
  font-weight: bold;
  color: $petroleum-100;
}

.wrapper {
  position: relative;
  margin-bottom: 0.8rem;

  .label {
    font-size: 1.4rem;
    display: inline-block;
    position: absolute;
    transform-origin: top left;
    transform: translate(0, 1.4rem) scale(1);
    transition: all .1s ease-in-out;
    color: $petroleum-100;

    &[data-active=true] {
      @include move-label();
      color: $waves-60 !important;
    }
  }

  &[data-hasvalue=true] {
    .label {
      @include move-label();
    }
  }
}

.text-field {
  border: none;
  background-color: transparent;
  font-size: 1.6rem;
  color: $petroleum-100;
  padding: 1.6rem 0 0.8rem 0;
  border-bottom: 1px solid $petroleum-40;
  width: 100%;
  outline: none;

  &:focus {
    color: $waves-60;
    border-color: $waves-60;
  }
}