@import "../../colors.scss";

.error-message {
  border: 1px solid $rose-60;
  padding: 0.8rem;
}

.helptext {
  margin-top: 5rem;
}

.validation {
  text-align: center;
}

.validation-text {
  margin-top: 1.6rem;
}