@import "../../../colors";

.building-list,
.links-list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.building-list {
  display: flex;
  flex-wrap: wrap;
  max-width: calc(100vw - 900px);

  .building-list-item {
    margin-bottom: 0.8rem;
    margin-right: 0.8rem;

    flex-grow: 1;
    max-width: 35rem;
  }
}

.links-list {
  .links-list-item {

    padding: 1.6rem;

    &:not(:last-child) {
      margin-bottom: 0.8rem;
    }

    >a {
      font-weight: bold;
      color: $waves-80;
      margin: -1.6rem;
      padding: 1.6rem;
    }

    .links-list-item-content {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      position: relative;

      .arrow-icon {
        position: absolute;
        right: 0;
        transform: rotate(-90deg);
      }

      >svg {
        fill: $waves-80;

        &:not(.arrow-icon) {
          margin-right: 1.6rem;
        }
      }
    }

  }
}

.building-header-block {
  padding: 3.2rem;
  margin: -1.6rem -1.6rem 1.6rem -1.6rem;
  text-align: center;
  position: relative;
  background-color: white;
  z-index: 2;

  &:after {
    // Element to hide the top of the box-shadow.
    content: "";
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    z-index: -1;
    background-color: white;
  }

  &:before {
    // Box-shadow bottom
    content: "";
    position: absolute;
    bottom: 1px;
    width: 100%;
    z-index: -1;
    left: 0;
    transform: scale(.99);
    box-shadow: 0 0 10px 1px #839097;
  }

  >h3 {
    color: $waves-80;
    font-size: 2rem;
    font-weight: normal;
    margin-top: 0.8rem;

    span {
      font-size: 1.6rem;
      text-transform: capitalize;
    }
  }
}

@media screen and (min-width: 769px) {
  .building-header-block {
    margin: -1.6rem -3.2rem 1.6rem -3.2rem;
  }
}