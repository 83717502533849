@import '../../variables';
@import '../../colors';

.modal-background {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  transform: translateZ(0);
  @include transparentColorMixin($petroleum-100, .95);
}

.modal-inner {
  position: fixed;
  box-sizing: border-box;
  top: 0;
  padding-top: 3.2rem;
  margin-bottom: 1.6rem;
  left: 50%;
  height: calc(100% - 1.6rem);
  width: 100%;
  max-width: $narrowContentWidth;
  transform: translate(-50%);
  overflow-y: hidden;
}

.modal-close {
  color: white;
  font-size: 1.6rem;
  font-weight: bold;
  position: absolute;
  right: 1.6rem;
  top: 1rem;
  text-decoration: none;

  &:hover {
    color: white;
    text-decoration: underline;
  }
}

.modal-content {
  height: 100%;
  margin-left: 1.6rem;
  margin-right: 1.6rem;
}