@import '../colors';

.headerBold40 {
    font-size: 40px;
    font-family: InterstateBoldCompressed;
    color: #33434B;
    line-height: 48px;
}

.dataRow {
    background-color: #F6F5ED;
    min-height: 40px;
    font-family: InterstateLight;
    font-size: 11px;
    color: #33434B;
    margin-bottom: 4px;

    * {
        font-family: InterstateLight;
        font-size: 13px;
    }

    a {
        width: 100%;
        display: inline-block;
        padding: 1.6rem;
    }
}

.dataRow:hover {
    background-color: #cce0ff;
}

.blackActionBtn {
    background-color: $ns-blue06;
    font-size: 13px;
    font-family: InterstateRegular;
    border-radius: 4px;
    padding: 10px 20px;
    color: white;
    cursor: pointer;
}

.sortButtonsLabel {
    color: #33434B;
    font-family: InterstateLight;
    font-size: 14px;
    margin-right: 20px;
}

.filtersHeader {
    color: #333333;
    font-size: 22px;
    font-family: InterstateBold;
    display: block;
    margin-bottom: 10px;
}

.filterCatLabel {
    font-size: 14px;
    font-family: InterstateRegular;
    color: #333333;
    min-width: 65px;
    margin-right: 10px;
    display: inline-block;
}

.filterBtn {
    box-sizing: border-box;
    transition: all .2s ease-in-out;
    white-space: nowrap;
    border: 1px dotted $ns-blue05;
    background-color: white;
    color: $ns-blue05;
    padding: 8px 16px;
    font-size: 13px;
    font-family: 'InterstateRegular';
    border-radius: 3px;
    display: inline-block;
    cursor: pointer;
    margin-right: 4px;
    margin-bottom: 8px;

    svg {
        height: 11px;
        margin-left: 8px;
    }
}

.filterBtn:hover {
    background-color: #F4F4F4;
}

.filterBtnActive,
.filterBtnActive:hover {
    border: 1px solid $ns-blue05;
    background-color: $ns-blue05;
    color: #fff;

    path {
        fill: white;
    }
}

.filterCatWrapper {
    margin-bottom: 8px;
}

.searchBoxInput {
    border: 1px dotted grey;
    height: 33px;
    width: 200px;
    font-family: 'InterstateRegular';
    font-size: 14px;
    padding: 0 10px;
}

.sortBtnsWrapper {
    margin-bottom: 15px;
    display: block;
    overflow: auto;
    max-width: 800px;
}

.brightBtn {
    padding: 20px 40px;
    border: 1px solid transparent;
    background-color: $ns-blue05;
    color: white;
    border-radius: 5px;
    font-family: 'InterstateRegular';
    font-size: 15px;
    line-height: 15px;
    display: block;
    cursor: pointer;
    width: max-content;
    margin: 0 auto;
    text-transform: uppercase;
}

.disabledBrightBtn {
    padding: 20px 40px;
    border: 1px solid transparent;
    color: white;
    border-radius: 5px;
    font-family: 'InterstateRegular';
    font-size: 15px;
    line-height: 15px;
    display: block;
    width: max-content;
    margin: 0 auto;
    text-transform: uppercase;
    cursor: default;
    background-color: #e0e0e0;
}

.brightBtn:hover {
    background-color: white;
    color: #333334;
    border: 1px dotted #333334;
    ;
}

.fadedBtn {
    font-family: 'InterstateRegular';
    font-size: 13px;
    margin-top: 24px;
    background-color: $ns-blue02;
    color: white;
    display: inline-block;
    border: 1px solid transparent;
    border-radius: 5px;
    padding: 15px 30px;
    cursor: pointer;
}

.fadedBtn:hover {
    color: #90999E;
    border: 1px dotted #90999E;
    background-color: white;
}

.validationError {
    color: #C60018;
    font-family: 'InterstateLight';
    font-size: 13px;
}

@media screen and (max-width: 768px) {
    .brightBtn {

        width: 100%;
    }

}