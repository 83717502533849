@import '../../colors';

.documentsTable{
  width:100%
}

.categories-wrapper {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  margin-bottom: 3.2rem;
  list-style-type: none;
}
.category-element {
  display: block;
  margin-right: 0.8rem;
  margin-bottom: 0.8rem;
}

.all-categories {
  margin-bottom:0.8rem;
  margin-right: 0.8rem;
}
.empty-list {
  text-align: center;

  header {
    margin-bottom: 1.6rem;
  }

  header > p {
    font-weight: bold;
  }

  .empty-list-hint {
    color: $waves-60;
    font-weight: bold;
  }
}

.filter-options {
  margin-bottom: 50px;
}
.filter-option {
  margin-right: 0.8rem;
  display: inline-block;
  margin-bottom: 4px;
}

.filterChoiceWrapper{
  margin-left: 100px;
  top: -20px;
  position: relative;
}