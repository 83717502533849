/* autoprefixer grid: autoplace */

@import '../../colors';

.wrapper {
  display: grid;
  grid-template-areas:
    "menu"
    "right"
    "header"
    "pageContent";

  grid-template-columns: 100%;
  -ms-grid-rows: 5rem 4fr 1fr;
  grid-template-rows: 6.7rem auto 1fr;
  transition: all 0.6s ease-in-out;
  min-height: 100vh;
  max-width: 100%;
  background-color: $main-background-color;

  .main-content {

    width: calc(100% - 221px);
    margin-left: 221px;
    margin-bottom: 100px;
  }
}

.wrapper {
  /*autoprefixer: off*/
  justify-content: center;
}

.header-wrapper {
  grid-area: header;
  margin-bottom: 1px;
}



.left-column {
  background-color: $ns-sand01;
  box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.15);
  position: fixed;
  height: 100%;
  width: 221px;
}

.right-column {
  grid-area: right;
  position: fixed;
  max-height: 100vh;
  max-width: 100vw;
  bottom: 0;
}

.main-menu {
  position: absolute;
  top: 5.8rem;
  left: 0;
  right: 0;
  z-index: 2;
  display: none;

  &--open {
    display: block;
  }
}

.nav-links-list {
  margin: 0;
  padding: 0;
  list-style: none;

  >li {
    text-align: center;
    box-shadow: 0 2px 0px -1px $ns-sand01;
    margin-right: 32px;
    margin-left: 32px;
    height: 70px;
    padding: 19px 31px;
    margin-bottom: 10px;
    border-radius: 4px;
    cursor: pointer;

    a {
      color: white;

      span {
        width: 100%;
      }
    }
  }



  .nav-link {
    color: white;
    width: 100%;
    justify-content: flex-start;
    padding: 1.6rem;
    box-sizing: border-box;

    &:hover,
    &:focus,
    &.active {
      background-color: $petroleum-80;
      color: white;

      .nav-link-icon {
        fill: white;
      }
    }
  }

  .nav-link-icon {
    fill: white;
  }
}

.bottom-nav-links-list {
  position: absolute;
  bottom: 50px;
  // margin-bottom: 100px;
  list-style: none;
  padding: 0 32px;

  li {
    width: 157px;
    height: 43px;
    text-align: center;
    background-color: #90999E;
    margin-bottom: 8px;
    border-radius: 3px;
    line-height: 44px;
    cursor: pointer;

    a {
      font-family: InterstateRegular;
      font-size: 13px;
    }
  }

  li:hover,
  .activeSidebarBtn {
    a {
      color: #90999E;
    }

    background-color: white;
  }

  li:last-of-type {
    margin-bottom: 30px;
  }
}

.logo-wrapper {
  text-align: center;
  display: none;
  padding: 23px;

  .newsec-logo {
    display: inline-block;
    width: 100%;
    height: auto;
  }
}

.new-issue-button {
  font-family: InterstateRegular;
  font-size: 14px;
  background-color: $ns-blue01;
  color: white;
}

.new-issue-button:hover,
.newIssueActive {
  background-color: white;

  a {
    color: $ns-blue06  !important;
  }
}

.new-service-button {
  font-family: InterstateRegular;
  font-size: 14px;
  background-color: $ns-blue05;
  color: white;
}

.new-service-button:hover,
.newServiceActive {
  background-color: white;

  a {
    color: $ns-blue06  !important;
  }
}

@media screen and (max-width: 768px) {
  .left-column {
    position: initial;
    width: 100%;
    height: 64px;
  }

  .wrapper {
    .main-content {
      position: absolute;
      width: 100%;
      margin-left: 0;
      margin-top: 150px;
    }
  }

  .new-issue-button,
  .new-service-button {
    display: block;
  }

  .nav-links-list,
  .bottom-nav-links-list {
    width: 100%;
  }

  .bottom-nav-links-list {
    li {
      width: 100%;
      height: 45px;
      margin-bottom: 12px;

      a {
        font-size: 15px;
        line-height: 45px;
      }
    }
  }

  .right-column {
    max-height: calc(100vh - 125px);
  }

  .main-menu {
    width: 100%;
    height: 100%;
    background-color: #5D6A70;

    ul:first-of-type {
      margin-top: 80px;
    }
  }

  .new-issue-button,
  .new-service-button {
    height: 80px !important;
    line-height: 40px;
    font-size: 22px;
    margin-bottom: 16px;
  }

}

@media screen and (min-width: 769px) {

  .page-content-wrapper {
    grid-area: pageContent;
    padding: 1.6rem 0.8rem;
    margin-top: 60px;
    margin-left: 80px;
    width: calc(100vw - 500px);
    max-width: 1200px;
    padding-left: 64px;
    padding-right: 40px;
    margin-bottom: 140px;
  }

  .hide-on-desktop {
    display: none !important;
  }

  .right-column {
    top: 6rem;
    max-width: 500px;
    right: 0;
    display: initial;
    position: absolute;
    bottom: 0;
    z-index: 100;
  }

  .wrapper {
    /*autoprefixer: off*/
    grid-template-rows: auto 1fr;
    -ms-grid-rows: 5rem 1fr;
  }

  .wrapper {
    grid-template-areas:
      "menu header"
      "menu pageContent right";
    grid-template-columns: 22rem 1fr;

    .page-content-wrapper {
      padding: 1.6rem 1.6rem 1.6rem;
    }

    .header-wrapper {
      position: absolute;
      margin-left: 230px;
      width: calc(100% - 237px);
      z-index: 1;
    }
  }

  .logo-wrapper {
    display: block;
  }

  .main-menu {
    position: initial;
    display: block !important;

    //IE-11 support, needed for grid areas to stretch properly.
    @media screen and (-ms-high-contrast: active),
    (-ms-high-contrast: none) {
      min-height: 100vh;

    }
  }
}

@media screen and (min-width: 1365px) {
  .page-content-wrapper {
    width: calc(100vw - 730px);
  }
}