/* autoprefixer grid: autoplace */
@import '../../colors';

.ServiceFormWrapper {
  margin-bottom: 78px;
}

.service-canvas {
  border: 1px solid black;
  border-radius: 5px;
  width: 35rem;
  margin-top: 50px;
}

.service-category {
  height: 12.8rem;
  padding: 0.8rem;
  font-size: x-large;
}

.service-subcategory {
  border-top: 1px solid black;
  padding: 0.8rem;
  font-weight: bold;
}

.service-subcategory-expanded {
  border-top: 1px solid black;
  padding: 0.8rem;
  font-weight: bold;
  height: 12.8rem;
}

.service-subcategory-downarrow {
  transform: rotate(-90deg);
  float: right;
  cursor: pointer;
}

.service-subcategory-uparrow {
  transform: rotate(90deg);
  float: right;
  cursor: pointer;
}

.service-image {
  width: 35rem;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.service-subcategory-description {
  font-weight: normal;
  padding: 0.8rem;
}

.order-button {
  background-color: $waves-60;
  border: none;
  font-size: 1.8rem;
  color: white;
  height: 4rem;
  //width: 9rem;
  display: block;
  margin-left: auto;
  cursor: pointer;
}

.servicesWrapper {
  max-width: 800px;
  min-width: 600px;
  padding-left: 64px;
  padding-right: 40px;
}

.newServiceBadge,
.popularServiceBadge {
  position: absolute;
  right: 14px;
  top: 11px;
  font-size: 12px;
  font-family: 'InterstateRegularCompressed';

  padding: 2px 5px 1px 6px;
  line-height: 15px;
  letter-spacing: 0;
  border-radius: 4px;
}

.newServiceBadge {
  background: #B3DC10;
}

.popularServiceBadge {
  background: #cce0ff;
}

.circledNumber {
  position: absolute;
  right: 0;
  width: 32px;
  height: 32px;
}

.page1Image {
  float: left;
  max-width: 138px;
  border-radius: 5px;
}

.page1MidRow {
  margin-bottom: 80px;
  display: block;
  overflow: auto;
}

.page1Title {
  font-size: 22px;
  font-family: 'InterstateBold';
  display: block;
}

.page1Header {
  margin-bottom: 53px;
  color: #333333;
  text-transform: uppercase;
  text-align: center;
  font: normal normal bold 40px/48px InterstateBoldCompressed;
  letter-spacing: 0px;
  height: 48px;
  margin-top: 37px;
  display: inline-block;
}

.page1Desc {
  font-size: 14px;
  font-family: 'InterstateRegular';
  display: block;
  margin-top: 8px;
  max-width: 380px;
}

.serviceFormHeader {
  width: 100%;
}

.page1BtnWrapper,
.page2BtnWrapper {
  float: left;
  text-align: center;
  width: 100%;
}

.page1BodyWrapper {
  padding-left: 50px;
  padding-top: 30px;
  float: left;
}

.marginRight24 {
  margin-right: 24px;
}

.circle1 {
  top: 200px;
  color: #BDC7CD;
}

.circle2 {
  top: 280px;
  color: #BDC7CD;
}

.circle3 {
  top: 360px;
  color: #BDC7CD;
}

.link {
  cursor: pointer;
}

.dots1,
.dots2 {
  position: absolute;
  height: 40px;
  right: 15px;
}

.dots1 {
  top: 237px;
}

.dots2 {
  top: 317px;
}

.activeCircleBtn {
  path {
    fill: #33434B;
  }
}

.doneStepCircle {
  path {
    fill: #6B841E;
  }
}

.doneStepCircle:hover {
  path {
    fill: $lighter-blue;
  }
}

.doneStepCircle {
  line {
    stroke: #6B841E;
  }
}

.page2Wrapper {
  text-align: center;
  margin-top: 84px;
}

.page3Wrapper,
.page4Wrapper {
  text-align: center;
  margin-top: 84px;
}

.summaryBox {
  border: 1px solid #BDC7CD;
  border-radius: 5px;
  display: block;
  padding: 24px 16px;
  margin-top: 24px;
  margin-bottom: 60px;
  text-align: left;
}

.page2Address {
  color: #90999E;
  font-family: InterstateLight;
  font-size: 17px;
  line-height: 20px;
  margin-bottom: 24px;
  display: block;
}

.formLabel {
  font-size: 17px;
  font-family: InterstateRegular;
  text-align: left;
  color: #333333;
  display: block;
  margin-bottom: 10px;
}

.smallDescription {
  font-family: InterstateRegular;
  font-size: 10px;
  line-height: 12px;
  color: #333333;
  display: block;
  text-align: left;
  margin-top: -8px;
  margin-bottom: 10px;
}

.summaryDesc {
  font-family: InterstateRegular;
  font-size: 14px;
  line-height: 16px;
  color: #333333;
  display: block;
  text-align: left;
  margin-bottom: 8px;
}

textarea {
  width: 100%;
  height: 120px;
  margin-bottom: 40px;
}

.page2Title,
.page4Title {
  font-size: 22px;
  font-family: 'InterstateBold';
  display: block;
  margin-bottom: 24px;
}

.leftTitle {
  font-size: 22px;
  font-family: 'InterstateBold';
  display: block;
  text-align: left;
  margin-bottom: 16px;
}

.titleCompressed {
  font-size: 40px;
  line-height: 48px;
  font-family: 'InterstateBoldCompressed';
  display: block;
}

.singleInput,
.registeredLabel {
  width: 100%;
  font-family: InterstateLight;
  font-size: 17px;
  color: #90999E;
  padding-top: 6px;
  padding-bottom: 6px;
}

.singleInput {
  border: 1px solid #707070;
  padding: 6px 16px;
  margin-bottom: 40px;
}

.registeredLabel {
  text-align: left;
  display: block;
}

.confirmationSubHeader {
  color: #33434B;
  font-family: InterstateLight;
  font-size: 17px;
  line-height: 20px;
  display: block;
  text-align: center;
}

.confirmationSubHeaderWrapper {
  margin-bottom: 36px;
}

.wrapper50 {
  width: 50%;
  display: inline-block;
}

.wrapper50MarginLeft {
  display: inline-block;
  width: calc(50% - 10px);
  margin-left: 10px;
}

.imageUploadWrapper {
  text-align: left;
  margin-top: 10px;
  float: left;
  padding-right: 15px;
  margin-bottom: 40px;

  svg,
  .uploaderBtn {
    color: #90999E;
    margin-top: -30px;
    display: block;
  }
}

.validationMessageWrapper {
  margin-top: -33px;
  display: block;
  float: left;
}

@media screen and (max-width: 768px) {
  .servicesWrapper {
    width: 100%;
    padding-left: 40px;
    padding-right: 40px;
    margin-bottom: 78px;
  }

  .page1Image {
    max-width: 100%;
  }

  .page1BodyWrapper {
    padding-left: 0;
  }
}