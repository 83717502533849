/* autoprefixer grid: autoplace */
@import '../../colors';

.issue-details-wrapper {
  box-sizing: border-box;
  background-color: white;
  // border-top: 8px solid white;
  display: flex;
  flex-direction: column;
  height: 100%;

  &.priority--high {
    // border-top: 8px solid $rose-60;
  }

  &.priority--medium {
    // border-top: 8px solid $sun-100;
  }

  &.priority--low {
    // border-top: 8px solid $petroleum-20;
  }
}

.waitingCase {
  background-color: #F4B1BB;
}

.openCase {
  background-color: #FFE77F;
}

.finishedCase {
  background-color: #B3DC10;
}

.halfWidth {
  float: left;
  width: 50%;
}

.header {
  height: 80px;

  .headerStatus {
    display: inline-block;
    width: 200px;
    line-height: 80px;
    margin-left: 60px;
    color: #333333;
    font-family: 'InterstateLight';
  }

  .headerCaseNumber {
    width: 120px;
    line-height: 80px;
    margin-right: 30px;
    color: #333333;
    font-family: 'InterstateBoldCompressed';
    font-size: 30px;
    float: right;
  }

  .sak {
    color: #5D6A70;
    font-family: 'InterstateBoldCompressed';
    padding-right: 6px;
  }
}

.heading {
  text-transform: capitalize;
  font-size: 40px;
  color: #33434B;
  font-family: InterstateBoldCompressed;
}

.section {
  padding: 20px 40px 60px 60px;

  &:not(:last-child) {
    border-bottom: 1px solid $petroleum-20;
  }
}

.scroll-section {
  flex-grow: 1;
  position: relative;
  overflow: hidden;

  >div {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    overflow: auto;
    padding: 1.6rem;
    display: grid;
    grid-template-rows: auto 1fr;
    grid-template-columns: 100%;
  }
}

.description-border {
  border-bottom: 1px solid $petroleum-20;
}

.box-shadow {
  box-shadow: 0 2px 1px -1px $petroleum-20;
}

.issue-info {
  padding-top: 9px;

  .issue-id,
  span {
    font-family: InterstateLight;
    font-size: 14px;
    color: #33434B;
    display: block;
    text-align: right;
  }

  span:last-of-type {
    padding-top: 25px;
  }
}

.issue-location {
  font-size: 17px;
  font-family: InterstateRegular;
  color: $lighter-blue;
  text-align: right;
  margin-top: 8px;

  >span {
    padding-left: 0.8rem;
  }
}

.issue-status {
  grid-area: status;
  text-transform: uppercase;
  font-size: 1.2rem;
  font-weight: bold;
  color: $petroleum-100;
}

@media screen and (max-width: 768px) {
  .header {
    .headerStatus {
      margin-left: 15px;
    }

    .headerCaseNumber {
      margin-right: 15px;
    }
  }

  .section {
    padding: 15px 25px 35px 20px;
  }
}