@import '../../colors';

.input {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.input:focus + .label {
  outline: 1px dotted #000;
  outline: -webkit-focus-ring-color auto 5px;
}

.input:disabled + .label {
  opacity: .5;

  &:hover {
    text-decoration: none;
    cursor: default;
  }
}

.label {
  cursor: pointer;
  color: $petroleum-80;
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;

  &:hover {
    text-decoration: underline;
  }
}

.icon {
  fill: $petroleum-80;
  margin-right: 0.8rem;
  margin-top: -30px;
}

.uploaderBtn{
  color:#90999E;
    margin-top: -30px;
    display: block;
}