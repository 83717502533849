@import '../../colors';

.card, .link-card {
  border-radius: 6px;
  background-color: white;
  display: block;
  transition: all .4s ease-in-out;
  border: 1px solid #BDC7CD;
}

.card {
  padding: 1.6rem;
}

.link-card {
  .link {
    text-decoration: none;
    color: $text-color;
    padding: 1.6rem;
    display: block;
  }

  &:hover {
  }
}

@media screen and (min-width: 768px){
  .card, .link-card .link {
    padding: 1.6rem 3.2rem;
  }
}