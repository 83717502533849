@import '../../../colors';

.button {
  font-size: 14px;
  font-family: 'InterstateRegular';
  border: none;
    align-items: center;
    border-radius: 4px;
  &[aria-disabled="true"] {
    opacity: .5;
    cursor: default;
  }
}

