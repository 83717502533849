/* autoprefixer grid: autoplace */
.wrapper {
  background-color: white;
  overflow: hidden; }

.newsec-background {
  position: relative;
  box-sizing: border-box;
  background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url("./background.jpg") no-repeat center;
  background-size: cover;
  max-width: 1920px;
  margin: 0 auto; }

.osloareal-background {
  position: relative;
  box-sizing: border-box;
  background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url("./OA_background.jpg") no-repeat center;
  background-size: cover;
  max-width: 1920px;
  margin: 0 auto; }
  @media screen and (min-width: 769px) {
    .osloareal-background > div.grid-container {
      margin: 0 12.8rem 0 auto; } }

.svg-background {
  position: relative;
  box-sizing: border-box;
  background: url("./SVG_background.jpg") no-repeat center;
  background-size: cover;
  max-width: 1920px;
  margin: 0 auto; }

.grid-container {
  box-sizing: border-box;
  display: grid;
  grid-template-areas: "login-box";
  grid-template-rows: 100vh;
  grid-row-gap: 0.8rem;
  max-width: 100rem;
  min-height: 100vh;
  margin: 0 auto; }

.grid-container {
  /*autoprefixer: off*/
  align-items: start; }

.main-heading,
.login-box,
.copyright {
  z-index: 2; }

.main-heading {
  grid-area: heading;
  align-self: center;
  color: white;
  display: none; }
  .main-heading span {
    display: block; }

.login-box {
  grid-area: login-box;
  background-color: white;
  padding: 3.2rem 3.2rem 6.4rem 3.2rem;
  height: 100%; }
  .login-box .logo {
    padding-bottom: 5.6rem; }

.login-form .login-form-header {
  text-transform: none; }

.login-form .action-wrapper {
  text-align: right; }

.login-form .input-field {
  margin-bottom: 3.2rem;
  font-size: 17px;
  font-family: InterstateRegularCompressed;
  color: #5D6A70; }

.login-form .inputFieldProp {
  border: 1px solid #5D6A70; }

.login-form .error-text-color {
  color: #C4232A; }

.copyright {
  grid-area: copyright;
  text-align: right;
  color: white;
  padding-right: 3.2rem;
  display: none;
  max-height: 5rem; }

.link {
  font-size: 11px;
  font-family: InterstateRegular;
  background-color: #a1c5ff;
  border-radius: 4px;
  padding: 10px 20px;
  color: white;
  margin-left: 10px;
  margin-top: 15px;
  display: inline-block; }

@media screen and (min-width: 769px) {
  .grid-container {
    grid-template-areas: "heading login-box" ".       copyright";
    grid-template-columns: 1fr 40rem;
    grid-template-rows: 1fr auto;
    padding: 1.6rem; }
  .login-box {
    align-self: center;
    height: auto; }
  .copyright,
  .main-heading {
    display: block; } }

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .newsec-background,
  .osloareal-background {
    min-height: 100vh; } }
