/* autoprefixer grid: autoplace */
@import 'colors.scss';

html {
    font-size: 62.5%;

    &.lock-scroll {
        overflow: hidden !important;
    }
}

body {
    margin: 0;
    padding: 0;
    font-size: 1.6rem;
    color: $text-color;
}

* {
    font-family: "InterstateRegular";
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    box-sizing: border-box;
}

span{
    caret-color: transparent;
}

a {
    color: $waves-60;
    text-decoration: none;

    &:hover {
        // color: $waves-80;
    }
}

h1, h2, h3, h4, p {
    margin-top: 0;
    margin-bottom: 0;

    &:not(:last-child) {
        margin-bottom: 1.6rem;
    }
}

h1 {

}

h2 {

}

h3 {
    font-size: 1.2rem;
}
h3:not(:first-child) {
    margin-top: 3.2rem;
}

h4 {

}

label {
    font-size: 1.4rem;
}

fieldset {
    margin: 0;
    padding: 0;

    legend {
        padding: 0;
        margin: 0;
        font-size: 2.2rem;
    }
}

