/* autoprefixer grid: autoplace */
a.issue-card-body, div.issue-card-body {
  display: flex;
  justify-content: space-between; }
  a.issue-card-body--new-updates, div.issue-card-body--new-updates {
    border-left: 6px solid #1585BB; }
  a.issue-card-body--priority-high .priority, div.issue-card-body--priority-high .priority {
    color: #C4232A; }
  a.issue-card-body--priority-medium .priority, div.issue-card-body--priority-medium .priority {
    color: #EB8B2D; }
  a.issue-card-body--priority-low .priority, div.issue-card-body--priority-low .priority {
    color: #33434B; }

.issue-card-section {
  display: -ms-grid;
  display: grid;
      grid-template-areas: "description          category" "location                    ." ". id" "updateCount status";
  -ms-grid-rows: auto 1fr auto auto;
  grid-template-rows: auto 1fr auto auto;
  width: 100%; }
  .issue-card-section .id, .issue-card-section .description, .issue-card-section .location, .issue-card-section .priority, .issue-card-section .status {
    margin: 0; }
  .issue-card-section .id, .issue-card-section .priority, .issue-card-section .status {
    color: #33434B; }
  .issue-card-section .id {
    -ms-grid-row: 3;
    -ms-grid-column: 2;
    font-size: 1.2rem;
    font-weight: bold;
    grid-area: id;
    margin-top: 1.6rem; }
  .issue-card-section .description {
    -ms-grid-row: 1;
    -ms-grid-column: 1;
    font-size: 2.4rem;
    grid-area: description;
    margin-top: 0.4rem; }
  .issue-card-section .location {
    -ms-grid-row: 2;
    -ms-grid-column: 1;
    font-weight: bold;
    grid-area: location;
    display: inline-flex;
    align-items: center;
    margin-top: 0.8rem; }
  .issue-card-section .priority {
    font-weight: normal;
    display: flex;
    align-items: center;
    justify-self: end;
    justify-content: flex-end;
    grid-area: priority; }
  .issue-card-section .status {
    -ms-grid-row: 4;
    -ms-grid-column: 2;
    font-size: 1.4rem;
    background-color: #C4CED4;
    padding: 0.8rem 1.6rem;
    fill: #33434B;
    border-radius: 50px;
    text-align: center;
    grid-area: status;
    display: flex;
    justify-content: space-between;
    align-items: center; }
    .issue-card-section .status .status-icon {
      margin-right: 0.8rem; }
  .issue-card-section .update-count {
    -ms-grid-row: 4;
    -ms-grid-column: 1;
    grid-area: updateCount;
    -ms-grid-column-align: end;
        justify-self: end;
    background-color: #1585BB;
    color: white;
    padding: 0.8rem 1.6rem;
    border-radius: 50px;
    font-size: 1.2rem;
    font-weight: bold; }

.issue-card-section {
  /*autoprefixer: off*/
  align-items: center;
  justify-items: start; }

.priority-icon {
  width: 15px;
  margin-right: 0.8rem; }
  .priority-icon--high {
    fill: #C4232A;
    -webkit-transform: rotate(135deg);
            transform: rotate(135deg); }
  .priority-icon--medium {
    fill: #EB8B2D; }
  .priority-icon--low {
    fill: #33434B;
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg); }
