/* autoprefixer grid: autoplace */
.wrapper {
  display: -ms-grid;
  display: grid;
      grid-template-areas: "menu" "right" "header" "pageContent";
  -ms-grid-columns: 100%;
  grid-template-columns: 100%;
  -ms-grid-rows: 6.7rem auto 1fr;
  grid-template-rows: 6.7rem auto 1fr;
  transition: all 0.6s ease-in-out;
  min-height: 100vh;
  max-width: 100%;
  background-color: white; }
  .wrapper .main-content {
    width: calc(100% - 221px);
    margin-left: 221px;
    margin-bottom: 100px; }

.wrapper {
  /*autoprefixer: off*/
  justify-content: center; }

.header-wrapper {
  -ms-grid-row: 3;
  -ms-grid-column: 1;
  grid-area: header;
  margin-bottom: 1px; }

.left-column {
  background-color: #e0d1b8;
  box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.15);
  position: fixed;
  height: 100%;
  width: 221px; }

.right-column {
  -ms-grid-row: 2;
  -ms-grid-column: 1;
  grid-area: right;
  position: fixed;
  max-height: 100vh;
  max-width: 100vw;
  bottom: 0; }

.main-menu {
  position: absolute;
  top: 5.8rem;
  left: 0;
  right: 0;
  z-index: 2;
  display: none; }
  .main-menu--open {
    display: block; }

.nav-links-list {
  margin: 0;
  padding: 0;
  list-style: none; }
  .nav-links-list > li {
    text-align: center;
    box-shadow: 0 2px 0px -1px #e0d1b8;
    margin-right: 32px;
    margin-left: 32px;
    height: 70px;
    padding: 19px 31px;
    margin-bottom: 10px;
    border-radius: 4px;
    cursor: pointer; }
    .nav-links-list > li a {
      color: white; }
      .nav-links-list > li a span {
        width: 100%; }
  .nav-links-list .nav-link {
    color: white;
    width: 100%;
    justify-content: flex-start;
    padding: 1.6rem;
    box-sizing: border-box; }
    .nav-links-list .nav-link:hover, .nav-links-list .nav-link:focus, .nav-links-list .nav-link.active {
      background-color: #5D6B71;
      color: white; }
      .nav-links-list .nav-link:hover .nav-link-icon, .nav-links-list .nav-link:focus .nav-link-icon, .nav-links-list .nav-link.active .nav-link-icon {
        fill: white; }
  .nav-links-list .nav-link-icon {
    fill: white; }

.bottom-nav-links-list {
  position: absolute;
  bottom: 50px;
  list-style: none;
  padding: 0 32px; }
  .bottom-nav-links-list li {
    width: 157px;
    height: 43px;
    text-align: center;
    background-color: #90999E;
    margin-bottom: 8px;
    border-radius: 3px;
    line-height: 44px;
    cursor: pointer; }
    .bottom-nav-links-list li a {
      font-family: InterstateRegular;
      font-size: 13px; }
  .bottom-nav-links-list li:hover,
  .bottom-nav-links-list .activeSidebarBtn {
    background-color: white; }
    .bottom-nav-links-list li:hover a,
    .bottom-nav-links-list .activeSidebarBtn a {
      color: #90999E; }
  .bottom-nav-links-list li:last-of-type {
    margin-bottom: 30px; }

.logo-wrapper {
  text-align: center;
  display: none;
  padding: 23px; }
  .logo-wrapper .newsec-logo {
    display: inline-block;
    width: 100%;
    height: auto; }

.new-issue-button {
  font-family: InterstateRegular;
  font-size: 14px;
  background-color: #5c7fff;
  color: white; }

.new-issue-button:hover,
.newIssueActive {
  background-color: white; }
  .new-issue-button:hover a,
  .newIssueActive a {
    color: #151d3b !important; }

.new-service-button {
  font-family: InterstateRegular;
  font-size: 14px;
  background-color: #2d3e80;
  color: white; }

.new-service-button:hover,
.newServiceActive {
  background-color: white; }
  .new-service-button:hover a,
  .newServiceActive a {
    color: #151d3b !important; }

@media screen and (max-width: 768px) {
  .left-column {
    position: static;
    position: initial;
    width: 100%;
    height: 64px; }
  .wrapper .main-content {
    position: absolute;
    width: 100%;
    margin-left: 0;
    margin-top: 150px; }
  .new-issue-button,
  .new-service-button {
    display: block; }
  .nav-links-list,
  .bottom-nav-links-list {
    width: 100%; }
  .bottom-nav-links-list li {
    width: 100%;
    height: 45px;
    margin-bottom: 12px; }
    .bottom-nav-links-list li a {
      font-size: 15px;
      line-height: 45px; }
  .right-column {
    max-height: calc(100vh - 125px); }
  .main-menu {
    width: 100%;
    height: 100%;
    background-color: #5D6A70; }
    .main-menu ul:first-of-type {
      margin-top: 80px; }
  .new-issue-button,
  .new-service-button {
    height: 80px !important;
    line-height: 40px;
    font-size: 22px;
    margin-bottom: 16px; } }

@media screen and (min-width: 769px) {
  .page-content-wrapper {
    -ms-grid-row: 4;
    -ms-grid-column: 1;
    grid-area: pageContent;
    padding: 1.6rem 0.8rem;
    margin-top: 60px;
    margin-left: 80px;
    width: calc(100vw - 500px);
    max-width: 1200px;
    padding-left: 64px;
    padding-right: 40px;
    margin-bottom: 140px; }
  @media screen and (min-width: 769px) {
  .page-content-wrapper {
      -ms-grid-row: 2;
      -ms-grid-column: 2; } }
  .hide-on-desktop {
    display: none !important; }
  .right-column {
    top: 6rem;
    max-width: 500px;
    right: 0;
    display: inline;
    display: initial;
    position: absolute;
    bottom: 0;
    z-index: 100; }
  .wrapper {
    /*autoprefixer: off*/
    grid-template-rows: auto 1fr;
    -ms-grid-rows: 5rem 1fr; }
  .wrapper {
        grid-template-areas: "menu header" "menu pageContent right";
    -ms-grid-columns: 22rem 1fr;
    grid-template-columns: 22rem 1fr; }
    .wrapper .page-content-wrapper {
      padding: 1.6rem 1.6rem 1.6rem; }
    .wrapper .header-wrapper {
      position: absolute;
      margin-left: 230px;
      width: calc(100% - 237px);
      z-index: 1; }
  .logo-wrapper {
    display: block; }
  .main-menu {
    position: static;
    position: initial;
    display: block !important; }
  .header-wrapper {
    -ms-grid-row: 1;
    -ms-grid-column: 2; }
  .right-column {
    -ms-grid-row: 2;
    -ms-grid-column: 3; } }
  @media screen and (min-width: 769px) and (-ms-high-contrast: active), screen and (min-width: 769px) and (-ms-high-contrast: none) {
    .main-menu {
      min-height: 100vh; } }

@media screen and (min-width: 1365px) {
  .page-content-wrapper {
    width: calc(100vw - 730px); } }
