@import '../../colors';

.button {
  border: none;
  margin: 0;
  padding: 0.8rem 3.2rem;
  color: white;
  transition: background-color .2s ease-in-out;

  align-items: center;
  justify-content: space-around;
  background-color: transparent;
  text-decoration: none;

  &:disabled {
    opacity: 0.5;
  }

  &.confirmation-button {
    background-color: $ns-blue06;
    min-width: 120px;
    min-height: 45px;
    font-size: 13px;
    font-family: InterstateRegular;
    border-radius: 4px;

    &:hover:not(:disabled) {
      background-color: $ns-blue05;
    }
  }

  &.primary-button {
    background-color: $waves-60;
    font-size: 1.6rem;
    font-weight: bold;
    border-radius: 4px;
    outline-color: coral;

    &--collapsed {
      padding: 0.8rem;

      >span {
        display: none;
      }

      >svg {
        margin: 0;
      }
    }

    &:hover:not(:disabled) {
      background-color: $waves-100;
    }
  }

  &.secondary-button {
    background-color: transparent;
    color: $waves-60;
    font-size: 1.4rem;
    padding: 0;

    >svg {
      margin-right: 0.8rem;
      fill: $waves-60;
      width: auto;
      height: auto;
    }

    &:hover:not(:disabled) {
      color: $waves-100;

      >svg {
        fill: $waves-100;
      }
    }
  }

  &.icon-button {
    padding: 0;

    &:hover {
      opacity: .5;
    }
  }

}

.loading-icon {
  fill: white !important;
  margin-left: 0.8rem;
  width: 20px;
  height: 20px;
}