/* autoprefixer grid: autoplace */
@import '../../colors.scss';

a.issue-card-body, div.issue-card-body {
  display: flex;
  justify-content: space-between;

  &--new-updates {
    border-left: 6px solid $waves-60;
  }

  &--priority-high {
    .priority {
      color: $rose-60;
    }
  }
  &--priority-medium {
    .priority {
      color: $sun-100;
    }
  }
  &--priority-low {
    .priority {
      color: $petroleum-100;
    }
  }
}

.issue-card-section {
  display: grid;
  grid-template-areas:
    "description          category"
    "location                    ."
    ". id"
    "updateCount status";

  grid-template-rows: auto 1fr auto auto;
  width: 100%;

  .id, .description, .location, .priority, .status {
    margin: 0;
  }

  .id, .priority, .status {
    color: $petroleum-100;
  }

  .id {
    font-size: 1.2rem;
    font-weight: bold;
    grid-area: id;
    margin-top: 1.6rem;
  }

  .description {
    font-size: 2.4rem;
    grid-area: description;
    margin-top: 0.4rem;
  }

  .location {
    font-weight: bold;
    grid-area: location;
    display: inline-flex;
    align-items: center;
    margin-top: 0.8rem;
  }

  .priority {
    font-weight: normal;
    display: flex;
    align-items: center;
    justify-self: end;
    justify-content: flex-end;
    grid-area: priority;
  }

  .status {
    font-size: 1.4rem;
    background-color: $petroleum-40;
    padding: 0.8rem 1.6rem;
    fill: $petroleum-100;
    border-radius: 50px;
    text-align: center;
    grid-area: status;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .status-icon {
      margin-right: 0.8rem;
    }
  }

  .update-count {
    grid-area: updateCount;
    justify-self: end;
    background-color: $waves-60;
    color: white;
    padding: 0.8rem 1.6rem;
    border-radius: 50px;
    font-size: 1.2rem;
    font-weight: bold;
  }
}

.issue-card-section{
  /*autoprefixer: off*/
  align-items: center;
  justify-items: start;
}

.priority-icon {
  width: 15px;
  margin-right: 0.8rem;

  &--high {
    fill: $rose-60;
    transform: rotate(135deg);
  }

  &--medium { fill: $sun-100; }

  &--low {
    fill: $petroleum-100;
    transform: rotate(-45deg);
  }
}
