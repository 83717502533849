@import '../../colors';

.wrapper {
  display: flex;
  flex-direction: column;
}

.message-form {
  margin: 0 -1.6rem;
  margin-top: 50px;
  margin-bottom: -15px;

  .message-input-wrapper {
    position: relative;
    padding: 0.8rem 3.2rem 0.8rem 3.2rem;
    background-color: #F6F5ED;

    .message-textarea {
      display: block;
      box-sizing: border-box;
      width: 100%;
      resize: none;
      border: none;
      font-size: 1.6rem;
      padding-top: 0.8rem;
      padding-right: 3.2rem;
      background-color: #F6F5ED;
    }

    .message-label {}

    .message-send-button {
      padding: 0;
      position: absolute;
      top: 3.2rem;
      right: 3.6rem;
    }

    .message-send-button-icon {
      fill: $petroleum-60;
    }
  }
}

.dialog-list--empty {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  background-color: $petroleum-10;
  color: $petroleum-60;
  margin: 0 -3.2rem;

  >p {
    margin-top: 3.2rem;
    font-size: 2.2rem;
  }

  >svg {
    width: 12rem;
    height: auto;
  }
}

.dialog-list {
  flex-grow: 1;
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  >li {
    min-width: 30%;
    max-width: 100%;
  }

  .attachments-list {
    margin-top: 0.8rem;
  }

  .date-spacer {
    margin: 1.6rem auto;
    text-align: center;
    font-weight: bold;
    font-size: 1.4rem;
    color: $petroleum-60;
    font-family: InterstateRegular;
  }

  .system-message {
    margin: 1.6rem auto;
    padding: 0.8rem;
    background-color: #EAEDEE;
    border-radius: 4px;
    color: $petroleum-80;
    max-width: 100%;

    .system-message-content {
      display: flex;
    }

    .system-message-time {
      padding-right: 1.6rem;
    }
  }

  .message-time {
    font-size: 1.4rem;
    padding-left: 0.8rem;
    margin-top: -10px;
  }

  .message-by-user-wrapper {
    margin-left: auto;
    margin-right: 70px;

    .userHeadWrapper {
      text-align: right;
      display: block;
      margin-right: -60px;
      margin-top: -20px;
      margin-bottom: 10px;
    }

    .dialog-bubble {
      &:after {
        content: "";
        position: absolute;
        bottom: -10px;
        -webkit-transform: rotate(213deg);
        transform: rotate(213deg);
        right: -2px;
        border-left: 35px solid transparent;
        border-right: -11px solid transparent;
        border-top: 15px solid #f6f5ed;
      }
    }
  }

  .message-by-counterpart-wrapper {
    margin-right: auto;
    margin-left: 70px;

    .userHeadWrapper {
      text-align: left;
      display: block;
      margin-left: -60px;
      margin-top: -20px;
      margin-bottom: 10px;
    }

    .message-time {
      text-align: right;
    }
  }

  .dialog-bubble {
    box-sizing: border-box;
    padding: 1.6rem;
    margin: 0.8rem 0 1.6rem 0;
    border-radius: 4px;
    position: relative;
    background-color: #F6F5ED;
    color: #333333;
    font-family: InterstateRegular;
    font-size: 12px;

    .message-sender {
      font-style: italic;
      font-weight: bold;
      font-size: 1.4rem;
      margin-bottom: 0.8rem;
    }

    &:last-child {
      margin-bottom: 3.2rem;
    }



    &--by-counterpart {
      background-color: #cce0ff;
      border: 1px solid $petroleum-40;
      color: #333333;
      font-family: InterstateRegular;
      font-size: 12px;

      &:after {
        border-color: #cce0ff #cce0ff transparent transparent;
        left: 30px;
      }

      // Border for chat bubble arrow
      &:before {
        content: "";
        display: block;
        border: 8px solid;
        border-color: $petroleum-40 $petroleum-40 transparent transparent;
        width: 0;
        height: 0;
        position: absolute;
        bottom: -9px;
        left: -2px;
        transform: rotate(135deg);
        border-left: -11px solid transparent;
        border-right: 35px solid transparent;
        border-top: 15px solid #cce0ff;
      }
    }

  }

}

.upload-input {
  margin-top: 0.8rem;
}

.userHeadName,
.message-time {
  display: block;
  color: #33434B;
  font-family: 'InterstateLight';
  font-size: 13px;
}

.userHeadName {
  padding-top: 10px;
}

.userHeadSVG {
  width: 45px;
}