@import '../../colors';

.scroll-container {
  // overflow-x: auto;
}

.table {
  border-collapse: collapse;
  margin-left: -1px;

  >caption {
    text-align: left;
    font-size: 1.4rem;
    text-transform: uppercase;
    font-weight: bold;
    margin-bottom: 1.6rem;
    margin-left: 1px;
  }


  th {
    background-color: transparent;
    text-transform: uppercase;
    text-align: left;
    font-size: 1.4rem;
    padding: 0.8rem 1.6rem;
  }

  td {


    &:first-child {
      border-left: 1px solid $main-background-color;
    }

    &:last-child {
      border-right: 1px solid $main-background-color;
    }

    border-bottom: 2px solid $main-background-color;
    box-sizing: border-box;
    color: $petroleum-80;
  }
}