@import '../../../variables';
@import '../../../colors';

header.confirmation-header {
  text-align: center;

  > span {
    font-weight: bold;
    display: inline-block;
    margin-bottom: 0.8rem;
  }

  > h1 {
    font-size: 2.2rem;
  }

  > p {
    font-size: 1.6rem;
    margin-bottom: 3.2rem;
  }
}

.wrapper {
  padding: 1.6rem;
  background-color: white;
  box-shadow: 0 1px 2px $petroleum-40;
  min-height: 100%;
  box-sizing: border-box;
  max-width: $narrowContentWidth;

  display: flex;
  flex-direction: column;
  align-items: center;
  > * {
    min-width: 100%;
  }

}

.issue-card-wrapper {
  padding-bottom: 3.2rem;
}

p.info-message {
  text-align: center;
  margin-bottom: 3.2rem;
  padding-top: 3.2rem;
  border-top: 1px solid $petroleum-40;
}

.dashboard-link-button {
  display: block;
  background-color: $petroleum-80;
  color: white;
  font-size: 1.8rem;
  padding: 1.6rem;
  text-decoration: none;
  text-align: center;
  transition: all .2s ease-in-out;

  &:hover {
    color: white;
    background-color: $petroleum-100;
  }
}

.register-new-link {
  margin-top: 3.2rem;
  font-size: 1.6rem;
  display: block;
  text-align: center;
}

@media screen and (min-width: 769px) {
  .dashboard-link-button{

    width: fit-content;
    min-width: auto;
    padding-left: 3.2rem;
    padding-right: 3.2rem;

  }
}