@font-face {
    font-family: "InterstateRegular";
    src: url("./InterstateRegular.woff") format('woff');
}

@font-face {
    font-family: "InterstateRegularCompressed";
    src: url("./InterstateRegularCompress.woff") format('woff');
}

@font-face {
    font-family: "InterstateBold";
    src: url("./InterstateBold.woff") format('woff');
}

@font-face {
    font-family: "InterstateBoldCompressed";
    src: url("./InterstateBoldCompressed.woff") format('woff');
}

@font-face {
    font-family: "InterstateLight";
    src: url("./InterstateLight.woff") format('woff');
}

@font-face {
    font-family: "ssprobold";
    src: url("./SourceSerifPro/SourceSerifPro-Bold.ttf") format('woff');
}

.headlineS {
    color: #333333;
    text-transform: uppercase;
    text-align: center;
    font: normal normal bold 40px/48px InterstateBoldCompressed;
    letter-spacing: 0px;
    height: 48px;
    margin-bottom: 32px;
    margin-top: 37px;
    display: inline-block;
}

.bigCard {
    text-align: center;
    width: 200px;
    float: left;
    margin-right: 24px;

    .imageWrapper {
        width: 200px;
        height: 224px;
        position: relative;
    }

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 6px;
    }

    .cardTitle {
        font: bold 24px InterstateBold;
        margin-top: 16px;
        display: inline-block;
    }

    .shortDescription {
        font: 14px InterstateRegular;
        line-height: 16px;
        padding: 24px 24px 32px 25px;
        margin: 0;
    }
}

.cardBack {
    font-size: 14px;
    font-family: InterstateRegular;
    text-align: center;
    position: absolute;
    left: 0;
    top: 0;
    background: #cce0ff;
    width: 100%;
    height: 100%;
    border-radius: 5px;
    visibility: hidden;
    opacity: 0;
    transition: opacity .2s, visibility .2s;
    cursor: pointer;

    button {
        padding: 10px 20px;
        font-size: 11px;
        font-family: 'InterstateRegular';
        text-transform: uppercase;
        background: #214C6F;
        color: white;
        border-radius: 5px;
        border: 1px solid transparent;
        cursor: pointer;
    }
}

.imageWrapper:hover .cardBack {
    visibility: visible;
    opacity: 1;
}

@media screen and (max-width: 768px) {
    .bigCard .imageWrapper {
        width: 100%;
        margin: 0;
        height: 416px;
        position: relative;
    }

    .bigCard {
        width: 100%;
        margin-bottom: 50px;
    }

}