/* autoprefixer grid: autoplace */
html {
  font-size: 62.5%; }
  html.lock-scroll {
    overflow: hidden !important; }

body {
  margin: 0;
  padding: 0;
  font-size: 1.6rem;
  color: #2C393F; }

* {
  font-family: "InterstateRegular";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box; }

span {
  caret-color: transparent; }

a {
  color: #1585BB;
  text-decoration: none; }

h1, h2, h3, h4, p {
  margin-top: 0;
  margin-bottom: 0; }
  h1:not(:last-child), h2:not(:last-child), h3:not(:last-child), h4:not(:last-child), p:not(:last-child) {
    margin-bottom: 1.6rem; }

h3 {
  font-size: 1.2rem; }

h3:not(:first-child) {
  margin-top: 3.2rem; }

label {
  font-size: 1.4rem; }

fieldset {
  margin: 0;
  padding: 0; }
  fieldset legend {
    padding: 0;
    margin: 0;
    font-size: 2.2rem; }

/* autoprefixer grid: autoplace */
.Authentication_wrapper__1QyYc {
  background-color: white;
  overflow: hidden; }

.Authentication_newsec-background__2AwmW {
  position: relative;
  box-sizing: border-box;
  background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(/static/media/background.f62c90f6.jpg) no-repeat center;
  background-size: cover;
  max-width: 1920px;
  margin: 0 auto; }

.Authentication_osloareal-background__2zDKv {
  position: relative;
  box-sizing: border-box;
  background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url(/static/media/OA_background.11db2154.jpg) no-repeat center;
  background-size: cover;
  max-width: 1920px;
  margin: 0 auto; }
  @media screen and (min-width: 769px) {
    .Authentication_osloareal-background__2zDKv > div.Authentication_grid-container__3bGd7 {
      margin: 0 12.8rem 0 auto; } }

.Authentication_svg-background__1xFk9 {
  position: relative;
  box-sizing: border-box;
  background: url(/static/media/SVG_background.4e721f19.jpg) no-repeat center;
  background-size: cover;
  max-width: 1920px;
  margin: 0 auto; }

.Authentication_grid-container__3bGd7 {
  box-sizing: border-box;
  display: -ms-grid;
  display: grid;
      grid-template-areas: "login-box";
  -ms-grid-rows: 100vh;
  grid-template-rows: 100vh;
  grid-row-gap: 0.8rem;
  max-width: 100rem;
  min-height: 100vh;
  margin: 0 auto; }

.Authentication_grid-container__3bGd7 {
  /*autoprefixer: off*/
  align-items: start; }

.Authentication_main-heading__3ydxj,
.Authentication_login-box__2RhSp,
.Authentication_copyright__12VCO {
  z-index: 2; }

.Authentication_main-heading__3ydxj {
  grid-area: heading;
  -ms-grid-row-align: center;
      align-self: center;
  color: white;
  display: none; }
  .Authentication_main-heading__3ydxj span {
    display: block; }

.Authentication_login-box__2RhSp {
  -ms-grid-row: 1;
  -ms-grid-column: 1;
  grid-area: login-box;
  background-color: white;
  padding: 3.2rem 3.2rem 6.4rem 3.2rem;
  height: 100%; }
  .Authentication_login-box__2RhSp .Authentication_logo__3wQFc {
    padding-bottom: 5.6rem; }

.Authentication_login-form__3Pu8y .Authentication_login-form-header__19Il2 {
  text-transform: none; }

.Authentication_login-form__3Pu8y .Authentication_action-wrapper__1EECy {
  text-align: right; }

.Authentication_login-form__3Pu8y .Authentication_input-field__kOMb1 {
  margin-bottom: 3.2rem;
  font-size: 17px;
  font-family: InterstateRegularCompressed;
  color: #5D6A70; }

.Authentication_login-form__3Pu8y .Authentication_inputFieldProp__2I3Nn {
  border: 1px solid #5D6A70; }

.Authentication_login-form__3Pu8y .Authentication_error-text-color__3UNgN {
  color: #C4232A; }

.Authentication_copyright__12VCO {
  grid-area: copyright;
  text-align: right;
  color: white;
  padding-right: 3.2rem;
  display: none;
  max-height: 5rem; }

.Authentication_link__2gUrK {
  font-size: 11px;
  font-family: InterstateRegular;
  background-color: #a1c5ff;
  border-radius: 4px;
  padding: 10px 20px;
  color: white;
  margin-left: 10px;
  margin-top: 15px;
  display: inline-block; }

@media screen and (min-width: 769px) {
  .Authentication_grid-container__3bGd7 {
        grid-template-areas: "heading login-box" ".       copyright";
    -ms-grid-columns: 1fr 40rem;
    grid-template-columns: 1fr 40rem;
    -ms-grid-rows: 1fr auto;
    grid-template-rows: 1fr auto;
    padding: 1.6rem; }
  .Authentication_login-box__2RhSp {
    -ms-grid-row-align: center;
        align-self: center;
    height: auto; }
  .Authentication_copyright__12VCO,
  .Authentication_main-heading__3ydxj {
    display: block; }
  .Authentication_main-heading__3ydxj {
    -ms-grid-row: 1;
    -ms-grid-column: 1; }
  .Authentication_login-box__2RhSp {
    -ms-grid-row: 1;
    -ms-grid-column: 2; }
  .Authentication_copyright__12VCO {
    -ms-grid-row: 2;
    -ms-grid-column: 2; } }

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .Authentication_newsec-background__2AwmW,
  .Authentication_osloareal-background__2zDKv {
    min-height: 100vh; } }

.TextField_wrapper__N4bkj {
  position: relative;
  margin-bottom: 0.8rem; }
  .TextField_wrapper__N4bkj .TextField_label__1eJ3W {
    font-size: 1.4rem;
    display: inline-block;
    position: absolute;
    -webkit-transform-origin: top left;
            transform-origin: top left;
    -webkit-transform: translate(0, 1.4rem) scale(1);
            transform: translate(0, 1.4rem) scale(1);
    transition: all .1s ease-in-out;
    color: #33434B; }
    .TextField_wrapper__N4bkj .TextField_label__1eJ3W[data-active=true] {
      -webkit-transform: translate(0, 0.4rem) scale(0.75);
              transform: translate(0, 0.4rem) scale(0.75);
      font-weight: bold;
      color: #33434B;
      color: #1585BB !important; }
  .TextField_wrapper__N4bkj[data-hasvalue=true] .TextField_label__1eJ3W {
    -webkit-transform: translate(0, 0.4rem) scale(0.75);
            transform: translate(0, 0.4rem) scale(0.75);
    font-weight: bold;
    color: #33434B; }

.TextField_text-field__16EUk {
  border: none;
  background-color: transparent;
  font-size: 1.6rem;
  color: #33434B;
  padding: 1.6rem 0 0.8rem 0;
  border-bottom: 1px solid #C4CED4;
  width: 100%;
  outline: none; }
  .TextField_text-field__16EUk:focus {
    color: #1585BB;
    border-color: #1585BB; }

.Buttons_button__3WgZv {
  border: none;
  margin: 0;
  padding: 0.8rem 3.2rem;
  color: white;
  transition: background-color .2s ease-in-out;
  align-items: center;
  justify-content: space-around;
  background-color: transparent;
  text-decoration: none; }
  .Buttons_button__3WgZv:disabled {
    opacity: 0.5; }
  .Buttons_button__3WgZv.Buttons_confirmation-button__16TRg {
    background-color: #151d3b;
    min-width: 120px;
    min-height: 45px;
    font-size: 13px;
    font-family: InterstateRegular;
    border-radius: 4px; }
    .Buttons_button__3WgZv.Buttons_confirmation-button__16TRg:hover:not(:disabled) {
      background-color: #2d3e80; }
  .Buttons_button__3WgZv.Buttons_primary-button__nyVEL {
    background-color: #1585BB;
    font-size: 1.6rem;
    font-weight: bold;
    border-radius: 4px;
    outline-color: coral; }
    .Buttons_button__3WgZv.Buttons_primary-button--collapsed__3jpke {
      padding: 0.8rem; }
      .Buttons_button__3WgZv.Buttons_primary-button--collapsed__3jpke > span {
        display: none; }
      .Buttons_button__3WgZv.Buttons_primary-button--collapsed__3jpke > svg {
        margin: 0; }
    .Buttons_button__3WgZv.Buttons_primary-button__nyVEL:hover:not(:disabled) {
      background-color: #214C6F; }
  .Buttons_button__3WgZv.Buttons_secondary-button__1hibn {
    background-color: transparent;
    color: #1585BB;
    font-size: 1.4rem;
    padding: 0; }
    .Buttons_button__3WgZv.Buttons_secondary-button__1hibn > svg {
      margin-right: 0.8rem;
      fill: #1585BB;
      width: auto;
      height: auto; }
    .Buttons_button__3WgZv.Buttons_secondary-button__1hibn:hover:not(:disabled) {
      color: #214C6F; }
      .Buttons_button__3WgZv.Buttons_secondary-button__1hibn:hover:not(:disabled) > svg {
        fill: #214C6F; }
  .Buttons_button__3WgZv.Buttons_icon-button__2nXuC {
    padding: 0; }
    .Buttons_button__3WgZv.Buttons_icon-button__2nXuC:hover {
      opacity: .5; }

.Buttons_loading-icon__3j1_d {
  fill: white !important;
  margin-left: 0.8rem;
  width: 20px;
  height: 20px; }

@-webkit-keyframes LoadingIcon_spin__3Ae0s {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes LoadingIcon_spin__3Ae0s {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

.LoadingIcon_loading-icon__GMjBe {
  fill: #5D6B71;
  -webkit-animation: LoadingIcon_spin__3Ae0s 2s linear infinite;
          animation: LoadingIcon_spin__3Ae0s 2s linear infinite; }

.FieldErrorMessage_message__37Smx {
  font-size: 1.2rem;
  color: #C4232A;
  display: flex;
  align-items: center;
  justify-content: flex-start; }
  .FieldErrorMessage_message__37Smx > span {
    padding-left: 0.8rem; }
  .FieldErrorMessage_message--hidden__2AHoJ {
    visibility: hidden; }

.EmailVerification_error-message__30bNb {
  border: 1px solid #C4232A;
  padding: 0.8rem; }

.EmailVerification_helptext__2KzMl {
  margin-top: 5rem; }

.EmailVerification_validation__3DSQc {
  text-align: center; }

.EmailVerification_validation-text__3g0VZ {
  margin-top: 1.6rem; }

.NewsecLogo_logo__1LFXn {
  width: 138px;
  box-sizing: border-box; }

.NewsecLogo_logoSubText__Rlm2V {
  color: white;
  font-size: 14px;
  font-family: InterstateLight; }

.OsloArealLogo_logo__uTRW8 {
  max-width: 10rem;
  width: 100%;
  box-sizing: border-box; }

.SvgLogo_logo__2P_xU {
  max-width: 10rem;
  width: 100%;
  box-sizing: border-box; }

/* autoprefixer grid: autoplace */
.DashboardLayout_wrapper__3DQFS {
  display: -ms-grid;
  display: grid;
      grid-template-areas: "menu" "right" "header" "pageContent";
  -ms-grid-columns: 100%;
  grid-template-columns: 100%;
  -ms-grid-rows: 6.7rem auto 1fr;
  grid-template-rows: 6.7rem auto 1fr;
  transition: all 0.6s ease-in-out;
  min-height: 100vh;
  max-width: 100%;
  background-color: white; }
  .DashboardLayout_wrapper__3DQFS .DashboardLayout_main-content__Xk6ts {
    width: calc(100% - 221px);
    margin-left: 221px;
    margin-bottom: 100px; }

.DashboardLayout_wrapper__3DQFS {
  /*autoprefixer: off*/
  justify-content: center; }

.DashboardLayout_header-wrapper__1Di2S {
  -ms-grid-row: 3;
  -ms-grid-column: 1;
  grid-area: header;
  margin-bottom: 1px; }

.DashboardLayout_left-column__mEDqd {
  background-color: #e0d1b8;
  box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.15);
  position: fixed;
  height: 100%;
  width: 221px; }

.DashboardLayout_right-column__1O1n6 {
  -ms-grid-row: 2;
  -ms-grid-column: 1;
  grid-area: right;
  position: fixed;
  max-height: 100vh;
  max-width: 100vw;
  bottom: 0; }

.DashboardLayout_main-menu__1uET3 {
  position: absolute;
  top: 5.8rem;
  left: 0;
  right: 0;
  z-index: 2;
  display: none; }
  .DashboardLayout_main-menu--open__1FVjC {
    display: block; }

.DashboardLayout_nav-links-list__1mW-W {
  margin: 0;
  padding: 0;
  list-style: none; }
  .DashboardLayout_nav-links-list__1mW-W > li {
    text-align: center;
    box-shadow: 0 2px 0px -1px #e0d1b8;
    margin-right: 32px;
    margin-left: 32px;
    height: 70px;
    padding: 19px 31px;
    margin-bottom: 10px;
    border-radius: 4px;
    cursor: pointer; }
    .DashboardLayout_nav-links-list__1mW-W > li a {
      color: white; }
      .DashboardLayout_nav-links-list__1mW-W > li a span {
        width: 100%; }
  .DashboardLayout_nav-links-list__1mW-W .DashboardLayout_nav-link__1sCbY {
    color: white;
    width: 100%;
    justify-content: flex-start;
    padding: 1.6rem;
    box-sizing: border-box; }
    .DashboardLayout_nav-links-list__1mW-W .DashboardLayout_nav-link__1sCbY:hover, .DashboardLayout_nav-links-list__1mW-W .DashboardLayout_nav-link__1sCbY:focus, .DashboardLayout_nav-links-list__1mW-W .DashboardLayout_nav-link__1sCbY.DashboardLayout_active__3nIOJ {
      background-color: #5D6B71;
      color: white; }
      .DashboardLayout_nav-links-list__1mW-W .DashboardLayout_nav-link__1sCbY:hover .DashboardLayout_nav-link-icon__3mP0-, .DashboardLayout_nav-links-list__1mW-W .DashboardLayout_nav-link__1sCbY:focus .DashboardLayout_nav-link-icon__3mP0-, .DashboardLayout_nav-links-list__1mW-W .DashboardLayout_nav-link__1sCbY.DashboardLayout_active__3nIOJ .DashboardLayout_nav-link-icon__3mP0- {
        fill: white; }
  .DashboardLayout_nav-links-list__1mW-W .DashboardLayout_nav-link-icon__3mP0- {
    fill: white; }

.DashboardLayout_bottom-nav-links-list__3JDli {
  position: absolute;
  bottom: 50px;
  list-style: none;
  padding: 0 32px; }
  .DashboardLayout_bottom-nav-links-list__3JDli li {
    width: 157px;
    height: 43px;
    text-align: center;
    background-color: #90999E;
    margin-bottom: 8px;
    border-radius: 3px;
    line-height: 44px;
    cursor: pointer; }
    .DashboardLayout_bottom-nav-links-list__3JDli li a {
      font-family: InterstateRegular;
      font-size: 13px; }
  .DashboardLayout_bottom-nav-links-list__3JDli li:hover,
  .DashboardLayout_bottom-nav-links-list__3JDli .DashboardLayout_activeSidebarBtn__bJfrg {
    background-color: white; }
    .DashboardLayout_bottom-nav-links-list__3JDli li:hover a,
    .DashboardLayout_bottom-nav-links-list__3JDli .DashboardLayout_activeSidebarBtn__bJfrg a {
      color: #90999E; }
  .DashboardLayout_bottom-nav-links-list__3JDli li:last-of-type {
    margin-bottom: 30px; }

.DashboardLayout_logo-wrapper__cIZUc {
  text-align: center;
  display: none;
  padding: 23px; }
  .DashboardLayout_logo-wrapper__cIZUc .DashboardLayout_newsec-logo__1kiR3 {
    display: inline-block;
    width: 100%;
    height: auto; }

.DashboardLayout_new-issue-button__2q8PE {
  font-family: InterstateRegular;
  font-size: 14px;
  background-color: #5c7fff;
  color: white; }

.DashboardLayout_new-issue-button__2q8PE:hover,
.DashboardLayout_newIssueActive__qqERL {
  background-color: white; }
  .DashboardLayout_new-issue-button__2q8PE:hover a,
  .DashboardLayout_newIssueActive__qqERL a {
    color: #151d3b !important; }

.DashboardLayout_new-service-button__24gVX {
  font-family: InterstateRegular;
  font-size: 14px;
  background-color: #2d3e80;
  color: white; }

.DashboardLayout_new-service-button__24gVX:hover,
.DashboardLayout_newServiceActive__P5Azo {
  background-color: white; }
  .DashboardLayout_new-service-button__24gVX:hover a,
  .DashboardLayout_newServiceActive__P5Azo a {
    color: #151d3b !important; }

@media screen and (max-width: 768px) {
  .DashboardLayout_left-column__mEDqd {
    position: static;
    position: initial;
    width: 100%;
    height: 64px; }
  .DashboardLayout_wrapper__3DQFS .DashboardLayout_main-content__Xk6ts {
    position: absolute;
    width: 100%;
    margin-left: 0;
    margin-top: 150px; }
  .DashboardLayout_new-issue-button__2q8PE,
  .DashboardLayout_new-service-button__24gVX {
    display: block; }
  .DashboardLayout_nav-links-list__1mW-W,
  .DashboardLayout_bottom-nav-links-list__3JDli {
    width: 100%; }
  .DashboardLayout_bottom-nav-links-list__3JDli li {
    width: 100%;
    height: 45px;
    margin-bottom: 12px; }
    .DashboardLayout_bottom-nav-links-list__3JDli li a {
      font-size: 15px;
      line-height: 45px; }
  .DashboardLayout_right-column__1O1n6 {
    max-height: calc(100vh - 125px); }
  .DashboardLayout_main-menu__1uET3 {
    width: 100%;
    height: 100%;
    background-color: #5D6A70; }
    .DashboardLayout_main-menu__1uET3 ul:first-of-type {
      margin-top: 80px; }
  .DashboardLayout_new-issue-button__2q8PE,
  .DashboardLayout_new-service-button__24gVX {
    height: 80px !important;
    line-height: 40px;
    font-size: 22px;
    margin-bottom: 16px; } }

@media screen and (min-width: 769px) {
  .DashboardLayout_page-content-wrapper__3wRRj {
    -ms-grid-row: 4;
    -ms-grid-column: 1;
    grid-area: pageContent;
    padding: 1.6rem 0.8rem;
    margin-top: 60px;
    margin-left: 80px;
    width: calc(100vw - 500px);
    max-width: 1200px;
    padding-left: 64px;
    padding-right: 40px;
    margin-bottom: 140px; }
  @media screen and (min-width: 769px) {
  .DashboardLayout_page-content-wrapper__3wRRj {
      -ms-grid-row: 2;
      -ms-grid-column: 2; } }
  .DashboardLayout_hide-on-desktop__PQeGV {
    display: none !important; }
  .DashboardLayout_right-column__1O1n6 {
    top: 6rem;
    max-width: 500px;
    right: 0;
    display: inline;
    display: initial;
    position: absolute;
    bottom: 0;
    z-index: 100; }
  .DashboardLayout_wrapper__3DQFS {
    /*autoprefixer: off*/
    grid-template-rows: auto 1fr;
    -ms-grid-rows: 5rem 1fr; }
  .DashboardLayout_wrapper__3DQFS {
        grid-template-areas: "menu header" "menu pageContent right";
    -ms-grid-columns: 22rem 1fr;
    grid-template-columns: 22rem 1fr; }
    .DashboardLayout_wrapper__3DQFS .DashboardLayout_page-content-wrapper__3wRRj {
      padding: 1.6rem 1.6rem 1.6rem; }
    .DashboardLayout_wrapper__3DQFS .DashboardLayout_header-wrapper__1Di2S {
      position: absolute;
      margin-left: 230px;
      width: calc(100% - 237px);
      z-index: 1; }
  .DashboardLayout_logo-wrapper__cIZUc {
    display: block; }
  .DashboardLayout_main-menu__1uET3 {
    position: static;
    position: initial;
    display: block !important; }
  .DashboardLayout_header-wrapper__1Di2S {
    -ms-grid-row: 1;
    -ms-grid-column: 2; }
  .DashboardLayout_right-column__1O1n6 {
    -ms-grid-row: 2;
    -ms-grid-column: 3; } }
  @media screen and (min-width: 769px) and (-ms-high-contrast: active), screen and (min-width: 769px) and (-ms-high-contrast: none) {
    .DashboardLayout_main-menu__1uET3 {
      min-height: 100vh; } }

@media screen and (min-width: 1365px) {
  .DashboardLayout_page-content-wrapper__3wRRj {
    width: calc(100vw - 730px); } }

/* autoprefixer grid: autoplace */
.IssuesList_issue-list-section__26CRs:not(:last-child),
.IssuesList_empty-list__2nA4J:not(:last-child) {
  margin-bottom: 3.2rem; }

.IssuesList_issue-list-section__26CRs {
  max-width: 80rem; }

.IssuesList_empty-list__2nA4J {
  text-align: center; }
  .IssuesList_empty-list__2nA4J header {
    margin-bottom: 1.6rem; }
  .IssuesList_empty-list__2nA4J header > p {
    font-weight: bold; }
  .IssuesList_empty-list__2nA4J .IssuesList_empty-list-hint__8CxD0 {
    color: #1585BB;
    font-weight: bold; }

.IssuesList_viewIcon1__3338m,
.IssuesList_viewIcon2__UOlBm {
  width: 28px;
  margin-right: 12px;
  cursor: pointer;
  position: absolute;
  transition: -webkit-transform .2s;
  transition: transform .2s;
  transition: transform .2s, -webkit-transform .2s; }

.IssuesList_viewIcon2__UOlBm {
  margin-left: 44px; }

.IssuesList_viewIcon1__3338m:hover,
.IssuesList_viewIcon2__UOlBm:hover {
  -webkit-transform: scale(1.2);
          transform: scale(1.2); }

.IssuesList_activeViewIcon__3jHgM {
  width: 32px; }

.IssuesList_contentHeader__Gp9H8 {
  font-family: InterstateBoldCompressed;
  font-size: 40px;
  display: block;
  margin-top: 43px;
  margin-bottom: 32px; }

.IssuesList_solved-issues-list__3y2PW .IssuesList_solved-issue___Oise {
  /*autoprefixer: off*/
  align-items: center; }

.IssuesList_solved-issues-list__3y2PW .IssuesList_solved-issue___Oise {
  display: -ms-grid !important;
  display: grid !important;
      grid-template-areas: "title    reportedDate" "location finishedDate";
  -ms-grid-columns: 1fr 1.6rem auto;
  grid-template-columns: 1fr auto;
  grid-column-gap: 1.6rem;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto; }
  .IssuesList_solved-issues-list__3y2PW .IssuesList_solved-issue___Oise .IssuesList_issue-location__FdI72,
  .IssuesList_solved-issues-list__3y2PW .IssuesList_solved-issue___Oise .IssuesList_issue-finishedDate__1Rh_F {
    margin: 0; }
  .IssuesList_solved-issues-list__3y2PW .IssuesList_solved-issue___Oise .IssuesList_issue-location__FdI72,
  .IssuesList_solved-issues-list__3y2PW .IssuesList_solved-issue___Oise .IssuesList_issue-reportedDate__gd3_6,
  .IssuesList_solved-issues-list__3y2PW .IssuesList_solved-issue___Oise .IssuesList_issue-finishedDate__1Rh_F {
    font-size: 1.2rem; }
  .IssuesList_solved-issues-list__3y2PW .IssuesList_solved-issue___Oise .IssuesList_issue-title__1x7_z,
  .IssuesList_solved-issues-list__3y2PW .IssuesList_solved-issue___Oise .IssuesList_issue-location__FdI72 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }
  .IssuesList_solved-issues-list__3y2PW .IssuesList_solved-issue___Oise .IssuesList_issue-title__1x7_z {
    -ms-grid-row: 1;
    -ms-grid-column: 1;
    grid-area: title;
    font-size: 1.6rem; }
  .IssuesList_solved-issues-list__3y2PW .IssuesList_solved-issue___Oise .IssuesList_issue-location__FdI72 {
    -ms-grid-row: 2;
    -ms-grid-column: 1;
    grid-area: location; }
  .IssuesList_solved-issues-list__3y2PW .IssuesList_solved-issue___Oise .IssuesList_issue-reportedDate__gd3_6 {
    -ms-grid-row: 1;
    -ms-grid-column: 3;
    grid-area: reportedDate;
    -ms-grid-column-align: end;
        justify-self: end; }
  .IssuesList_solved-issues-list__3y2PW .IssuesList_solved-issue___Oise .IssuesList_issue-finishedDate__1Rh_F {
    -ms-grid-row: 2;
    -ms-grid-column: 3;
    grid-area: finishedDate;
    -ms-grid-column-align: end;
        justify-self: end;
    color: #30E500; }

.IssuesList_filter-options__2oZdK {
  margin-bottom: 50px; }

.IssuesList_filter-option__2j4d- {
  margin-right: 0.8rem;
  display: inline-block; }

@media screen and (min-width: 769px) {
  /*autoprefixer: off*/
  .IssuesList_solved-issues-list__3y2PW {
    /*autoprefixer: grid*/
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 0.8rem; } }

.CardList_card-list__3Y-ve {
  list-style: none;
  margin: 0;
  padding: 0; }
  .CardList_card-list__3Y-ve > li {
    margin-bottom: 0.8rem; }

.CardList_card-list--collapsed__2zXWL {
  z-index: 0;
  position: relative;
  -webkit-perspective: 1000px;
          perspective: 1000px; }

.CardList_card-list--collapsed__2zXWL > li {
  position: relative;
  -webkit-transform-origin: 50% 0;
          transform-origin: 50% 0; }
  .CardList_card-list--collapsed__2zXWL > li:hover {
    -webkit-transform: none;
            transform: none;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.15); }
  .CardList_card-list--collapsed__2zXWL > li:nth-child(n+2) {
    height: 10px;
    overflow: hidden; }
    .CardList_card-list--collapsed__2zXWL > li:nth-child(n+2) *:not(a) {
      visibility: hidden; }
  .CardList_card-list--collapsed__2zXWL > li:nth-child(n+5) {
    display: none; }
  .CardList_card-list--collapsed__2zXWL > li:nth-child(1) {
    z-index: 6;
    -webkit-transform: translateY(0) scale(1);
            transform: translateY(0) scale(1); }
  .CardList_card-list--collapsed__2zXWL > li:nth-child(2) {
    z-index: 5;
    -webkit-transform: translate3d(0, -38px, -30px);
            transform: translate3d(0, -38px, -30px); }
  .CardList_card-list--collapsed__2zXWL > li:nth-child(3) {
    z-index: 4;
    -webkit-transform: translate3d(0, -80px, -100px);
            transform: translate3d(0, -80px, -100px); }
  .CardList_card-list--collapsed__2zXWL > li:nth-child(4) {
    z-index: 3;
    -webkit-transform: translate3d(0, -120px, -150px);
            transform: translate3d(0, -120px, -150px); }

.FilterButton_input__2Z8iE {
  width: 1px;
  height: 1px;
  border: none;
  position: absolute;
  left: -5000px; }

.FilterButton_label__3K5q8 {
  box-sizing: border-box;
  transition: all .2s ease-in-out;
  white-space: nowrap;
  border: 1px dotted #214C6F;
  background-color: white;
  color: #214C6F;
  padding: 8px 16px;
  font-size: 13px;
  font-family: 'InterstateRegular';
  border-radius: 3px;
  display: inline-block;
  cursor: pointer; }

.FilterButton_input__2Z8iE:focus + .FilterButton_label__3K5q8, .FilterButton_label__3K5q8:hover {
  background-color: #F8F8F8; }

.FilterButton_input__2Z8iE:checked + .FilterButton_label__3K5q8 {
  border: 1px solid #214C6F;
  background-color: #214C6F;
  color: #fff; }

.NewIssueCard_description__1Y1ar {
  font-family: InterstateBold;
  font-size: 22px; }

.NewIssueCard_location__3dOss {
  font-family: InterstateRegular;
  font-size: 17px;
  color: #333333; }

.NewIssueCard_status__xE6s9 {
  font-family: InterstateLight;
  font-size: 15px;
  color: #33434B;
  text-align: right; }

.NewIssueCard_id__Q4KpK {
  font-family: InterstateBoldCompressed;
  font-size: 30px;
  color: #5D6A70;
  text-align: right;
  margin-bottom: 8px !important; }

.NewIssueCard_addedDate__3Tsco {
  text-align: left;
  font-family: InterstateLight;
  font-size: 14px;
  color: #33434B; }

.NewIssueCard_waitingCase__32ctT {
  border-right: 20px solid #F4B1BB !important; }

.NewIssueCard_openCase__felpL {
  border-right: 20px solid #FFE77F !important; }

.NewIssueCard_finishedCase__HLai1 {
  border-right: 20px solid #B3DC10 !important; }

.Cards_card__2sASI, .Cards_link-card__L-uyN {
  border-radius: 6px;
  background-color: white;
  display: block;
  transition: all .4s ease-in-out;
  border: 1px solid #BDC7CD; }

.Cards_card__2sASI {
  padding: 1.6rem; }

.Cards_link-card__L-uyN .Cards_link__6V91k {
  text-decoration: none;
  color: #2C393F;
  padding: 1.6rem;
  display: block; }

@media screen and (min-width: 768px) {
  .Cards_card__2sASI, .Cards_link-card__L-uyN .Cards_link__6V91k {
    padding: 1.6rem 3.2rem; } }

.IssueDashboard_issue-dashboard__2pD0v {
  min-height: 100%;
  padding-top: 1.6rem;
  max-width: 800px;
  min-width: 600px;
  padding-left: 64px;
  padding-right: 40px; }

@media screen and (max-width: 768px) {
  .IssueDashboard_issue-dashboard__2pD0v {
    width: 100%;
    padding-right: 20px;
    padding-left: 20px; } }

/* autoprefixer grid: autoplace */
.IssueDetails_issue-details-wrapper__2V9vw {
  box-sizing: border-box;
  background-color: white;
  display: flex;
  flex-direction: column;
  height: 100%; }

.IssueDetails_waitingCase__2ZtSx {
  background-color: #F4B1BB; }

.IssueDetails_openCase__2l36P {
  background-color: #FFE77F; }

.IssueDetails_finishedCase__1E8o4 {
  background-color: #B3DC10; }

.IssueDetails_halfWidth__8h3oe {
  float: left;
  width: 50%; }

.IssueDetails_header__3qLec {
  height: 80px; }
  .IssueDetails_header__3qLec .IssueDetails_headerStatus__2JEt2 {
    display: inline-block;
    width: 200px;
    line-height: 80px;
    margin-left: 60px;
    color: #333333;
    font-family: 'InterstateLight'; }
  .IssueDetails_header__3qLec .IssueDetails_headerCaseNumber__3zRiu {
    width: 120px;
    line-height: 80px;
    margin-right: 30px;
    color: #333333;
    font-family: 'InterstateBoldCompressed';
    font-size: 30px;
    float: right; }
  .IssueDetails_header__3qLec .IssueDetails_sak__jE1NI {
    color: #5D6A70;
    font-family: 'InterstateBoldCompressed';
    padding-right: 6px; }

.IssueDetails_heading__1SgPi {
  text-transform: capitalize;
  font-size: 40px;
  color: #33434B;
  font-family: InterstateBoldCompressed; }

.IssueDetails_section__1l0aj {
  padding: 20px 40px 60px 60px; }
  .IssueDetails_section__1l0aj:not(:last-child) {
    border-bottom: 1px solid #DFE2E2; }

.IssueDetails_scroll-section__3B5n5 {
  flex-grow: 1;
  position: relative;
  overflow: hidden; }
  .IssueDetails_scroll-section__3B5n5 > div {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    overflow: auto;
    padding: 1.6rem;
    display: -ms-grid;
    display: grid;
    -ms-grid-rows: auto 1fr;
    grid-template-rows: auto 1fr;
    -ms-grid-columns: 100%;
    grid-template-columns: 100%; }
  .IssueDetails_scroll-section__3B5n5 > div > *:nth-child(1) {
  -ms-grid-row: 1;
  -ms-grid-column: 1; }
  .IssueDetails_scroll-section__3B5n5 > div > *:nth-child(2) {
  -ms-grid-row: 2;
  -ms-grid-column: 1; }

.IssueDetails_description-border__T3qqY {
  border-bottom: 1px solid #DFE2E2; }

.IssueDetails_box-shadow__1otcE {
  box-shadow: 0 2px 1px -1px #DFE2E2; }

.IssueDetails_issue-info__3fKv8 {
  padding-top: 9px; }
  .IssueDetails_issue-info__3fKv8 .IssueDetails_issue-id__2O57D,
  .IssueDetails_issue-info__3fKv8 span {
    font-family: InterstateLight;
    font-size: 14px;
    color: #33434B;
    display: block;
    text-align: right; }
  .IssueDetails_issue-info__3fKv8 span:last-of-type {
    padding-top: 25px; }

.IssueDetails_issue-location__2KE0s {
  font-size: 17px;
  font-family: InterstateRegular;
  color: #377EB8;
  text-align: right;
  margin-top: 8px; }
  .IssueDetails_issue-location__2KE0s > span {
    padding-left: 0.8rem; }

.IssueDetails_issue-status__XGmaF {
  grid-area: status;
  text-transform: uppercase;
  font-size: 1.2rem;
  font-weight: bold;
  color: #33434B; }

@media screen and (max-width: 768px) {
  .IssueDetails_header__3qLec .IssueDetails_headerStatus__2JEt2 {
    margin-left: 15px; }
  .IssueDetails_header__3qLec .IssueDetails_headerCaseNumber__3zRiu {
    margin-right: 15px; }
  .IssueDetails_section__1l0aj {
    padding: 15px 25px 35px 20px; } }

.Dialog_wrapper__3XWPX {
  display: flex;
  flex-direction: column; }

.Dialog_message-form__1zi-q {
  margin: 0 -1.6rem;
  margin-top: 50px;
  margin-bottom: -15px; }
  .Dialog_message-form__1zi-q .Dialog_message-input-wrapper__3QEj1 {
    position: relative;
    padding: 0.8rem 3.2rem 0.8rem 3.2rem;
    background-color: #F6F5ED; }
    .Dialog_message-form__1zi-q .Dialog_message-input-wrapper__3QEj1 .Dialog_message-textarea__3HwrY {
      display: block;
      box-sizing: border-box;
      width: 100%;
      resize: none;
      border: none;
      font-size: 1.6rem;
      padding-top: 0.8rem;
      padding-right: 3.2rem;
      background-color: #F6F5ED; }
    .Dialog_message-form__1zi-q .Dialog_message-input-wrapper__3QEj1 .Dialog_message-send-button__fwuRZ {
      padding: 0;
      position: absolute;
      top: 3.2rem;
      right: 3.6rem; }
    .Dialog_message-form__1zi-q .Dialog_message-input-wrapper__3QEj1 .Dialog_message-send-button-icon__2-W5a {
      fill: #839097; }

.Dialog_dialog-list--empty__XFVh3 {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  background-color: #F8F8F8;
  color: #839097;
  margin: 0 -3.2rem; }
  .Dialog_dialog-list--empty__XFVh3 > p {
    margin-top: 3.2rem;
    font-size: 2.2rem; }
  .Dialog_dialog-list--empty__XFVh3 > svg {
    width: 12rem;
    height: auto; }

.Dialog_dialog-list__M1Nvo {
  flex-grow: 1;
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end; }
  .Dialog_dialog-list__M1Nvo > li {
    min-width: 30%;
    max-width: 100%; }
  .Dialog_dialog-list__M1Nvo .Dialog_attachments-list__3g_fF {
    margin-top: 0.8rem; }
  .Dialog_dialog-list__M1Nvo .Dialog_date-spacer__pF4s- {
    margin: 1.6rem auto;
    text-align: center;
    font-weight: bold;
    font-size: 1.4rem;
    color: #839097;
    font-family: InterstateRegular; }
  .Dialog_dialog-list__M1Nvo .Dialog_system-message__2pKcS {
    margin: 1.6rem auto;
    padding: 0.8rem;
    background-color: #EAEDEE;
    border-radius: 4px;
    color: #5D6B71;
    max-width: 100%; }
    .Dialog_dialog-list__M1Nvo .Dialog_system-message__2pKcS .Dialog_system-message-content__2OACz {
      display: flex; }
    .Dialog_dialog-list__M1Nvo .Dialog_system-message__2pKcS .Dialog_system-message-time__35Zo8 {
      padding-right: 1.6rem; }
  .Dialog_dialog-list__M1Nvo .Dialog_message-time__2kZeX {
    font-size: 1.4rem;
    padding-left: 0.8rem;
    margin-top: -10px; }
  .Dialog_dialog-list__M1Nvo .Dialog_message-by-user-wrapper__16xiy {
    margin-left: auto;
    margin-right: 70px; }
    .Dialog_dialog-list__M1Nvo .Dialog_message-by-user-wrapper__16xiy .Dialog_userHeadWrapper__25Jit {
      text-align: right;
      display: block;
      margin-right: -60px;
      margin-top: -20px;
      margin-bottom: 10px; }
    .Dialog_dialog-list__M1Nvo .Dialog_message-by-user-wrapper__16xiy .Dialog_dialog-bubble__37F9g:after {
      content: "";
      position: absolute;
      bottom: -10px;
      -webkit-transform: rotate(213deg);
      transform: rotate(213deg);
      right: -2px;
      border-left: 35px solid transparent;
      border-right: -11px solid transparent;
      border-top: 15px solid #f6f5ed; }
  .Dialog_dialog-list__M1Nvo .Dialog_message-by-counterpart-wrapper__yXIvW {
    margin-right: auto;
    margin-left: 70px; }
    .Dialog_dialog-list__M1Nvo .Dialog_message-by-counterpart-wrapper__yXIvW .Dialog_userHeadWrapper__25Jit {
      text-align: left;
      display: block;
      margin-left: -60px;
      margin-top: -20px;
      margin-bottom: 10px; }
    .Dialog_dialog-list__M1Nvo .Dialog_message-by-counterpart-wrapper__yXIvW .Dialog_message-time__2kZeX {
      text-align: right; }
  .Dialog_dialog-list__M1Nvo .Dialog_dialog-bubble__37F9g {
    box-sizing: border-box;
    padding: 1.6rem;
    margin: 0.8rem 0 1.6rem 0;
    border-radius: 4px;
    position: relative;
    background-color: #F6F5ED;
    color: #333333;
    font-family: InterstateRegular;
    font-size: 12px; }
    .Dialog_dialog-list__M1Nvo .Dialog_dialog-bubble__37F9g .Dialog_message-sender__3g4PD {
      font-style: italic;
      font-weight: bold;
      font-size: 1.4rem;
      margin-bottom: 0.8rem; }
    .Dialog_dialog-list__M1Nvo .Dialog_dialog-bubble__37F9g:last-child {
      margin-bottom: 3.2rem; }
    .Dialog_dialog-list__M1Nvo .Dialog_dialog-bubble--by-counterpart__QSw-3 {
      background-color: #cce0ff;
      border: 1px solid #C4CED4;
      color: #333333;
      font-family: InterstateRegular;
      font-size: 12px; }
      .Dialog_dialog-list__M1Nvo .Dialog_dialog-bubble--by-counterpart__QSw-3:after {
        border-color: #cce0ff #cce0ff transparent transparent;
        left: 30px; }
      .Dialog_dialog-list__M1Nvo .Dialog_dialog-bubble--by-counterpart__QSw-3:before {
        content: "";
        display: block;
        border: 8px solid;
        border-color: #C4CED4 #C4CED4 transparent transparent;
        width: 0;
        height: 0;
        position: absolute;
        bottom: -9px;
        left: -2px;
        -webkit-transform: rotate(135deg);
                transform: rotate(135deg);
        border-left: -11px solid transparent;
        border-right: 35px solid transparent;
        border-top: 15px solid #cce0ff; }

.Dialog_upload-input__3ewPB {
  margin-top: 0.8rem; }

.Dialog_userHeadName__s_a4g,
.Dialog_message-time__2kZeX {
  display: block;
  color: #33434B;
  font-family: 'InterstateLight';
  font-size: 13px; }

.Dialog_userHeadName__s_a4g {
  padding-top: 10px; }

.Dialog_userHeadSVG__3Oj0o {
  width: 45px; }

.AttachmentPreviewList_list__1Ll13 {
  list-style: none;
  padding: 0; }
  .AttachmentPreviewList_list__1Ll13 .AttachmentPreviewList_list-element__5uZH9 {
    margin: 0 0 0.8rem;
    padding: 0.8rem;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #1585BB;
    color: #1585BB;
    border-radius: 4px;
    font-size: 1.2rem;
    font-weight: bold;
    max-width: 100%; }
    .AttachmentPreviewList_list__1Ll13 .AttachmentPreviewList_list-element__5uZH9 > a {
      color: #1585BB;
      white-space: nowrap;
      overflow: hidden;
      max-width: 100%;
      text-overflow: ellipsis; }
    .AttachmentPreviewList_list__1Ll13 .AttachmentPreviewList_list-element__5uZH9 > button {
      margin-left: 0.8rem;
      padding: 0;
      border-radius: 50%;
      border: none;
      display: inline-block;
      width: 2rem;
      height: 2rem;
      background-color: #1585BB;
      color: white;
      position: relative; }
      .AttachmentPreviewList_list__1Ll13 .AttachmentPreviewList_list-element__5uZH9 > button:hover {
        background-color: #15587B; }
      .AttachmentPreviewList_list__1Ll13 .AttachmentPreviewList_list-element__5uZH9 > button:after {
        content: "x";
        position: absolute;
        font-size: 1.6rem;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0; }
  .AttachmentPreviewList_list--inverted__3tuuG .AttachmentPreviewList_list-element__5uZH9 {
    color: white;
    background-color: #1585BB; }
    .AttachmentPreviewList_list--inverted__3tuuG .AttachmentPreviewList_list-element__5uZH9 > a {
      color: white; }

@media screen and (min-width: 769px) {
  .AttachmentPreviewList_list__1Ll13 .AttachmentPreviewList_list-element__5uZH9:not(:last-child) {
    margin-right: 0.8rem; } }

.ImageUpload_input__2ovME {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1; }

.ImageUpload_input__2ovME:focus + .ImageUpload_label__1LBvE {
  outline: 1px dotted #000;
  outline: -webkit-focus-ring-color auto 5px; }

.ImageUpload_input__2ovME:disabled + .ImageUpload_label__1LBvE {
  opacity: .5; }
  .ImageUpload_input__2ovME:disabled + .ImageUpload_label__1LBvE:hover {
    text-decoration: none;
    cursor: default; }

.ImageUpload_label__1LBvE {
  cursor: pointer;
  color: #5D6B71;
  display: inline-flex;
  align-items: center;
  justify-content: flex-start; }
  .ImageUpload_label__1LBvE:hover {
    text-decoration: underline; }

.ImageUpload_icon__1H2ke {
  fill: #5D6B71;
  margin-right: 0.8rem;
  margin-top: -30px; }

.ImageUpload_uploaderBtn__SdmW8 {
  color: #90999E;
  margin-top: -30px;
  display: block; }

.DocumentUpload_input__1WyCK {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1; }

.DocumentUpload_input__1WyCK:focus + .DocumentUpload_label__2UzFr {
  outline: 1px dotted #000;
  outline: -webkit-focus-ring-color auto 5px; }

.DocumentUpload_input__1WyCK:disabled + .DocumentUpload_label__2UzFr {
  opacity: .5; }
  .DocumentUpload_input__1WyCK:disabled + .DocumentUpload_label__2UzFr:hover {
    text-decoration: none;
    cursor: default; }

.DocumentUpload_label__2UzFr {
  cursor: pointer;
  color: #5D6B71;
  display: inline-flex;
  align-items: center;
  justify-content: flex-start; }
  .DocumentUpload_label__2UzFr:hover {
    text-decoration: underline; }

.DocumentUpload_icon__2JZCo {
  fill: #5D6B71;
  margin-right: 0.8rem;
  margin-top: -30px; }

.DocumentUpload_uploaderBtn__39IZ3 {
  color: #90999E;
  margin-top: -30px;
  display: block; }

.FullHeightModal_modal-background__2a88b {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
  background-color: rgba(51, 67, 75, 0.95); }

.FullHeightModal_modal-inner__1mnZs {
  position: fixed;
  box-sizing: border-box;
  top: 0;
  padding-top: 3.2rem;
  margin-bottom: 1.6rem;
  left: 50%;
  height: calc(100% - 1.6rem);
  width: 100%;
  max-width: 80rem;
  -webkit-transform: translate(-50%);
          transform: translate(-50%);
  overflow-y: hidden; }

.FullHeightModal_modal-close__3vtcG {
  color: white;
  font-size: 1.6rem;
  font-weight: bold;
  position: absolute;
  right: 1.6rem;
  top: 1rem;
  text-decoration: none; }
  .FullHeightModal_modal-close__3vtcG:hover {
    color: white;
    text-decoration: underline; }

.FullHeightModal_modal-content__3XJPy {
  height: 100%;
  margin-left: 1.6rem;
  margin-right: 1.6rem; }

.CenteredLoadingIcon_centered-icon__20FlL {
  display: block;
  margin: 0 auto; }

.CenteredLoadingIcon_text__9fkjR {
  margin-top: 1.6rem;
  text-align: center;
  font-size: 2rem; }

/* autoprefixer grid: autoplace */
.Header_main-header__3GShH {
  grid-area: header;
  box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.15);
  z-index: 1;
  font-size: 1.4rem;
  display: flex;
  justify-content: flex-start;
  align-items: center; }
  .Header_main-header__3GShH .Header_company-logo__3U6HP {
    height: 4rem;
    margin-left: 1.6rem; }
  .Header_main-header__3GShH .Header_building-name__2mvwE {
    margin-left: 1.6rem;
    flex-grow: 1; }
  .Header_main-header__3GShH .Header_building-and-contract__3r4Co .Header_dropdown-wrapper__3onVj {
    margin-right: 0.8rem; }
  .Header_main-header__3GShH .Header_building-and-contract__3r4Co .Header_text__2kV4K {
    font-size: 1.6rem;
    margin: 1.6rem 0.4rem 1.6rem 0.4rem; }
  .Header_main-header__3GShH .Header_user-context__3Z32q {
    display: flex;
    align-items: center; }
    .Header_main-header__3GShH .Header_user-context__3Z32q .Header_notification-panel__YEf_X {
      padding-right: 1.6rem; }
    .Header_main-header__3GShH .Header_user-context__3Z32q .Header_logout-button__3TPVB {
      border-left: 1px solid #F8F8F8; }
      .Header_main-header__3GShH .Header_user-context__3Z32q .Header_logout-button__3TPVB > svg {
        fill: #33434B;
        margin: 0;
        padding: 1.6rem;
        box-sizing: content-box; }
        .Header_main-header__3GShH .Header_user-context__3Z32q .Header_logout-button__3TPVB > svg:hover {
          opacity: .5; }
        @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
          .Header_main-header__3GShH .Header_user-context__3Z32q .Header_logout-button__3TPVB > svg {
            width: 20px;
            height: 20px; } }


.SelectInput_select-wrapper__2W3Aj {
  display: inline-block;
  position: relative;
  margin: 0.4rem 0 0.4rem 0; }
  .SelectInput_select-wrapper__2W3Aj:after {
    content: "";
    width: 3.2rem;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    border-left: 1px solid #5D6B71;
    background-image: url(/static/media/ArrowDown.70ffddec.svg);
    background-repeat: no-repeat;
    background-position: center;
    pointer-events: none; }

.SelectInput_fullWidth__1rfoo {
  width: 100%;
  margin-bottom: 30px; }

.SelectInput_select__8Kop5 {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: inherit;
  color: #5D6B71;
  border: 1px solid #5D6B71;
  border-radius: 4px;
  background-color: white;
  padding: 0.8rem 4rem 0.8rem 0.8rem;
  font-weight: bold;
  font-size: 1.2rem; }

/* Remove default arrow in IE 11 */
.SelectInput_select__8Kop5::-ms-expand {
  display: none; }

.BuildingList_building-list__25g_N,
.BuildingList_links-list__1cUh6 {
  list-style: none;
  margin: 0;
  padding: 0; }

.BuildingList_building-list__25g_N {
  display: flex;
  flex-wrap: wrap;
  max-width: calc(100vw - 900px); }
  .BuildingList_building-list__25g_N .BuildingList_building-list-item__1amo6 {
    margin-bottom: 0.8rem;
    margin-right: 0.8rem;
    flex-grow: 1;
    max-width: 35rem; }

.BuildingList_links-list__1cUh6 .BuildingList_links-list-item__2-WzD {
  padding: 1.6rem; }
  .BuildingList_links-list__1cUh6 .BuildingList_links-list-item__2-WzD:not(:last-child) {
    margin-bottom: 0.8rem; }
  .BuildingList_links-list__1cUh6 .BuildingList_links-list-item__2-WzD > a {
    font-weight: bold;
    color: #15587B;
    margin: -1.6rem;
    padding: 1.6rem; }
  .BuildingList_links-list__1cUh6 .BuildingList_links-list-item__2-WzD .BuildingList_links-list-item-content__3DnVi {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative; }
    .BuildingList_links-list__1cUh6 .BuildingList_links-list-item__2-WzD .BuildingList_links-list-item-content__3DnVi .BuildingList_arrow-icon__3WlFU {
      position: absolute;
      right: 0;
      -webkit-transform: rotate(-90deg);
              transform: rotate(-90deg); }
    .BuildingList_links-list__1cUh6 .BuildingList_links-list-item__2-WzD .BuildingList_links-list-item-content__3DnVi > svg {
      fill: #15587B; }
      .BuildingList_links-list__1cUh6 .BuildingList_links-list-item__2-WzD .BuildingList_links-list-item-content__3DnVi > svg:not(.BuildingList_arrow-icon__3WlFU) {
        margin-right: 1.6rem; }

.BuildingList_building-header-block__2X-Tp {
  padding: 3.2rem;
  margin: -1.6rem -1.6rem 1.6rem -1.6rem;
  text-align: center;
  position: relative;
  background-color: white;
  z-index: 2; }
  .BuildingList_building-header-block__2X-Tp:after {
    content: "";
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    z-index: -1;
    background-color: white; }
  .BuildingList_building-header-block__2X-Tp:before {
    content: "";
    position: absolute;
    bottom: 1px;
    width: 100%;
    z-index: -1;
    left: 0;
    -webkit-transform: scale(0.99);
            transform: scale(0.99);
    box-shadow: 0 0 10px 1px #839097; }
  .BuildingList_building-header-block__2X-Tp > h3 {
    color: #15587B;
    font-size: 2rem;
    font-weight: normal;
    margin-top: 0.8rem; }
    .BuildingList_building-header-block__2X-Tp > h3 span {
      font-size: 1.6rem;
      text-transform: capitalize; }

@media screen and (min-width: 769px) {
  .BuildingList_building-header-block__2X-Tp {
    margin: -1.6rem -3.2rem 1.6rem -3.2rem; } }

.BuildingIcon_wrapper__GO1yb {
  display: inline-block;
  fill: #15587B;
  width: 2.1rem;
  height: auto; }
  .BuildingIcon_wrapper__GO1yb svg {
    width: 100%;
    height: auto; }
  .BuildingIcon_wrapper__GO1yb.BuildingIcon_round__2RMKJ {
    height: 2.1rem;
    background-color: #15587B;
    fill: white;
    border-radius: 1000px;
    padding: 0.8rem;
    box-sizing: content-box; }

.BuildingViewLayout_navigation__1LckG {
  display: flex;
  justify-content: space-around;
  border-bottom: 1px solid #C4CED4;
  margin-bottom: 3.2rem; }

.BuildingViewLayout_navigation-list__GykBi {
  list-style: none;
  margin: 0;
  display: inline-flex;
  justify-content: space-around;
  padding: 0.8rem; }
  .BuildingViewLayout_navigation-list__GykBi .BuildingViewLayout_navigation-list-item__Az9XB {
    padding: 0;
    margin: 0;
    text-align: center; }
    .BuildingViewLayout_navigation-list__GykBi .BuildingViewLayout_navigation-list-item__Az9XB > .BuildingViewLayout_navigation-link__S1_G1 {
      display: inline-block;
      font-size: 1.8rem;
      position: relative;
      min-width: 10rem;
      padding: 0; }
      .BuildingViewLayout_navigation-list__GykBi .BuildingViewLayout_navigation-list-item__Az9XB > .BuildingViewLayout_navigation-link__S1_G1.BuildingViewLayout_disabled__2hRqy {
        color: #33434B;
        opacity: .5;
        cursor: default; }
      .BuildingViewLayout_navigation-list__GykBi .BuildingViewLayout_navigation-list-item__Az9XB > .BuildingViewLayout_navigation-link--active__wN1h- {
        color: #15587B; }
        .BuildingViewLayout_navigation-list__GykBi .BuildingViewLayout_navigation-list-item__Az9XB > .BuildingViewLayout_navigation-link--active__wN1h-:after {
          content: "";
          height: 1px;
          position: absolute;
          background-color: #15587B;
          width: 100%;
          bottom: -0.9rem;
          left: 0; }

@media screen and (min-width: 769px) {
  .BuildingViewLayout_navigation-list__GykBi {
    padding: 1.6rem; }
    .BuildingViewLayout_navigation-list__GykBi .BuildingViewLayout_navigation-list-item__Az9XB > .BuildingViewLayout_navigation-link__S1_G1 {
      padding: 0 3.2rem; }
      .BuildingViewLayout_navigation-list__GykBi .BuildingViewLayout_navigation-list-item__Az9XB > .BuildingViewLayout_navigation-link--active__wN1h-:after {
        bottom: -1.7rem; }
    .BuildingViewLayout_navigation-list__GykBi .BuildingViewLayout_navigation-list-item__Az9XB:not(:last-child) {
      margin-right: 6.4rem; } }

.Contacts_main-contact-info__1gHxS {
  text-align: center;
  border: 1px solid #C4CED4;
  width: 100%;
  padding: 0.8rem;
  border-radius: 100px;
  margin-bottom: 1.6rem; }
  .Contacts_main-contact-info__1gHxS > a {
    font-size: 1.6rem;
    font-weight: bold;
    display: block; }
    .Contacts_main-contact-info__1gHxS > a:not(:last-child) {
      padding-bottom: 0.8rem; }
  .Contacts_main-contact-info__1gHxS > h2 {
    text-transform: uppercase;
    padding-bottom: 0.8rem;
    margin: 0;
    font-size: 1.4rem; }

.Contacts_no-wrap__2FNNU {
  white-space: nowrap; }

.Contacts_kundesenter-info__XvFNj {
  background-color: rgba(196, 206, 212, 0.5) !important; }
  .Contacts_kundesenter-info__XvFNj svg {
    bottom: 0; }

.Contacts_sortableTableHeader__W6BUq {
  cursor: pointer; }

.Contacts_building-contacts-table__3U8Hn,
.Contacts_contract-contacts-table__4jkzY {
  margin: 0.8rem 0 1.6rem 0;
  width: 100%; }

.Contacts_queueTable__XqjTM {
  width: 100%; }

@media screen and (min-width: 769px) {
  .Contacts_main-contact-info__1gHxS {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 1.6rem; }
    .Contacts_main-contact-info__1gHxS > a,
    .Contacts_main-contact-info__1gHxS > h2 {
      padding-bottom: 0 !important; } }

.Contacts_formBtnWrapper__hWKHz {
  text-align: center; }
  .Contacts_formBtnWrapper__hWKHz .Contacts_contactFormBtn__2z9j6 {
    background-color: #333334;
    font-size: 13px;
    font-family: InterstateRegular;
    border-radius: 4px;
    padding: 10px 20px;
    color: white;
    margin-top: 32px;
    display: inline-block;
    cursor: pointer; }
  .Contacts_formBtnWrapper__hWKHz .Contacts_contactFormCancel__1vRcX {
    font-size: 11px;
    font-family: InterstateRegular;
    background-color: #869197;
    border-radius: 4px;
    padding: 10px 20px;
    color: white;
    margin-top: 15px;
    display: inline-block;
    cursor: pointer;
    margin-right: 10px; }

.Contacts_addContactWrapper__2CGKM {
  text-align: left;
  margin-right: 18px;
  margin-bottom: 100px;
  margin-top: 15px; }

.Contacts_buttonTd__22e2h {
  width: 100px; }

.Contacts_formInput__2_mqI {
  width: 365px;
  height: 24px;
  margin-right: 20px;
  border: 1px #333333 solid;
  display: block;
  font-family: InterstateLight; }

.Contacts_contactNewForm__3Eu7t {
  padding: 144px 52px 56px 52px; }
  .Contacts_contactNewForm__3Eu7t .Contacts_contactFormHeader__3cRuZ {
    color: #333333;
    font-family: InterstateBold;
    font-size: 22px;
    margin-bottom: 15px;
    display: inline-block; }
  .Contacts_contactNewForm__3Eu7t label {
    font-size: 11px;
    font-family: InterstateLight;
    color: #333333;
    margin-bottom: 4px;
    display: inline-block; }
  .Contacts_contactNewForm__3Eu7t .Contacts_formRow__3smla {
    margin-bottom: 15px; }

.Contacts_disabledBtn__34nzp {
  background-color: #cacaca;
  cursor: text; }

.Contacts_toBeDeleted__3VHay {
  -webkit-text-decoration-line: line-through;
          text-decoration-line: line-through; }

.Contacts_warningMessage__12O7P {
  font-size: 14px;
  font-weight: 400;
  background: #FFE77F;
  min-height: 32px;
  width: 100%;
  display: flex;
  padding: 12px;
  margin-top: 22px;
  color: #C60018;
  border-radius: 4px;
  border: solid 1px #FFE77F;
  font-family: InterstateRegular; }
  .Contacts_warningMessage__12O7P span {
    font-family: InterstateRegular;
    padding-left: 10px; }

.Contacts_warningMessageForm__1ZXLQ {
  font-size: 11px;
  background: #FFE77F;
  width: 365px;
  padding: 6px;
  margin-top: 10px;
  color: #C60018;
  border-radius: 4px;
  border: solid 1px #FFE77F;
  font-family: InterstateRegular; }

.Contacts_rolesWarningMessage__1THU0 {
  font-size: 16px;
  font-weight: 400;
  background: #fff4ce;
  min-height: 32px;
  width: 100%;
  display: flex;
  padding: 12px;
  margin-top: 22px;
  color: #ff5555; }

.Contacts_contactRowActionBtnTD___fTqB {
  visibility: hidden; }

.Contacts_tableRowWrapper__31EyJ td {
  padding: 1.6rem; }

.Contacts_tableRowWrapper__31EyJ:hover {
  background-color: #cce0ff; }
  .Contacts_tableRowWrapper__31EyJ:hover .Contacts_contactRowActionBtnTD___fTqB {
    visibility: visible; }
    .Contacts_tableRowWrapper__31EyJ:hover .Contacts_contactRowActionBtnTD___fTqB span {
      font-family: InterstateBold;
      cursor: pointer; }

.Contacts_roleName__2-ill {
  position: relative;
  display: inline-block;
  text-transform: capitalize; }

.Contacts_roleName__2-ill > .Contacts_roleNameTooltip__35Qxn {
  visibility: hidden;
  width: 180px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 150%;
  left: 50%;
  margin-left: -90px;
  text-transform: none; }

.Contacts_roleName__2-ill > .Contacts_roleNameTooltip__35Qxn::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent; }

.Contacts_roleName__2-ill:hover > .Contacts_roleNameTooltip__35Qxn {
  visibility: visible; }

.Table_table__eqWor {
  border-collapse: collapse;
  margin-left: -1px; }
  .Table_table__eqWor > caption {
    text-align: left;
    font-size: 1.4rem;
    text-transform: uppercase;
    font-weight: bold;
    margin-bottom: 1.6rem;
    margin-left: 1px; }
  .Table_table__eqWor th {
    background-color: transparent;
    text-transform: uppercase;
    text-align: left;
    font-size: 1.4rem;
    padding: 0.8rem 1.6rem; }
  .Table_table__eqWor td {
    border-bottom: 2px solid white;
    box-sizing: border-box;
    color: #5D6B71; }
    .Table_table__eqWor td:first-child {
      border-left: 1px solid white; }
    .Table_table__eqWor td:last-child {
      border-right: 1px solid white; }

.BuildingHeaderBlock_header-block__3wmwj {
  padding: 3.2rem;
  margin-left: -0.8rem;
  margin-right: -0.8rem;
  background-color: white; }
  .BuildingHeaderBlock_header-block__3wmwj .BuildingHeaderBlock_buildings-list__1ecAj {
    list-style: none;
    text-align: center;
    margin: 0;
    padding: 0;
    height: 0;
    overflow: hidden; }
    .BuildingHeaderBlock_header-block__3wmwj .BuildingHeaderBlock_buildings-list--open__2chLs {
      height: auto; }
    .BuildingHeaderBlock_header-block__3wmwj .BuildingHeaderBlock_buildings-list__1ecAj > li {
      padding: 0;
      margin: 0; }
  .BuildingHeaderBlock_header-block__3wmwj .BuildingHeaderBlock_building-link-item__3HTmh {
    font-size: 1.6rem;
    text-transform: capitalize; }
    .BuildingHeaderBlock_header-block__3wmwj .BuildingHeaderBlock_building-link-item__3HTmh:hover {
      opacity: .5; }
  .BuildingHeaderBlock_header-block__3wmwj .BuildingHeaderBlock_building-list-select__3a0xo {
    text-align: center;
    margin-bottom: 0.8rem; }
  .BuildingHeaderBlock_header-block__3wmwj .BuildingHeaderBlock_building-list-expand-button__3-mY5 {
    background-color: transparent;
    border: none;
    margin: 0;
    padding: 0;
    display: inline-flex;
    align-items: center; }
    .BuildingHeaderBlock_header-block__3wmwj .BuildingHeaderBlock_building-list-expand-button__3-mY5 .BuildingHeaderBlock_building-list-icon__1rMcc {
      width: 4rem;
      margin-right: 1.6rem; }
    .BuildingHeaderBlock_header-block__3wmwj .BuildingHeaderBlock_building-list-expand-button__3-mY5 .BuildingHeaderBlock_building-city__LLyyt, .BuildingHeaderBlock_header-block__3wmwj .BuildingHeaderBlock_building-list-expand-button__3-mY5 .BuildingHeaderBlock_building-street__2csl4 {
      font-size: 1.6rem;
      color: #15587B; }
    .BuildingHeaderBlock_header-block__3wmwj .BuildingHeaderBlock_building-list-expand-button__3-mY5 .BuildingHeaderBlock_building-street__2csl4 {
      text-align: center;
      font-weight: bold; }
    .BuildingHeaderBlock_header-block__3wmwj .BuildingHeaderBlock_building-list-expand-button__3-mY5 .BuildingHeaderBlock_building-city__LLyyt {
      text-transform: capitalize; }
    .BuildingHeaderBlock_header-block__3wmwj .BuildingHeaderBlock_building-list-expand-button__3-mY5 .BuildingHeaderBlock_building-list-button-icon__2X_bY {
      width: 2.8rem;
      height: auto;
      fill: #15587B; }

@media screen and (min-width: 769px) {
  .BuildingHeaderBlock_header-block__3wmwj {
    margin-left: -1.6rem;
    margin-right: -1.6rem; }
    .BuildingHeaderBlock_header-block__3wmwj .BuildingHeaderBlock_building-link-item__3HTmh {
      font-size: 2.4rem; }
  .BuildingHeaderBlock_building-city__LLyyt, .BuildingHeaderBlock_building-street__2csl4 {
    font-size: 2.4rem; } }

.DocumentsTable_documentsTable__3k5wT {
  width: 100%; }

.DocumentsTable_categories-wrapper__3JRPy {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  margin-bottom: 3.2rem;
  list-style-type: none; }

.DocumentsTable_category-element__1vEzP {
  display: block;
  margin-right: 0.8rem;
  margin-bottom: 0.8rem; }

.DocumentsTable_all-categories__Emb1O {
  margin-bottom: 0.8rem;
  margin-right: 0.8rem; }

.DocumentsTable_empty-list__2D7ZO {
  text-align: center; }
  .DocumentsTable_empty-list__2D7ZO header {
    margin-bottom: 1.6rem; }
  .DocumentsTable_empty-list__2D7ZO header > p {
    font-weight: bold; }
  .DocumentsTable_empty-list__2D7ZO .DocumentsTable_empty-list-hint__26_zH {
    color: #1585BB;
    font-weight: bold; }

.DocumentsTable_filter-options__e0Pc3 {
  margin-bottom: 50px; }

.DocumentsTable_filter-option__3piXF {
  margin-right: 0.8rem;
  display: inline-block;
  margin-bottom: 4px; }

.DocumentsTable_filterChoiceWrapper__13kuu {
  margin-left: 100px;
  top: -20px;
  position: relative; }

.PrimaryButton_button__1h6TJ {
  font-size: 14px;
  font-family: 'InterstateRegular';
  border: none;
  align-items: center;
  border-radius: 4px; }
  .PrimaryButton_button__1h6TJ[aria-disabled="true"] {
    opacity: .5;
    cursor: default; }

.IconButton_button__NZ7fy {
  border: none;
  padding: 0;
  font-size: 1.4rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: white;
  background-color: transparent; }
  .IconButton_button__NZ7fy[aria-disabled="true"] {
    opacity: .5;
    cursor: default; }
    .IconButton_button__NZ7fy[aria-disabled="true"]:hover, .IconButton_button__NZ7fy[aria-disabled="true"]:focus {
      color: #1585BB; }
      .IconButton_button__NZ7fy[aria-disabled="true"]:hover .IconButton_icon__kqSDC, .IconButton_button__NZ7fy[aria-disabled="true"]:focus .IconButton_icon__kqSDC {
        fill: #1585BB; }

.IconButton_icon__kqSDC {
  fill: #1585BB;
  margin-right: 0.8rem; }

.IconButton_button__NZ7fy:hover, .IconButton_button__NZ7fy:focus {
  color: #15587B; }
  .IconButton_button__NZ7fy:hover .IconButton_icon__kqSDC, .IconButton_button__NZ7fy:focus .IconButton_icon__kqSDC {
    fill: #15587B; }

.IssueInformationForm_submit-button__1hzIU {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  background-color: #1585BB;
  border: none;
  font-size: 1.8rem;
  color: white;
  height: 4.8rem;
  display: block; }

.IssueInformationForm_form__sC5gF {
  padding: 1.6rem;
  background-color: white;
  box-shadow: 0 1px 2px #C4CED4;
  min-height: 100%;
  box-sizing: border-box;
  max-width: 80rem;
  position: relative; }
  .IssueInformationForm_form__sC5gF > fieldset {
    border: none;
    margin: 3.2rem -1.6rem 0 -1.6rem;
    padding-bottom: 3.2rem;
    padding-left: 1.6rem;
    padding-right: 1.6rem; }
    .IssueInformationForm_form__sC5gF > fieldset:first-child {
      margin-top: 0; }
    .IssueInformationForm_form__sC5gF > fieldset:not(:last-of-type) {
      border-bottom: 1px solid #C4CED4; }

.IssueInformationForm_selected-category__25bQp {
  margin-top: 0.8rem;
  margin-bottom: 0 !important;
  font-size: 1.4rem;
  display: flex;
  align-items: center; }
  .IssueInformationForm_selected-category__25bQp .IssueInformationForm_category-arrow__11nXQ {
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
    fill: #1585BB;
    margin: 0 0.8rem; }
  .IssueInformationForm_selected-category__25bQp > .IssueInformationForm_category__38B5w {
    padding: 0.4rem 1.6rem;
    color: white;
    background-color: #1585BB;
    border-radius: 4px;
    display: inline-block; }

.IssueInformationForm_upload-input__1SQzl {
  margin-top: 0.8rem; }

.IssueInformationForm_loading-overlay__2Upo5 {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  background-color: rgba(51, 67, 75, 0.8); }
  .IssueInformationForm_loading-overlay__2Upo5 svg {
    margin-bottom: 1.6rem;
    fill: white; }

@media screen and (min-width: 769px) {
  .IssueInformationForm_submit-button__1hzIU {
    position: relative;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding-left: 3.2rem;
    padding-right: 3.2rem;
    margin-left: auto;
    display: flex;
    align-items: center; }
    .IssueInformationForm_submit-button__1hzIU > .IssueInformationForm_loading-icon__1iEsJ {
      width: 20px;
      height: 20px;
      margin-left: 0.8rem;
      fill: white; }
  .IssueInformationForm_form__sC5gF {
    padding: 1.6rem 3.2rem; } }

.IssueInformationForm_flex-left-column__psUcA {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start; }

.IssueInformationForm_select-dropdown__APNER {
  margin: 0.8rem 0 0.8rem 0;
  width: 100%; }
  .IssueInformationForm_select-dropdown__APNER > * {
    width: 100%; }
  .IssueInformationForm_select-dropdown__APNER > label {
    display: inline-block;
    margin-bottom: 0.6rem; }

.FullWidthTextField_input-wrapper__GRyEh, .FullWidthTextField_textarea-wrapper__1rj7S {
  background-color: white;
  margin-top: 0.8rem; }

.FullWidthTextField_input-wrapper__GRyEh {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative; }
  .FullWidthTextField_input-wrapper__GRyEh .FullWidthTextField_input__icC2D {
    padding: 2rem 0 0.8rem 0; }
  .FullWidthTextField_input-wrapper__GRyEh .FullWidthTextField_label__1a2QO {
    position: absolute;
    -webkit-transform-origin: top left;
            transform-origin: top left;
    -webkit-transform: translate(0, 0.6rem) scale(1);
            transform: translate(0, 0.6rem) scale(1);
    display: inline-block;
    transition: all .1s ease-in-out; }
    .FullWidthTextField_input-wrapper__GRyEh .FullWidthTextField_label__1a2QO[data-hasvalue=true] {
      -webkit-transform: translate(0, -1.2rem) scale(0.85);
              transform: translate(0, -1.2rem) scale(0.85);
      color: #33434B;
      opacity: 1; }
  .FullWidthTextField_input-wrapper__GRyEh .FullWidthTextField_input__icC2D:not([readonly]):focus + .FullWidthTextField_label__1a2QO {
    -webkit-transform: translate(0, -1.2rem) scale(0.85);
            transform: translate(0, -1.2rem) scale(0.85);
    color: #33434B;
    opacity: 1;
    color: #1585BB !important; }

.FullWidthTextField_textarea-wrapper__1rj7S {
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-start;
  align-items: flex-start; }
  .FullWidthTextField_textarea-wrapper__1rj7S .FullWidthTextField_label__1a2QO {
    padding-top: 1.6rem;
    box-sizing: border-box;
    display: block;
    width: 100%;
    padding-bottom: 0.8rem; }
  .FullWidthTextField_textarea-wrapper__1rj7S .FullWidthTextField_input__icC2D {
    resize: none;
    padding: 0.8rem;
    border: 1px solid #33434B;
    border-radius: 4px; }

.FullWidthTextField_input__icC2D {
  box-sizing: border-box;
  width: 100%;
  border: none;
  background-color: transparent;
  font-size: 1.6rem;
  color: #33434B;
  outline: none;
  padding: 1.6rem 3.2rem 1.6rem 3.2rem; }
  .FullWidthTextField_input__icC2D:not([readonly]) {
    border-bottom: 1px solid #33434B; }
  .FullWidthTextField_input__icC2D:not([readonly]):focus {
    color: #1585BB;
    border-color: #1585BB; }

.FullWidthTextField_input__icC2D:disabled, .FullWidthTextField_input__icC2D:disabled + .FullWidthTextField_label__1a2QO {
  opacity: .5; }

.FullWidthTextField_input__icC2D:not([readonly]):focus + .FullWidthTextField_label__1a2QO {
  color: #1585BB; }

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .FullWidthTextField_input-wrapper__GRyEh {
    flex-direction: row-reverse; }
    .FullWidthTextField_input-wrapper__GRyEh .FullWidthTextField_input__icC2D {
      padding: 2rem 0 0.8rem 0; }
    .FullWidthTextField_input-wrapper__GRyEh .FullWidthTextField_label__1a2QO[data-hasvalue=true] {
      -webkit-transform: translate(0, -1.6rem) scale(0.85);
              transform: translate(0, -1.6rem) scale(0.85);
      color: #33434B;
      opacity: 1; }
    .FullWidthTextField_input-wrapper__GRyEh .FullWidthTextField_input__icC2D:not([readonly]):focus + .FullWidthTextField_label__1a2QO {
      -webkit-transform: translate(0, -1.6rem) scale(0.85);
              transform: translate(0, -1.6rem) scale(0.85);
      color: #33434B;
      opacity: 1;
      color: #1585BB !important; } }

header.IssueCreatedConfirmation_confirmation-header__scn5w {
  text-align: center; }
  header.IssueCreatedConfirmation_confirmation-header__scn5w > span {
    font-weight: bold;
    display: inline-block;
    margin-bottom: 0.8rem; }
  header.IssueCreatedConfirmation_confirmation-header__scn5w > h1 {
    font-size: 2.2rem; }
  header.IssueCreatedConfirmation_confirmation-header__scn5w > p {
    font-size: 1.6rem;
    margin-bottom: 3.2rem; }

.IssueCreatedConfirmation_wrapper__1JOr0 {
  padding: 1.6rem;
  background-color: white;
  box-shadow: 0 1px 2px #C4CED4;
  min-height: 100%;
  box-sizing: border-box;
  max-width: 80rem;
  display: flex;
  flex-direction: column;
  align-items: center; }
  .IssueCreatedConfirmation_wrapper__1JOr0 > * {
    min-width: 100%; }

.IssueCreatedConfirmation_issue-card-wrapper__1JoPk {
  padding-bottom: 3.2rem; }

p.IssueCreatedConfirmation_info-message__3kMjk {
  text-align: center;
  margin-bottom: 3.2rem;
  padding-top: 3.2rem;
  border-top: 1px solid #C4CED4; }

.IssueCreatedConfirmation_dashboard-link-button__2Pi8c {
  display: block;
  background-color: #5D6B71;
  color: white;
  font-size: 1.8rem;
  padding: 1.6rem;
  text-decoration: none;
  text-align: center;
  transition: all .2s ease-in-out; }
  .IssueCreatedConfirmation_dashboard-link-button__2Pi8c:hover {
    color: white;
    background-color: #33434B; }

.IssueCreatedConfirmation_register-new-link__2MLSZ {
  margin-top: 3.2rem;
  font-size: 1.6rem;
  display: block;
  text-align: center; }

@media screen and (min-width: 769px) {
  .IssueCreatedConfirmation_dashboard-link-button__2Pi8c {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    min-width: auto;
    padding-left: 3.2rem;
    padding-right: 3.2rem; } }

/* autoprefixer grid: autoplace */
a.IssueCard_issue-card-body__3rg2L, div.IssueCard_issue-card-body__3rg2L {
  display: flex;
  justify-content: space-between; }
  a.IssueCard_issue-card-body--new-updates__1Tazt, div.IssueCard_issue-card-body--new-updates__1Tazt {
    border-left: 6px solid #1585BB; }
  a.IssueCard_issue-card-body--priority-high__3Qcnp .IssueCard_priority__3FOne, div.IssueCard_issue-card-body--priority-high__3Qcnp .IssueCard_priority__3FOne {
    color: #C4232A; }
  a.IssueCard_issue-card-body--priority-medium__3Y6Oa .IssueCard_priority__3FOne, div.IssueCard_issue-card-body--priority-medium__3Y6Oa .IssueCard_priority__3FOne {
    color: #EB8B2D; }
  a.IssueCard_issue-card-body--priority-low__5y5Am .IssueCard_priority__3FOne, div.IssueCard_issue-card-body--priority-low__5y5Am .IssueCard_priority__3FOne {
    color: #33434B; }

.IssueCard_issue-card-section__14DOK {
  display: -ms-grid;
  display: grid;
      grid-template-areas: "description          category" "location                    ." ". id" "updateCount status";
  -ms-grid-rows: auto 1fr auto auto;
  grid-template-rows: auto 1fr auto auto;
  width: 100%; }
  .IssueCard_issue-card-section__14DOK .IssueCard_id__ktLqY, .IssueCard_issue-card-section__14DOK .IssueCard_description__1o3vp, .IssueCard_issue-card-section__14DOK .IssueCard_location__3qtQW, .IssueCard_issue-card-section__14DOK .IssueCard_priority__3FOne, .IssueCard_issue-card-section__14DOK .IssueCard_status__WCYvC {
    margin: 0; }
  .IssueCard_issue-card-section__14DOK .IssueCard_id__ktLqY, .IssueCard_issue-card-section__14DOK .IssueCard_priority__3FOne, .IssueCard_issue-card-section__14DOK .IssueCard_status__WCYvC {
    color: #33434B; }
  .IssueCard_issue-card-section__14DOK .IssueCard_id__ktLqY {
    -ms-grid-row: 3;
    -ms-grid-column: 2;
    font-size: 1.2rem;
    font-weight: bold;
    grid-area: id;
    margin-top: 1.6rem; }
  .IssueCard_issue-card-section__14DOK .IssueCard_description__1o3vp {
    -ms-grid-row: 1;
    -ms-grid-column: 1;
    font-size: 2.4rem;
    grid-area: description;
    margin-top: 0.4rem; }
  .IssueCard_issue-card-section__14DOK .IssueCard_location__3qtQW {
    -ms-grid-row: 2;
    -ms-grid-column: 1;
    font-weight: bold;
    grid-area: location;
    display: inline-flex;
    align-items: center;
    margin-top: 0.8rem; }
  .IssueCard_issue-card-section__14DOK .IssueCard_priority__3FOne {
    font-weight: normal;
    display: flex;
    align-items: center;
    justify-self: end;
    justify-content: flex-end;
    grid-area: priority; }
  .IssueCard_issue-card-section__14DOK .IssueCard_status__WCYvC {
    -ms-grid-row: 4;
    -ms-grid-column: 2;
    font-size: 1.4rem;
    background-color: #C4CED4;
    padding: 0.8rem 1.6rem;
    fill: #33434B;
    border-radius: 50px;
    text-align: center;
    grid-area: status;
    display: flex;
    justify-content: space-between;
    align-items: center; }
    .IssueCard_issue-card-section__14DOK .IssueCard_status__WCYvC .IssueCard_status-icon__LnP3s {
      margin-right: 0.8rem; }
  .IssueCard_issue-card-section__14DOK .IssueCard_update-count__36B52 {
    -ms-grid-row: 4;
    -ms-grid-column: 1;
    grid-area: updateCount;
    -ms-grid-column-align: end;
        justify-self: end;
    background-color: #1585BB;
    color: white;
    padding: 0.8rem 1.6rem;
    border-radius: 50px;
    font-size: 1.2rem;
    font-weight: bold; }

.IssueCard_issue-card-section__14DOK {
  /*autoprefixer: off*/
  align-items: center;
  justify-items: start; }

.IssueCard_priority-icon__3l3uq {
  width: 15px;
  margin-right: 0.8rem; }
  .IssueCard_priority-icon--high__1iMQ0 {
    fill: #C4232A;
    -webkit-transform: rotate(135deg);
            transform: rotate(135deg); }
  .IssueCard_priority-icon--medium__3iYzD {
    fill: #EB8B2D; }
  .IssueCard_priority-icon--low__3T1-f {
    fill: #33434B;
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg); }

.HamburgerButton_button__2-8aL {
  border: none;
  background-color: transparent;
  padding: 1.6rem;
  display: flex;
  justify-content: space-between;
  align-items: center; }
  .HamburgerButton_button__2-8aL svg {
    height: 35px; }
    .HamburgerButton_button__2-8aL svg path {
      fill: white; }

.HamburgerButton_bar__3G3tN {
  display: block;
  height: 4px;
  width: 2.5rem;
  background-color: white;
  border-radius: 4px;
  -webkit-transform-origin: 4px 0;
          transform-origin: 4px 0;
  transition: all .2s ease-in-out; }
  .HamburgerButton_bar__3G3tN:not(:last-child) {
    margin-bottom: 5px; }

.HamburgerButton_button__2-8aL.HamburgerButton_button--open__1UONK .HamburgerButton_bar__3G3tN:nth-child(1) {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg); }

.HamburgerButton_button__2-8aL.HamburgerButton_button--open__1UONK .HamburgerButton_bar__3G3tN:nth-child(2) {
  display: none; }

.HamburgerButton_button__2-8aL.HamburgerButton_button--open__1UONK .HamburgerButton_bar__3G3tN:nth-child(3) {
  -webkit-transform: rotate(-45deg) translate(-4px);
          transform: rotate(-45deg) translate(-4px); }

.HamburgerButton_button-text__3UEtc {
  color: white;
  font-size: 1.6rem;
  font-weight: bold;
  padding-left: 0.8rem; }

.Modal_modal-background__1_f5u {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
  background-color: rgba(51, 67, 75, 0.95); }

.Modal_modal-inner__2E_LM {
  /* Positioning */
  box-sizing: border-box;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  padding: 1.6rem;
  overflow-y: auto;
  /* Other */
  display: flex;
  flex-direction: column; }
  @media screen and (min-width: 425px) {
    .Modal_modal-inner__2E_LM {
      left: 50%;
      height: auto;
      max-width: 80rem;
      -webkit-transform: translate(-50%, 3.2rem);
              transform: translate(-50%, 3.2rem);
      max-height: calc(100% - 3.2rem); } }

.Modal_modal-close__3xvT8 {
  text-decoration: none;
  align-self: flex-end;
  font-size: 1.6rem;
  font-weight: bold;
  color: white;
  margin-bottom: 0.8rem; }

.Modal_modal-content__1pAe5 {
  background-color: white; }

/* autoprefixer grid: autoplace */
.Services_ServiceFormWrapper__1sjIp {
  margin-bottom: 78px; }

.Services_service-canvas__2Xeev {
  border: 1px solid black;
  border-radius: 5px;
  width: 35rem;
  margin-top: 50px; }

.Services_service-category__1TB3l {
  height: 12.8rem;
  padding: 0.8rem;
  font-size: x-large; }

.Services_service-subcategory__1MP1m {
  border-top: 1px solid black;
  padding: 0.8rem;
  font-weight: bold; }

.Services_service-subcategory-expanded__3uZaT {
  border-top: 1px solid black;
  padding: 0.8rem;
  font-weight: bold;
  height: 12.8rem; }

.Services_service-subcategory-downarrow__1A4yJ {
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
  float: right;
  cursor: pointer; }

.Services_service-subcategory-uparrow__XXV4M {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
  float: right;
  cursor: pointer; }

.Services_service-image__3Z1yj {
  width: 35rem;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px; }

.Services_service-subcategory-description__1H9R9 {
  font-weight: normal;
  padding: 0.8rem; }

.Services_order-button__2GvgW {
  background-color: #1585BB;
  border: none;
  font-size: 1.8rem;
  color: white;
  height: 4rem;
  display: block;
  margin-left: auto;
  cursor: pointer; }

.Services_servicesWrapper__Jjs8L {
  max-width: 800px;
  min-width: 600px;
  padding-left: 64px;
  padding-right: 40px; }

.Services_newServiceBadge__34Sx-,
.Services_popularServiceBadge__1iFae {
  position: absolute;
  right: 14px;
  top: 11px;
  font-size: 12px;
  font-family: 'InterstateRegularCompressed';
  padding: 2px 5px 1px 6px;
  line-height: 15px;
  letter-spacing: 0;
  border-radius: 4px; }

.Services_newServiceBadge__34Sx- {
  background: #B3DC10; }

.Services_popularServiceBadge__1iFae {
  background: #cce0ff; }

.Services_circledNumber__1t7s4 {
  position: absolute;
  right: 0;
  width: 32px;
  height: 32px; }

.Services_page1Image__3jlhK {
  float: left;
  max-width: 138px;
  border-radius: 5px; }

.Services_page1MidRow__35rt9 {
  margin-bottom: 80px;
  display: block;
  overflow: auto; }

.Services_page1Title__LrjND {
  font-size: 22px;
  font-family: 'InterstateBold';
  display: block; }

.Services_page1Header__1i8xL {
  margin-bottom: 53px;
  color: #333333;
  text-transform: uppercase;
  text-align: center;
  font: normal normal bold 40px/48px InterstateBoldCompressed;
  letter-spacing: 0px;
  height: 48px;
  margin-top: 37px;
  display: inline-block; }

.Services_page1Desc__2ljem {
  font-size: 14px;
  font-family: 'InterstateRegular';
  display: block;
  margin-top: 8px;
  max-width: 380px; }

.Services_serviceFormHeader___Omrv {
  width: 100%; }

.Services_page1BtnWrapper__yYjpf,
.Services_page2BtnWrapper__1fizl {
  float: left;
  text-align: center;
  width: 100%; }

.Services_page1BodyWrapper__17_Xp {
  padding-left: 50px;
  padding-top: 30px;
  float: left; }

.Services_marginRight24__1eMUZ {
  margin-right: 24px; }

.Services_circle1__1QcXh {
  top: 200px;
  color: #BDC7CD; }

.Services_circle2__1tJ2s {
  top: 280px;
  color: #BDC7CD; }

.Services_circle3__1Z9t0 {
  top: 360px;
  color: #BDC7CD; }

.Services_link__3zxpD {
  cursor: pointer; }

.Services_dots1__1HsM6,
.Services_dots2__1djZ2 {
  position: absolute;
  height: 40px;
  right: 15px; }

.Services_dots1__1HsM6 {
  top: 237px; }

.Services_dots2__1djZ2 {
  top: 317px; }

.Services_activeCircleBtn__PJbFR path {
  fill: #33434B; }

.Services_doneStepCircle__3FGZl path {
  fill: #6B841E; }

.Services_doneStepCircle__3FGZl:hover path {
  fill: #377EB8; }

.Services_doneStepCircle__3FGZl line {
  stroke: #6B841E; }

.Services_page2Wrapper__2Ygs3 {
  text-align: center;
  margin-top: 84px; }

.Services_page3Wrapper__2d_yz,
.Services_page4Wrapper__2jBCE {
  text-align: center;
  margin-top: 84px; }

.Services_summaryBox__z6bbM {
  border: 1px solid #BDC7CD;
  border-radius: 5px;
  display: block;
  padding: 24px 16px;
  margin-top: 24px;
  margin-bottom: 60px;
  text-align: left; }

.Services_page2Address__3DDuC {
  color: #90999E;
  font-family: InterstateLight;
  font-size: 17px;
  line-height: 20px;
  margin-bottom: 24px;
  display: block; }

.Services_formLabel__rDzlN {
  font-size: 17px;
  font-family: InterstateRegular;
  text-align: left;
  color: #333333;
  display: block;
  margin-bottom: 10px; }

.Services_smallDescription__jKi-o {
  font-family: InterstateRegular;
  font-size: 10px;
  line-height: 12px;
  color: #333333;
  display: block;
  text-align: left;
  margin-top: -8px;
  margin-bottom: 10px; }

.Services_summaryDesc__2-Zq_ {
  font-family: InterstateRegular;
  font-size: 14px;
  line-height: 16px;
  color: #333333;
  display: block;
  text-align: left;
  margin-bottom: 8px; }

textarea {
  width: 100%;
  height: 120px;
  margin-bottom: 40px; }

.Services_page2Title__1wjYI,
.Services_page4Title__2o0nL {
  font-size: 22px;
  font-family: 'InterstateBold';
  display: block;
  margin-bottom: 24px; }

.Services_leftTitle__3s3ms {
  font-size: 22px;
  font-family: 'InterstateBold';
  display: block;
  text-align: left;
  margin-bottom: 16px; }

.Services_titleCompressed__1C2sq {
  font-size: 40px;
  line-height: 48px;
  font-family: 'InterstateBoldCompressed';
  display: block; }

.Services_singleInput__T32st,
.Services_registeredLabel__SNyK3 {
  width: 100%;
  font-family: InterstateLight;
  font-size: 17px;
  color: #90999E;
  padding-top: 6px;
  padding-bottom: 6px; }

.Services_singleInput__T32st {
  border: 1px solid #707070;
  padding: 6px 16px;
  margin-bottom: 40px; }

.Services_registeredLabel__SNyK3 {
  text-align: left;
  display: block; }

.Services_confirmationSubHeader__2jCWX {
  color: #33434B;
  font-family: InterstateLight;
  font-size: 17px;
  line-height: 20px;
  display: block;
  text-align: center; }

.Services_confirmationSubHeaderWrapper__1mkaH {
  margin-bottom: 36px; }

.Services_wrapper50__3k3Pq {
  width: 50%;
  display: inline-block; }

.Services_wrapper50MarginLeft__2jpfp {
  display: inline-block;
  width: calc(50% - 10px);
  margin-left: 10px; }

.Services_imageUploadWrapper__3ZUUa {
  text-align: left;
  margin-top: 10px;
  float: left;
  padding-right: 15px;
  margin-bottom: 40px; }
  .Services_imageUploadWrapper__3ZUUa svg,
  .Services_imageUploadWrapper__3ZUUa .Services_uploaderBtn__1g9oA {
    color: #90999E;
    margin-top: -30px;
    display: block; }

.Services_validationMessageWrapper__1rtDF {
  margin-top: -33px;
  display: block;
  float: left; }

@media screen and (max-width: 768px) {
  .Services_servicesWrapper__Jjs8L {
    width: 100%;
    padding-left: 40px;
    padding-right: 40px;
    margin-bottom: 78px; }
  .Services_page1Image__3jlhK {
    max-width: 100%; }
  .Services_page1BodyWrapper__17_Xp {
    padding-left: 0; } }

@media screen and (max-width: 769px) {
  .Notification_closeBox__1Enbv {
    visibility: visible;
    height: 10%; }
  .Notification_sidebar__EtfZ7.Notification_minemize__2j1YV {
    height: 5vh;
    position: absolute;
    bottom: 0; }
  .Notification_sidebar__EtfZ7 {
    background: #F6F5ED 0% 0% no-repeat padding-box;
    opacity: 1;
    position: relative;
    height: 100vh;
    width: 100vw;
    transition: height 0.5s;
    border-radius: 4px;
    box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.1); }
    .Notification_sidebar__EtfZ7 .Notification_button__eivHV {
      background-color: #F6F5ED;
      width: 100%;
      word-wrap: break-word;
      padding: 0;
      border: none;
      background: none;
      font: normal normal normal 1.5rem/2rem InterstateRegular;
      text-transform: uppercase;
      letter-spacing: 0.03px;
      color: #333333;
      opacity: 1; }
    .Notification_sidebar__EtfZ7 .Notification_buttonShow__33aNB {
      background-color: #F6F5ED;
      width: 100%;
      height: 100%;
      white-space: nowrap;
      border: none;
      background: none;
      font: normal normal normal 1.5rem/1.5rem InterstateRegular;
      text-transform: uppercase; }
    .Notification_sidebar__EtfZ7 .Notification_ShowBttnBox__1V-9X {
      width: 5%;
      height: 100%;
      background-color: #F6F5ED;
      visibility: hidden;
      border: none;
      background: none;
      bottom: 0; }
    .Notification_sidebar__EtfZ7 .Notification_ShowBttnBox__1V-9X:hover {
      background-color: #377EB8; }
    .Notification_sidebar__EtfZ7 .Notification_ShowBttnBox__1V-9X:hover .Notification_buttonShow__33aNB {
      color: white; }
    .Notification_sidebar__EtfZ7 .Notification_ShowBttnBox__1V-9X:hover:after {
      position: relative;
      top: 50%;
      left: -5px;
      width: 0;
      height: 0;
      border-right: solid 5px red;
      border-bottom: solid 5px transparent;
      border-top: solid 5px transparent; }
    .Notification_sidebar__EtfZ7 .Notification_ShowBttnBox__1V-9X.Notification_visible__t--2J {
      transition: visibility 0s linear 300ms, opacity 300ms;
      width: 100%;
      visibility: visible; }
    .Notification_sidebar__EtfZ7 .Notification_HideBttnBox__ADHv3 {
      position: relative;
      top: 50%;
      width: 100%;
      white-space: nowrap;
      background-color: #F6F5ED;
      padding: 0;
      border: none;
      background: none; }
    .Notification_sidebar__EtfZ7 .Notification_button__eivHV:hover {
      color: #377EB8;
      text-decoration: underline; }
    .Notification_sidebar__EtfZ7 .Notification_HideBttnBox__ADHv3.Notification_hide__3VFNe {
      visibility: hidden;
      width: 0px;
      height: 0px; }
    .Notification_sidebar__EtfZ7 .Notification_boxes__7CW9K {
      width: 100%;
      background-color: #F6F5ED;
      height: 10%; }
    .Notification_sidebar__EtfZ7 .Notification_boxes__7CW9K.Notification_hide__3VFNe {
      visibility: hidden;
      width: 0%;
      height: 0%; }
    .Notification_sidebar__EtfZ7 .Notification_boxes2__hbm6f {
      width: 100%;
      height: 80%;
      background-color: #F6F5ED; }
    .Notification_sidebar__EtfZ7 .Notification_boxes2__hbm6f.Notification_hide2__2UfLl {
      height: 100%; }
    .Notification_sidebar__EtfZ7 .Notification_boxes3__12_xP {
      width: 100%;
      height: 10%;
      background-color: #F6F5ED; }
    .Notification_sidebar__EtfZ7 .Notification_boxes3__12_xP.Notification_hide__3VFNe {
      visibility: hidden;
      width: 0%;
      height: 0%; }
    .Notification_sidebar__EtfZ7 .Notification_lukkBox__3wS-n {
      position: relative;
      top: 10%;
      left: 10%;
      width: 80%;
      height: auto;
      background-color: white;
      text-align: center;
      border-radius: 4px;
      box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.1);
      font: normal normal normal 1.5rem/2rem InterstateRegular;
      visibility: hidden; }
    .Notification_sidebar__EtfZ7 .Notification_lukkBox__3wS-n.Notification_hide__3VFNe {
      visibility: visible; }
    .Notification_sidebar__EtfZ7 .Notification_NotBox__3bRfj {
      border: 1px;
      width: 90%;
      background-color: #F6F5ED;
      height: 100%;
      overflow: scroll; }
    .Notification_sidebar__EtfZ7 .Notification_NotBox__3bRfj::-webkit-scrollbar {
      display: none; }
    .Notification_sidebar__EtfZ7 .Notification_NotBox__3bRfj.Notification_hide__3VFNe {
      height: 0%;
      visibility: hidden; }
    .Notification_sidebar__EtfZ7 .Notification_NotBox__3bRfj > * {
      margin-bottom: 10px; }
  .Notification_show__2JZDi {
    visibility: visible;
    height: 400px; }
  div .Notification_focus__j7vlR {
    height: -webkit-min-content;
    height: -moz-min-content;
    height: min-content;
    max-height: 5000px;
    width: 100%;
    cursor: default; }
  div .Notification_hide__3VFNe {
    height: 0%;
    visibility: hidden; }
  div .Notification_hidePanel__I4AbW {
    max-height: 0px;
    visibility: hidden;
    margin-bottom: 0px;
    transition: visibility 0s linear 0.5s, max-height 0.5s, margin-bottom 0.5s 0s; } }

@media screen and (min-width: 769px) {
  .Notification_sidebar__EtfZ7.Notification_minemize__2j1YV {
    width: 50px; }
  .Notification_sidebar__EtfZ7.Notification_minemize__2j1YV:hover:after {
    position: absolute;
    top: 50%;
    left: -10px;
    content: '';
    width: 0;
    height: 0;
    border-right: solid 10px #377EB8;
    border-bottom: solid 10px transparent;
    border-top: solid 10px transparent; }
  .Notification_sidebar__EtfZ7 {
    background: #F6F5ED 0% 0% no-repeat padding-box;
    opacity: 1;
    height: 95vh;
    width: 500px;
    right: 0;
    transition: width 0.5s;
    overflow: hidden;
    border-radius: 4px;
    box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.1); }
    .Notification_sidebar__EtfZ7 .Notification_button__eivHV {
      background-color: #F6F5ED;
      width: 100%;
      word-wrap: break-word;
      padding: 0;
      border: none;
      background: none;
      font: normal normal normal 14px/18px InterstateRegular;
      text-transform: uppercase;
      letter-spacing: 0.03px;
      color: #333333;
      opacity: 1; }
    .Notification_sidebar__EtfZ7 .Notification_buttonShow__33aNB {
      background-color: #F6F5ED;
      width: 100%;
      height: 100%;
      white-space: nowrap;
      border: none;
      background: none;
      -webkit-transform: rotate(-90deg);
              transform: rotate(-90deg);
      font: normal normal normal 18px/18px InterstateRegular;
      text-transform: uppercase; }
    .Notification_sidebar__EtfZ7 .Notification_ShowBttnBox__1V-9X {
      width: 5%;
      height: 100%;
      background-color: #F6F5ED;
      visibility: hidden;
      border: none;
      background: none;
      bottom: 0;
      cursor: pointer; }
    .Notification_sidebar__EtfZ7 .Notification_ShowBttnBox__1V-9X:hover {
      background-color: #377EB8; }
    .Notification_sidebar__EtfZ7 .Notification_ShowBttnBox__1V-9X:hover .Notification_buttonShow__33aNB {
      color: white;
      cursor: pointer; }
    .Notification_sidebar__EtfZ7 .Notification_ShowBttnBox__1V-9X:hover:after {
      position: relative;
      top: 50%;
      left: -5px;
      width: 0;
      height: 0;
      border-right: solid 5px #377EB8;
      border-bottom: solid 5px transparent;
      border-top: solid 5px transparent; }
    .Notification_sidebar__EtfZ7 .Notification_ShowBttnBox__1V-9X.Notification_visible__t--2J {
      transition: visibility 0s linear 300ms, opacity 300ms;
      width: 100%;
      visibility: visible; }
    .Notification_sidebar__EtfZ7 .Notification_HideBttnBox__ADHv3 {
      position: relative;
      top: 50%;
      width: 100%;
      white-space: nowrap;
      background-color: #F6F5ED;
      padding: 0;
      border: none;
      background: none; }
    .Notification_sidebar__EtfZ7 .Notification_button__eivHV:hover {
      color: #377EB8;
      text-decoration: underline;
      cursor: pointer; }
    .Notification_sidebar__EtfZ7 .Notification_HideBttnBox__ADHv3.Notification_hide__3VFNe {
      visibility: hidden;
      width: 0px;
      height: 0px; }
    .Notification_sidebar__EtfZ7 .Notification_boxes__7CW9K {
      width: 100%;
      background-color: #F6F5ED;
      height: 10%; }
    .Notification_sidebar__EtfZ7 .Notification_boxes__7CW9K.Notification_hide__3VFNe {
      visibility: hidden;
      width: 0%;
      height: 0%; }
    .Notification_sidebar__EtfZ7 .Notification_boxes2__hbm6f {
      width: 100%;
      height: 80%;
      background-color: #F6F5ED; }
    .Notification_sidebar__EtfZ7 .Notification_boxes2__hbm6f.Notification_hide2__2UfLl {
      height: 100%; }
    .Notification_sidebar__EtfZ7 .Notification_boxes3__12_xP {
      width: 100%;
      height: 10%;
      background-color: #F6F5ED; }
    .Notification_sidebar__EtfZ7 .Notification_boxes3__12_xP.Notification_hide__3VFNe {
      visibility: hidden;
      width: 0%;
      height: 0%; }
    .Notification_sidebar__EtfZ7 .Notification_lukkBox__3wS-n {
      position: relative;
      top: 10%;
      left: 6%;
      width: 88%;
      height: auto;
      background-color: white;
      text-align: center;
      border-radius: 4px;
      box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.1);
      padding-top: 10px;
      padding-bottom: 10px;
      visibility: hidden;
      cursor: pointer; }
    .Notification_sidebar__EtfZ7 .Notification_lukkBox__3wS-n > * {
      font: normal normal 300 12px/15px InterstateRegular; }
    .Notification_sidebar__EtfZ7 .Notification_lukkBox__3wS-n.Notification_visible__t--2J {
      visibility: visible; }
    .Notification_sidebar__EtfZ7 .Notification_NotBox__3bRfj {
      border: 1px;
      width: 90%;
      background-color: #F6F5ED;
      height: 100%;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      grid-column-gap: 10px;
      -webkit-column-gap: 10px;
              column-gap: 10px; }
  div .Notification_focus__j7vlR {
    height: -webkit-min-content;
    height: -moz-min-content;
    height: min-content;
    max-height: 5000px;
    width: 100%;
    cursor: default; }
  div .Notification_hide__3VFNe {
    max-height: 0px;
    visibility: hidden;
    margin-bottom: 0px; }
  div .Notification_hidePanel__I4AbW {
    max-height: 0px;
    visibility: hidden;
    margin-bottom: 0px;
    transition: visibility 0s linear 0.5s, max-height 0.5s, margin-bottom 0.5s 0s; } }

.Notification_panel__3Tpes {
  transition: visibility 0s linear 0s, max-height 0.5s, margin-bottom 0.5s 0s;
  border-radius: 4px;
  box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.1);
  background-color: white;
  color: #33434B;
  max-height: 100px;
  width: 100%;
  margin-bottom: 10px;
  overflow: hidden;
  cursor: pointer;
  position: relative; }
  .Notification_panel__3Tpes .Notification_title__xNOKS {
    font: normal normal bold 20px/24px InterstateRegularCompressed;
    letter-spacing: 0px;
    color: #333333;
    text-transform: uppercase;
    opacity: 1;
    margin-left: 10px;
    margin-top: 5px;
    justify-self: start;
    position: relative; }
  .Notification_panel__3Tpes .Notification_description__2b-mk {
    max-height: 0px;
    margin-left: 10px;
    word-wrap: break-word;
    visibility: hidden;
    transition: visibility 0.1s ease 0s, max-height 0.4s; }
  .Notification_panel__3Tpes .Notification_descriptionShow__3M47g {
    height: auto;
    max-height: 5000px;
    visibility: visible;
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 20px;
    transition: visibility 0.1s ease 0s, max-height 0.4s; }
  .Notification_panel__3Tpes .Notification_description__2b-mk > * {
    font: normal normal normal 13px/15px InterstateRegular !important; }
  .Notification_panel__3Tpes .Notification_teaser__2blRT {
    justify-self: start;
    margin-left: 10px;
    font: normal normal normal 13px/15px InterstateRegular; }
  .Notification_panel__3Tpes .Notification_link__1ugUc {
    margin-left: 10px;
    padding-bottom: 10px;
    float: left;
    position: relative;
    font: normal normal 300 12px/15px InterstateRegular; }
  .Notification_panel__3Tpes .Notification_timestamp__2-zBG {
    font: normal normal normal 0.8125rem/0.9375rem InterstateRegular;
    letter-spacing: 0px;
    align-self: end;
    justify-self: end;
    color: #839097;
    margin-right: 5px;
    margin-bottom: 5px; }
  .Notification_panel__3Tpes .Notification_pubdate__Tjc88 {
    text-align: left;
    font: normal normal 300 11px/14px InterstateRegular;
    letter-spacing: 0px;
    color: #333333;
    opacity: 1;
    position: absolute;
    bottom: 0;
    left: 0;
    margin-left: 10px;
    margin-bottom: 10px; }
  .Notification_panel__3Tpes .Notification_close__13_A0 {
    position: absolute;
    bottom: 0;
    right: 0;
    margin-right: 10px;
    margin-bottom: 10px;
    font: normal normal 300 12px/15px InterstateRegular;
    cursor: pointer; }

.Notification_list__1YPtu .Notification_caption__1wbIk {
  font-size: 40px;
  font-family: InterstateBoldCompressed;
  color: #33434B;
  line-height: 48px;
  margin-bottom: 40px; }

.Notification_list__1YPtu .Notification_filterTitle__2Ztl8 {
  float: left;
  font: normal normal 300 14px/16px InterstateRegular;
  color: #33434B;
  margin-left: 10px;
  margin-bottom: 25px;
  margin-top: 19px; }

.Notification_list__1YPtu .Notification_filterBttn__V96dp {
  float: left;
  margin-left: 10px;
  margin-bottom: 25px;
  margin-top: 10px;
  cursor: pointer; }

.Notification_list__1YPtu .Notification_filterBttn__V96dp:active {
  background-color: #214C6F; }

.Notification_list__1YPtu .Notification_filterBttnSvg__2YQcK {
  width: 10px;
  height: auto;
  float: right;
  margin: 1px; }

.Notification_list__1YPtu .Notification_filterBttnTxt__31NCD {
  float: left; }

.Notification_list__1YPtu .Notification_active__1ooTU {
  background-color: #cce0ff; }

.Notification_list__1YPtu .Notification_item__K3bBu.Notification_expand__1WKHA {
  max-height: 5000px;
  transition: max-height 1s; }

.Notification_list__1YPtu .Notification_item__K3bBu {
  background-color: #F6F5ED;
  margin-bottom: 15px;
  box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.1);
  display: grid;
  grid-template-columns: 250px 250px 0px;
  grid-template-rows: auto;
  grid-template-areas: "title publisert tab" "teaser teaser tab" "innhold innhold tab";
  width: 500px;
  max-height: 100px;
  transition: max-height 1s;
  overflow: hidden;
  cursor: pointer; }
  .Notification_list__1YPtu .Notification_item__K3bBu .Notification_sSvg__25e0z {
    width: 20px;
    height: auto;
    margin: auto; }
  .Notification_list__1YPtu .Notification_item__K3bBu .Notification_hover__34e2w {
    margin-top: 20px;
    display: block;
    grid-area: tab;
    visibility: hidden;
    justify-self: center;
    align-self: start; }
  .Notification_list__1YPtu .Notification_item__K3bBu .Notification_hover__34e2w * {
    display: block;
    justify-self: center;
    font: normal normal bold 12px/11px InterstateRegular; }
  .Notification_list__1YPtu .Notification_item__K3bBu .Notification_sTitle__3Uhz1 {
    grid-area: title;
    margin-top: 15px;
    margin-left: 15px;
    font: normal normal bold 20px/24px InterstateRegularCompressed;
    letter-spacing: 0px;
    color: #333333;
    text-transform: uppercase;
    overflow: hidden; }
  .Notification_list__1YPtu .Notification_item__K3bBu .Notification_sPublisert__3H7HT {
    grid-area: publisert;
    margin-top: 15px;
    margin-right: 15px;
    text-align: right;
    font: normal normal 300 11px/14px InterstateRegular;
    letter-spacing: 0px;
    color: #333333; }
  .Notification_list__1YPtu .Notification_item__K3bBu .Notification_sTeaser__2Q4-w {
    grid-area: teaser;
    margin-left: 15px;
    font: normal normal normal 13px/15px InterstateRegular; }
  .Notification_list__1YPtu .Notification_item__K3bBu .Notification_sInnhold__3dmvD {
    grid-area: innhold;
    width: 470px;
    margin-left: 15px;
    margin-right: 15px;
    margin-bottom: 20px;
    word-wrap: break-word; }
  .Notification_list__1YPtu .Notification_item__K3bBu .Notification_sInnhold__3dmvD > * {
    font: normal normal normal 13px/15px InterstateRegular !important; }

.Notification_list__1YPtu .Notification_item__K3bBu:hover > .Notification_hover__34e2w {
  transition: visibility 0s linear 0.3s;
  visibility: visible; }

.Notification_list__1YPtu .Notification_item__K3bBu:hover {
  transition: width ease 0.3s, max-height ease 0.5s;
  width: 600px;
  grid-template-columns: 250px 250px 100px;
  background-color: #cce0ff; }

/* autoprefixer grid: autoplace */
.NewIssue_service-canvas__4TRXw {
  border: 1px solid black;
  border-radius: 5px;
  width: 35rem;
  margin-top: 50px; }

.NewIssue_service-category__ZAMF0 {
  height: 12.8rem;
  padding: 0.8rem;
  font-size: x-large; }

.NewIssue_service-subcategory__QZhze {
  border-top: 1px solid black;
  padding: 0.8rem;
  font-weight: bold; }

.NewIssue_service-subcategory-expanded__2AWrB {
  border-top: 1px solid black;
  padding: 0.8rem;
  font-weight: bold;
  height: 12.8rem; }

.NewIssue_service-subcategory-downarrow__3FlvH {
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
  float: right;
  cursor: pointer; }

.NewIssue_service-subcategory-uparrow__qRFC_ {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
  float: right;
  cursor: pointer; }

.NewIssue_service-image__1t0gl {
  width: 35rem;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px; }

.NewIssue_service-subcategory-description__2ScjP {
  font-weight: normal;
  padding: 0.8rem; }

.NewIssue_order-button__3XWVQ {
  background-color: #1585BB;
  border: none;
  font-size: 1.8rem;
  color: white;
  height: 4rem;
  display: block;
  margin-left: auto;
  cursor: pointer; }

.NewIssue_servicesWrapper__2i9WD {
  max-width: 800px;
  min-width: 600px;
  padding-left: 64px;
  padding-right: 40px;
  margin-bottom: 78px; }

.NewIssue_newServiceBadge__3uUkS,
.NewIssue_popularServiceBadge__24NAq {
  position: absolute;
  right: 14px;
  top: 11px;
  font-size: 12px;
  font-family: 'InterstateRegularCompressed';
  padding: 2px 5px 1px 6px;
  line-height: 15px;
  letter-spacing: 0;
  border-radius: 4px; }

.NewIssue_newServiceBadge__3uUkS {
  background: #B3DC10; }

.NewIssue_popularServiceBadge__24NAq {
  background: #cce0ff; }

.NewIssue_circledNumber__1RJyJ {
  position: absolute;
  right: 0;
  width: 32px;
  height: 32px; }

.NewIssue_circledNumber__1RJyJ path {
  fill: #C9D1D6; }

.NewIssue_dots1__1sQ0P line,
.NewIssue_dots2__2RC2B line {
  stroke: #C9D1D6; }

.NewIssue_page1Image__1pshV {
  float: left;
  max-width: 138px;
  border-radius: 5px;
  margin-bottom: 80px; }

.NewIssue_page1Title__2RXzw {
  font-size: 22px;
  font-family: 'InterstateBold';
  display: block; }

.NewIssue_page1Header__l2C8E {
  margin-bottom: 53px;
  color: #333333;
  text-transform: uppercase;
  text-align: center;
  font: normal normal bold 40px/48px InterstateBoldCompressed;
  letter-spacing: 0px;
  height: 48px;
  margin-top: 37px;
  display: inline-block; }

.NewIssue_page1Desc__2YTGk {
  font-size: 14px;
  font-family: 'InterstateRegular';
  display: block;
  margin-top: 8px; }

.NewIssue_serviceFormHeader__1SdrU {
  float: left;
  width: 100%; }

.NewIssue_page1BtnWrapper__1XCJK,
.NewIssue_page2BtnWrapper__1uwk9 {
  float: left;
  text-align: center;
  width: 100%; }

.NewIssue_page1BodyWrapper__1eFb- {
  padding-left: 50px;
  padding-top: 30px;
  float: left; }

.NewIssue_marginRight24__19Aki {
  margin-right: 24px; }

.NewIssue_circle1__200_6 {
  top: 200px;
  color: #BDC7CD; }

.NewIssue_circle2__3nGi- {
  top: 280px;
  color: #BDC7CD; }

.NewIssue_circle3__mTzpq {
  top: 360px;
  color: #BDC7CD; }

.NewIssue_link__2ijTa {
  cursor: pointer; }

.NewIssue_dots1__1sQ0P,
.NewIssue_dots2__2RC2B {
  position: absolute;
  height: 40px;
  right: 15px; }

.NewIssue_dots1__1sQ0P {
  top: 237px; }

.NewIssue_dots2__2RC2B {
  top: 317px; }

.NewIssue_activeCircleBtn__1a1Jf path {
  fill: #33434B; }

.NewIssue_doneStepCircle__2Ecmj path {
  fill: #6B841E; }

.NewIssue_doneStepCircle__2Ecmj:hover path {
  fill: #377EB8; }

.NewIssue_doneStepCircle__2Ecmj line {
  stroke: #6B841E; }

.NewIssue_biggerThanSign__bOeUg {
  padding: 0 13px;
  font-family: InterstateRegular;
  font-size: 17px; }

.NewIssue_page2Wrapper__3Km5G {
  text-align: center;
  margin-top: 84px; }

.NewIssue_page3Wrapper__ph3fG,
.NewIssue_page4Wrapper__2KBZy {
  text-align: center;
  margin-top: 84px; }

.NewIssue_summaryBox__1M0Hc {
  border: 1px solid #BDC7CD;
  border-radius: 5px;
  display: block;
  padding: 24px 16px;
  margin-top: 24px;
  margin-bottom: 60px;
  text-align: left; }

.NewIssue_page2Address__1g_Aw {
  color: #90999E;
  font-family: InterstateLight;
  font-size: 17px;
  line-height: 20px;
  margin-bottom: 24px;
  display: block; }

.NewIssue_formLabel__1pkzy {
  font-size: 17px;
  font-family: InterstateRegular;
  text-align: left;
  color: #333333;
  display: block;
  margin-bottom: 10px; }

.NewIssue_smallDescription__3TO0l {
  font-family: InterstateRegular;
  font-size: 10px;
  line-height: 12px;
  color: #333333;
  display: block;
  text-align: left;
  margin-top: -8px;
  margin-bottom: 10px; }

.NewIssue_summaryDesc__2wNEd {
  font-family: InterstateRegular;
  font-size: 14px;
  line-height: 16px;
  color: #333333;
  display: block;
  text-align: left;
  margin-bottom: 8px; }

textarea {
  width: 100%;
  height: 120px;
  margin-bottom: 40px; }

.NewIssue_page2Title__2WiIW,
.NewIssue_page4Title__c--MK {
  font-size: 22px;
  font-family: 'InterstateBold';
  display: block;
  margin-bottom: 24px; }

.NewIssue_page2Title__2WiIW {
  margin-top: 60px; }

.NewIssue_leftTitle__gai-w {
  font-size: 22px;
  font-family: 'InterstateBold';
  display: block;
  text-align: left;
  margin-bottom: 16px; }

.NewIssue_titleCompressed__2cZM6 {
  font-size: 40px;
  line-height: 48px;
  font-family: 'InterstateBoldCompressed';
  display: block; }

.NewIssue_singleInput__1TClA,
.NewIssue_registeredLabel__oy-B3 {
  width: 100%;
  font-family: InterstateLight;
  font-size: 17px;
  color: #90999E;
  padding-top: 6px;
  padding-bottom: 6px; }

.NewIssue_singleInput__1TClA {
  border: 1px solid #707070;
  padding: 6px 16px;
  margin-bottom: 40px; }

.NewIssue_registeredLabel__oy-B3 {
  text-align: left;
  display: block; }

.NewIssue_confirmationSubHeader__3Hqyp {
  color: #33434B;
  font-family: InterstateLight;
  font-size: 17px;
  line-height: 20px;
  display: block;
  text-align: center; }

.NewIssue_confirmationSubHeaderWrapper__14D3R {
  margin-bottom: 36px; }

.NewIssue_wrapper50__3hWUf {
  width: 50%;
  display: inline-block; }

.NewIssue_wrapper50MarginLeft__3bUSg {
  display: inline-block;
  width: calc(50% - 10px);
  margin-left: 10px; }

.NewIssue_imageUploadWrapper__D70Ll {
  text-align: left;
  margin-top: 10px;
  float: left;
  padding-right: 15px;
  margin-bottom: 40px; }
  .NewIssue_imageUploadWrapper__D70Ll svg {
    color: #90999E;
    display: block; }

.NewIssue_validationMessageWrapper__1_xbz {
  margin-top: -33px;
  display: block;
  float: left; }

@media screen and (max-width: 768px) {
  .NewIssue_servicesWrapper__2i9WD {
    width: 100%;
    padding-left: 40px;
    padding-right: 40px;
    margin-bottom: 78px; }
  .NewIssue_page1BodyWrapper__1eFb- {
    padding-left: 0;
    padding-top: 0; } }

@media screen and (max-width: 450px) {
  .NewIssue_imageUploadWrapper__D70Ll {
    margin-bottom: 10px; } }

.ChooseCategory_wrapper__3ElVA {
  padding: 1.6rem;
  background-color: white;
  min-height: 100%;
  box-sizing: border-box;
  max-width: 80rem;
  text-align: center; }

.ChooseCategory_top-category-list__2m4KM {
  list-style: none;
  margin: 0;
  padding: 0;
  text-align: center; }
  .ChooseCategory_top-category-list__2m4KM > li {
    margin: 0.8rem 0 0;
    padding: 0;
    display: inline-block; }
    .ChooseCategory_top-category-list__2m4KM > li:not(:last-child) {
      margin-right: 0.8rem; }

.ChooseCategory_sub-category-list__2AGvq {
  list-style: none;
  margin: 0;
  padding: 0;
  text-align: center;
  margin-top: 0.8rem; }
  .ChooseCategory_sub-category-list__2AGvq > li {
    margin: 0.8rem 0 0;
    padding: 0;
    display: inline-block; }
    .ChooseCategory_sub-category-list__2AGvq > li:not(:last-child) {
      margin-right: 0.8rem; }

.ChooseCategory_full-width-toggle-button__3c32V {
  width: 100%; }
  .ChooseCategory_full-width-toggle-button__3c32V > label {
    width: 100%;
    text-align: center; }

.ChooseCategory_heading__3KFRL {
  margin-top: 150px; }

.ChooseCategory_heading__3KFRL, .ChooseCategory_heading2__2KcXE {
  text-align: center;
  font-family: InterstateBoldCompressed;
  font-size: 40px;
  color: #333333; }

.ChooseCategory_heading2__2KcXE {
  font-size: 22px;
  margin-top: 20px; }

.ChooseCategory_show-all-categories-button__3d39- {
  background-color: #1585BB;
  color: white;
  padding: 0.8rem 1.6rem;
  font-size: 1.6rem;
  border: none;
  border-radius: 4px; }

.ChooseCategory_featured-section__2794F {
  margin-top: 3.2rem;
  padding-top: 3.2rem;
  border-top: 1px solid #C4CED4; }

.ChooseCategory_featured-list__gU3eN {
  list-style: none;
  margin: 0 auto;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 80%; }
  .ChooseCategory_featured-list__gU3eN > li:not(:first-child) {
    margin-top: 1.6rem; }

.ChooseCategory_infoMessage__2MwXX {
  line-height: 1.6em;
  padding: 20px;
  border-radius: 5px;
  background-color: #d2e36f;
  margin-top: 20px; }

@media screen and (max-width: 450px) {
  .ChooseCategory_heading__3KFRL {
    margin-top: 15px; } }

.Brannbok_brannbokNoteWrapper__3Eu-O {
  margin-top: 30px;
  display: block; }
  .Brannbok_brannbokNoteWrapper__3Eu-O .Brannbok_brannbokHeaderBtn__3JewC {
    display: block;
    padding: 20px 30px;
    margin-bottom: 30px;
    width: 100%;
    background-color: #5c7fff;
    color: white;
    text-align: center;
    font-family: "InterstateRegular";
    font-size: 20px;
    border-radius: 4px;
    cursor: pointer; }
  .Brannbok_brannbokNoteWrapper__3Eu-O .Brannbok_expandable__2i6aU {
    -webkit-animation: Brannbok_fadeIn__APttM 0.5s;
            animation: Brannbok_fadeIn__APttM 0.5s;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards; }
  .Brannbok_brannbokNoteWrapper__3Eu-O .Brannbok_hiddenNote__3NZMY {
    display: none; }
  .Brannbok_brannbokNoteWrapper__3Eu-O .Brannbok_sectionHeader__2d5jF {
    font-size: 16px;
    margin-top: 20px;
    display: block;
    margin-bottom: 15px;
    color: #2d3e80; }

@-webkit-keyframes Brannbok_fadeIn__APttM {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes Brannbok_fadeIn__APttM {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.ImageViewer_background__2UA3X {
  background-color: #33434B;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0; }

.ImageViewer_image-viewer__1foA1 {
  display: grid;
  grid-template-rows: 1fr auto;
  grid-template-columns: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  padding: 6.4rem 3.2rem 3.2rem;
  max-height: 110rem;
  max-width: 150rem;
  height: 100%;
  width: 100%;
  margin: 0 auto; }

.ImageViewer_images-list__3ffws {
  min-width: 0;
  min-height: 0;
  list-style: none;
  padding: 0;
  flex-shrink: 0;
  display: inline-flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin: 0 auto; }
  .ImageViewer_images-list__3ffws > li {
    padding: 0;
    margin: 0; }
    .ImageViewer_images-list__3ffws > li:not(:last-child) {
      margin-right: 1.6rem; }

.ImageViewer_viewed-image-wrapper__1ztkc {
  display: flex;
  flex-direction: column;
  align-items: center; }

.ImageViewer_viewed-image__3IJvb {
  min-width: 0;
  min-height: 0;
  max-height: 100%;
  max-width: 100%;
  width: auto;
  object-fit: contain;
  display: block; }

.ImageViewer_image-text__132hG {
  color: white;
  margin: 1.6rem 0 3.2rem; }

.ImageViewer_image-link__3OqOc {
  display: block;
  height: 100%;
  width: 100%;
  border-radius: 4px;
  border: 2px solid transparent; }
  .ImageViewer_image-link__3OqOc.ImageViewer_active__kv4Xi, .ImageViewer_image-link__3OqOc:hover, .ImageViewer_image-link__3OqOc:focus {
    border: 2px solid white; }
  .ImageViewer_image-link__3OqOc:focus {
    outline: none; }
  .ImageViewer_image-link__3OqOc .ImageViewer_thumbnail__3zYrN {
    object-fit: cover;
    width: 16rem;
    height: 9rem;
    box-sizing: content-box;
    display: block; }

.ImageViewer_close-link__1vc8G {
  color: white;
  font-size: 1.6rem;
  font-weight: bold;
  position: absolute;
  top: 0;
  right: 3.2rem; }
  .ImageViewer_close-link__1vc8G:before {
    content: "x";
    position: absolute;
    left: -2.0rem;
    font-size: 3rem;
    line-height: 1;
    top: -9px;
    font-weight: lighter; }
  .ImageViewer_close-link__1vc8G:hover {
    color: white;
    text-decoration: underline; }

@font-face {
  font-family: "InterstateRegular";
  src: url(/static/media/InterstateRegular.3e611597.woff) format("woff"); }

@font-face {
  font-family: "InterstateRegularCompressed";
  src: url(/static/media/InterstateRegularCompress.e217c1db.woff) format("woff"); }

@font-face {
  font-family: "InterstateBold";
  src: url(/static/media/InterstateBold.b8f3dd5f.woff) format("woff"); }

@font-face {
  font-family: "InterstateBoldCompressed";
  src: url(/static/media/InterstateBoldCompressed.4bfb1d4c.woff) format("woff"); }

@font-face {
  font-family: "InterstateLight";
  src: url(/static/media/InterstateLight.64bd8d71.woff) format("woff"); }

@font-face {
  font-family: "ssprobold";
  src: url(/static/media/SourceSerifPro-Bold.fa16624e.ttf) format("woff"); }

.headlineS {
  color: #333333;
  text-transform: uppercase;
  text-align: center;
  font: normal normal bold 40px/48px InterstateBoldCompressed;
  letter-spacing: 0px;
  height: 48px;
  margin-bottom: 32px;
  margin-top: 37px;
  display: inline-block; }

.bigCard {
  text-align: center;
  width: 200px;
  float: left;
  margin-right: 24px; }
  .bigCard .imageWrapper {
    width: 200px;
    height: 224px;
    position: relative; }
  .bigCard img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 6px; }
  .bigCard .cardTitle {
    font: bold 24px InterstateBold;
    margin-top: 16px;
    display: inline-block; }
  .bigCard .shortDescription {
    font: 14px InterstateRegular;
    line-height: 16px;
    padding: 24px 24px 32px 25px;
    margin: 0; }

.cardBack {
  font-size: 14px;
  font-family: InterstateRegular;
  text-align: center;
  position: absolute;
  left: 0;
  top: 0;
  background: #cce0ff;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  visibility: hidden;
  opacity: 0;
  transition: opacity .2s, visibility .2s;
  cursor: pointer; }
  .cardBack button {
    padding: 10px 20px;
    font-size: 11px;
    font-family: 'InterstateRegular';
    text-transform: uppercase;
    background: #214C6F;
    color: white;
    border-radius: 5px;
    border: 1px solid transparent;
    cursor: pointer; }

.imageWrapper:hover .cardBack {
  visibility: visible;
  opacity: 1; }

@media screen and (max-width: 768px) {
  .bigCard .imageWrapper {
    width: 100%;
    margin: 0;
    height: 416px;
    position: relative; }
  .bigCard {
    width: 100%;
    margin-bottom: 50px; } }

.headerBold40 {
  font-size: 40px;
  font-family: InterstateBoldCompressed;
  color: #33434B;
  line-height: 48px; }

.dataRow {
  background-color: #F6F5ED;
  min-height: 40px;
  font-family: InterstateLight;
  font-size: 11px;
  color: #33434B;
  margin-bottom: 4px; }
  .dataRow * {
    font-family: InterstateLight;
    font-size: 13px; }
  .dataRow a {
    width: 100%;
    display: inline-block;
    padding: 1.6rem; }

.dataRow:hover {
  background-color: #cce0ff; }

.blackActionBtn {
  background-color: #151d3b;
  font-size: 13px;
  font-family: InterstateRegular;
  border-radius: 4px;
  padding: 10px 20px;
  color: white;
  cursor: pointer; }

.sortButtonsLabel {
  color: #33434B;
  font-family: InterstateLight;
  font-size: 14px;
  margin-right: 20px; }

.filtersHeader {
  color: #333333;
  font-size: 22px;
  font-family: InterstateBold;
  display: block;
  margin-bottom: 10px; }

.filterCatLabel {
  font-size: 14px;
  font-family: InterstateRegular;
  color: #333333;
  min-width: 65px;
  margin-right: 10px;
  display: inline-block; }

.filterBtn {
  box-sizing: border-box;
  transition: all .2s ease-in-out;
  white-space: nowrap;
  border: 1px dotted #2d3e80;
  background-color: white;
  color: #2d3e80;
  padding: 8px 16px;
  font-size: 13px;
  font-family: 'InterstateRegular';
  border-radius: 3px;
  display: inline-block;
  cursor: pointer;
  margin-right: 4px;
  margin-bottom: 8px; }
  .filterBtn svg {
    height: 11px;
    margin-left: 8px; }

.filterBtn:hover {
  background-color: #F4F4F4; }

.filterBtnActive,
.filterBtnActive:hover {
  border: 1px solid #2d3e80;
  background-color: #2d3e80;
  color: #fff; }
  .filterBtnActive path,
  .filterBtnActive:hover path {
    fill: white; }

.filterCatWrapper {
  margin-bottom: 8px; }

.searchBoxInput {
  border: 1px dotted grey;
  height: 33px;
  width: 200px;
  font-family: 'InterstateRegular';
  font-size: 14px;
  padding: 0 10px; }

.sortBtnsWrapper {
  margin-bottom: 15px;
  display: block;
  overflow: auto;
  max-width: 800px; }

.brightBtn {
  padding: 20px 40px;
  border: 1px solid transparent;
  background-color: #2d3e80;
  color: white;
  border-radius: 5px;
  font-family: 'InterstateRegular';
  font-size: 15px;
  line-height: 15px;
  display: block;
  cursor: pointer;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  margin: 0 auto;
  text-transform: uppercase; }

.disabledBrightBtn {
  padding: 20px 40px;
  border: 1px solid transparent;
  color: white;
  border-radius: 5px;
  font-family: 'InterstateRegular';
  font-size: 15px;
  line-height: 15px;
  display: block;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  margin: 0 auto;
  text-transform: uppercase;
  cursor: default;
  background-color: #e0e0e0; }

.brightBtn:hover {
  background-color: white;
  color: #333334;
  border: 1px dotted #333334; }

.fadedBtn {
  font-family: 'InterstateRegular';
  font-size: 13px;
  margin-top: 24px;
  background-color: #a1c5ff;
  color: white;
  display: inline-block;
  border: 1px solid transparent;
  border-radius: 5px;
  padding: 15px 30px;
  cursor: pointer; }

.fadedBtn:hover {
  color: #90999E;
  border: 1px dotted #90999E;
  background-color: white; }

.validationError {
  color: #C60018;
  font-family: 'InterstateLight';
  font-size: 13px; }

@media screen and (max-width: 768px) {
  .brightBtn {
    width: 100%; } }

