.issue-dashboard {
  min-height: 100%;
  padding-top: 1.6rem;
  max-width: 800px;
  min-width: 600px;
  padding-left: 64px;
  padding-right: 40px;
}

@media screen and (max-width: 768px) {
  .issue-dashboard {
    width: 100%;
    padding-right: 20px;
    padding-left: 20px;
  }
}