@import '../../../colors';

.header-block {
  padding: 3.2rem;
  margin-left: -0.8rem;
  margin-right: -0.8rem;
  background-color: white;

  .buildings-list {
    list-style: none;
    text-align: center;
    margin: 0;
    padding: 0;

    height: 0;
    overflow: hidden;

    &--open {
      height: auto;
    }

    > li {
      padding: 0;
      margin: 0;
    }
  }

  .building-link-item {
    font-size: 1.6rem;
    text-transform: capitalize;

    &:hover {
      opacity: .5;
    }
  }

  .building-list-select {
    text-align: center;
    margin-bottom: 0.8rem;
  }

  .building-list-expand-button {
    background-color: transparent;
    border: none;
    margin: 0;
    padding: 0;

    display: inline-flex;
    align-items: center;

    .building-list-icon {
      width: 4rem;
      margin-right: 1.6rem;
    }

    .building-city, .building-street {
      font-size: 1.6rem;
      color: $waves-80;
    }

    .building-street {
      text-align: center;
      font-weight: bold;
    }

    .building-city {
      text-transform: capitalize;
    }

    .building-list-button-icon {
      width: 2.8rem;
      height: auto;
      fill: $waves-80;
    }
  }
}

@media screen and (min-width: 769px) {
  .header-block {
    margin-left: -1.6rem;
    margin-right: -1.6rem;

    .building-link-item {
      font-size: 2.4rem;
    }
  }

  .building-city, .building-street {
    font-size: 2.4rem;
  }
}