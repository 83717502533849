@import '../../colors';

.message {
  font-size: 1.2rem;
  color: $rose-60;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  > span {
    padding-left: 0.8rem;
  }

  &--hidden {
    visibility: hidden;
  }

}