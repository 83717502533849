/* autoprefixer grid: autoplace */
.issue-list-section:not(:last-child),
.empty-list:not(:last-child) {
  margin-bottom: 3.2rem; }

.issue-list-section {
  max-width: 80rem; }

.empty-list {
  text-align: center; }
  .empty-list header {
    margin-bottom: 1.6rem; }
  .empty-list header > p {
    font-weight: bold; }
  .empty-list .empty-list-hint {
    color: #1585BB;
    font-weight: bold; }

.viewIcon1,
.viewIcon2 {
  width: 28px;
  margin-right: 12px;
  cursor: pointer;
  position: absolute;
  transition: transform .2s; }

.viewIcon2 {
  margin-left: 44px; }

.viewIcon1:hover,
.viewIcon2:hover {
  transform: scale(1.2); }

.activeViewIcon {
  width: 32px; }

.contentHeader {
  font-family: InterstateBoldCompressed;
  font-size: 40px;
  display: block;
  margin-top: 43px;
  margin-bottom: 32px; }

.solved-issues-list .solved-issue {
  /*autoprefixer: off*/
  align-items: center; }

.solved-issues-list .solved-issue {
  display: grid !important;
  grid-template-areas: "title    reportedDate" "location finishedDate";
  grid-template-columns: 1fr auto;
  grid-column-gap: 1.6rem;
  grid-template-rows: auto auto; }
  .solved-issues-list .solved-issue .issue-location,
  .solved-issues-list .solved-issue .issue-finishedDate {
    margin: 0; }
  .solved-issues-list .solved-issue .issue-location,
  .solved-issues-list .solved-issue .issue-reportedDate,
  .solved-issues-list .solved-issue .issue-finishedDate {
    font-size: 1.2rem; }
  .solved-issues-list .solved-issue .issue-title,
  .solved-issues-list .solved-issue .issue-location {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }
  .solved-issues-list .solved-issue .issue-title {
    grid-area: title;
    font-size: 1.6rem; }
  .solved-issues-list .solved-issue .issue-location {
    grid-area: location; }
  .solved-issues-list .solved-issue .issue-reportedDate {
    grid-area: reportedDate;
    justify-self: end; }
  .solved-issues-list .solved-issue .issue-finishedDate {
    grid-area: finishedDate;
    justify-self: end;
    color: #30E500; }

.filter-options {
  margin-bottom: 50px; }

.filter-option {
  margin-right: 0.8rem;
  display: inline-block; }

@media screen and (min-width: 769px) {
  /*autoprefixer: off*/
  .solved-issues-list {
    /*autoprefixer: grid*/
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 0.8rem; } }
