@import '../../colors';


.background {
  background-color: $petroleum-100;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.image-viewer {
  display: grid;
  grid-template-rows: 1fr auto;
  grid-template-columns: 100%;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  padding: 6.4rem 3.2rem 3.2rem;
  max-height: 110rem;
  max-width: 150rem;
  height: 100%;
  width: 100%;
  margin: 0 auto;
}

.images-list {
  min-width: 0;
  min-height: 0;

  list-style: none;
  padding: 0;

  flex-shrink: 0;
  display: inline-flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin: 0 auto;

  > li {
    padding: 0;
    margin: 0;

    &:not(:last-child) {
      margin-right: 1.6rem;
    }
  }
}

.viewed-image-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.viewed-image {
  min-width: 0;
  min-height: 0;
  max-height: 100%;
  max-width: 100%;
  width: auto;
  object-fit: contain;
  display: block;
}

.image-text {
  color: white;
  margin: 1.6rem 0 3.2rem;
}

.image-link {
  display: block;
  height: 100%;
  width: 100%;
  border-radius: 4px;
  border: 2px solid transparent;

  &.active, &:hover, &:focus {
    border: 2px solid white;
  }

  &:focus {
    outline: none;
  }

  .thumbnail {
    object-fit: cover;
    width: 16rem;
    height: 9rem;
    box-sizing: content-box;
    display: block;
  }
}

.close-link {
  color: white;
  font-size: 1.6rem;
  font-weight: bold;
  position: absolute;
  top: 0;
  right: 3.2rem;

  &:before {
    content: "x";
    position: absolute;
    left: -2.0rem;
    font-size: 3rem;
    line-height: 1;
    top: -9px;
    font-weight: lighter;
  }

  &:hover {
    color: white;
    text-decoration: underline;
  }
}