@import '../../colors';

.navigation {
  display: flex;
  justify-content: space-around;
  border-bottom: 1px solid $petroleum-40;
  margin-bottom: 3.2rem;
}

.navigation-list {
  list-style: none;
  margin: 0;
  display: inline-flex;
  justify-content: space-around;
  padding: 0.8rem;

  .navigation-list-item {
    padding: 0;
    margin: 0;
    text-align: center;

    > .navigation-link {
      display: inline-block;
      font-size: 1.8rem;
      position: relative;
      min-width: 10rem;
      padding: 0;

      &.disabled {
        color: $petroleum-100;
        opacity: .5;
        cursor: default;
      }

      &--active {
        color: $waves-80;

        &:after {
          content: "";
          height: 1px;
          position: absolute;
          background-color: $waves-80;
          width: 100%;
          bottom: -0.9rem;
          left: 0;
        }
      }
    }

  }

}

@media screen and (min-width: 769px) {
  .navigation-list {
    padding: 1.6rem;

    .navigation-list-item {

      > .navigation-link {
        padding: 0 3.2rem;

        &--active {
          &:after {
            bottom: -1.7rem;
          }
        }
      }

      &:not(:last-child) {
        margin-right: 6.4rem;
      }

    }
  }
}