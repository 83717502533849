@import '../../../variables';
@import '../../../colors';

.submit-button {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;

  background-color: $waves-60;
  border: none;
  font-size: 1.8rem;
  color: white;
  height: 4.8rem;
  display: block;
}

.form {
  padding: 1.6rem;
  background-color: white;
  box-shadow: 0 1px 2px $petroleum-40;
  min-height: 100%;
  box-sizing: border-box;
  max-width: $narrowContentWidth;
  position: relative;

  > fieldset {
    border: none;
    margin: 3.2rem -1.6rem 0 -1.6rem;
    &:first-child {
      margin-top: 0;
    }

    padding-bottom: 3.2rem;
    padding-left: 1.6rem;
    padding-right: 1.6rem;

    &:not(:last-of-type) {
      border-bottom: 1px solid $petroleum-40;
    }
  }
}

.selected-category {
  margin-top: 0.8rem;
  margin-bottom: 0 !important;
  font-size: 1.4rem;
  display: flex;
  align-items: center;

  .category-arrow {
    transform: rotate(-90deg);
    fill: $waves-60;
    margin: 0 0.8rem;
  }

  > .category {
    padding: 0.4rem 1.6rem;
    color: white;
    background-color: $waves-60;
    border-radius: 4px;
    display: inline-block;
  }
}

.upload-input {
  margin-top: 0.8rem;
}

.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;

  svg {
    margin-bottom: 1.6rem;
    fill: white;
  }

  @include transparentColorMixin($petroleum-100, 0.8)
}

@media screen and (min-width: 769px) {
  .submit-button {
    position: relative;
    width: fit-content;
    padding-left: 3.2rem;
    padding-right: 3.2rem;
    margin-left: auto;
    display: flex;
    align-items: center;

    > .loading-icon {
      width: 20px;
      height: 20px;
      margin-left: 0.8rem;
      fill: white;
    }
  }

  .form {
    padding: 1.6rem 3.2rem;
  }
}


.flex-left-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.select-dropdown {
  margin: 0.8rem 0 0.8rem 0;
  width: 100%;
  > * {
    width: 100%;
  }
  > label{
    display: inline-block;
    margin-bottom: 0.6rem;
  }
}
