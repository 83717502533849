@media screen and (max-width: 769px) {
  .closeBox {
    visibility: visible;
    height: 10%; }
  .sidebar.minemize {
    height: 5vh;
    position: absolute;
    bottom: 0; }
  .sidebar {
    background: #F6F5ED 0% 0% no-repeat padding-box;
    opacity: 1;
    position: relative;
    height: 100vh;
    width: 100vw;
    transition: height 0.5s;
    border-radius: 4px;
    box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.1); }
    .sidebar .button {
      background-color: #F6F5ED;
      width: 100%;
      word-wrap: break-word;
      padding: 0;
      border: none;
      background: none;
      font: normal normal normal 1.5rem/2rem InterstateRegular;
      text-transform: uppercase;
      letter-spacing: 0.03px;
      color: #333333;
      opacity: 1; }
    .sidebar .buttonShow {
      background-color: #F6F5ED;
      width: 100%;
      height: 100%;
      white-space: nowrap;
      border: none;
      background: none;
      font: normal normal normal 1.5rem/1.5rem InterstateRegular;
      text-transform: uppercase; }
    .sidebar .ShowBttnBox {
      width: 5%;
      height: 100%;
      background-color: #F6F5ED;
      visibility: hidden;
      border: none;
      background: none;
      bottom: 0; }
    .sidebar .ShowBttnBox:hover {
      background-color: #377EB8; }
    .sidebar .ShowBttnBox:hover .buttonShow {
      color: white; }
    .sidebar .ShowBttnBox:hover:after {
      position: relative;
      top: 50%;
      left: -5px;
      width: 0;
      height: 0;
      border-right: solid 5px red;
      border-bottom: solid 5px transparent;
      border-top: solid 5px transparent; }
    .sidebar .ShowBttnBox.visible {
      transition: visibility 0s linear 300ms, opacity 300ms;
      width: 100%;
      visibility: visible; }
    .sidebar .HideBttnBox {
      position: relative;
      top: 50%;
      width: 100%;
      white-space: nowrap;
      background-color: #F6F5ED;
      padding: 0;
      border: none;
      background: none; }
    .sidebar .button:hover {
      color: #377EB8;
      text-decoration: underline; }
    .sidebar .HideBttnBox.hide {
      visibility: hidden;
      width: 0px;
      height: 0px; }
    .sidebar .boxes {
      width: 100%;
      background-color: #F6F5ED;
      height: 10%; }
    .sidebar .boxes.hide {
      visibility: hidden;
      width: 0%;
      height: 0%; }
    .sidebar .boxes2 {
      width: 100%;
      height: 80%;
      background-color: #F6F5ED; }
    .sidebar .boxes2.hide2 {
      height: 100%; }
    .sidebar .boxes3 {
      width: 100%;
      height: 10%;
      background-color: #F6F5ED; }
    .sidebar .boxes3.hide {
      visibility: hidden;
      width: 0%;
      height: 0%; }
    .sidebar .lukkBox {
      position: relative;
      top: 10%;
      left: 10%;
      width: 80%;
      height: auto;
      background-color: white;
      text-align: center;
      border-radius: 4px;
      box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.1);
      font: normal normal normal 1.5rem/2rem InterstateRegular;
      visibility: hidden; }
    .sidebar .lukkBox.hide {
      visibility: visible; }
    .sidebar .NotBox {
      border: 1px;
      width: 90%;
      background-color: #F6F5ED;
      height: 100%;
      overflow: scroll; }
    .sidebar .NotBox::-webkit-scrollbar {
      display: none; }
    .sidebar .NotBox.hide {
      height: 0%;
      visibility: hidden; }
    .sidebar .NotBox > * {
      margin-bottom: 10px; }
  .show {
    visibility: visible;
    height: 400px; }
  div .focus {
    height: -webkit-min-content;
    height: -moz-min-content;
    height: min-content;
    max-height: 5000px;
    width: 100%;
    cursor: default; }
  div .hide {
    height: 0%;
    visibility: hidden; }
  div .hidePanel {
    max-height: 0px;
    visibility: hidden;
    margin-bottom: 0px;
    transition: visibility 0s linear 0.5s, max-height 0.5s, margin-bottom 0.5s 0s; } }

@media screen and (min-width: 769px) {
  .sidebar.minemize {
    width: 50px; }
  .sidebar.minemize:hover:after {
    position: absolute;
    top: 50%;
    left: -10px;
    content: '';
    width: 0;
    height: 0;
    border-right: solid 10px #377EB8;
    border-bottom: solid 10px transparent;
    border-top: solid 10px transparent; }
  .sidebar {
    background: #F6F5ED 0% 0% no-repeat padding-box;
    opacity: 1;
    height: 95vh;
    width: 500px;
    right: 0;
    transition: width 0.5s;
    overflow: hidden;
    border-radius: 4px;
    box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.1); }
    .sidebar .button {
      background-color: #F6F5ED;
      width: 100%;
      word-wrap: break-word;
      padding: 0;
      border: none;
      background: none;
      font: normal normal normal 14px/18px InterstateRegular;
      text-transform: uppercase;
      letter-spacing: 0.03px;
      color: #333333;
      opacity: 1; }
    .sidebar .buttonShow {
      background-color: #F6F5ED;
      width: 100%;
      height: 100%;
      white-space: nowrap;
      border: none;
      background: none;
      -webkit-transform: rotate(-90deg);
              transform: rotate(-90deg);
      font: normal normal normal 18px/18px InterstateRegular;
      text-transform: uppercase; }
    .sidebar .ShowBttnBox {
      width: 5%;
      height: 100%;
      background-color: #F6F5ED;
      visibility: hidden;
      border: none;
      background: none;
      bottom: 0;
      cursor: pointer; }
    .sidebar .ShowBttnBox:hover {
      background-color: #377EB8; }
    .sidebar .ShowBttnBox:hover .buttonShow {
      color: white;
      cursor: pointer; }
    .sidebar .ShowBttnBox:hover:after {
      position: relative;
      top: 50%;
      left: -5px;
      width: 0;
      height: 0;
      border-right: solid 5px #377EB8;
      border-bottom: solid 5px transparent;
      border-top: solid 5px transparent; }
    .sidebar .ShowBttnBox.visible {
      transition: visibility 0s linear 300ms, opacity 300ms;
      width: 100%;
      visibility: visible; }
    .sidebar .HideBttnBox {
      position: relative;
      top: 50%;
      width: 100%;
      white-space: nowrap;
      background-color: #F6F5ED;
      padding: 0;
      border: none;
      background: none; }
    .sidebar .button:hover {
      color: #377EB8;
      text-decoration: underline;
      cursor: pointer; }
    .sidebar .HideBttnBox.hide {
      visibility: hidden;
      width: 0px;
      height: 0px; }
    .sidebar .boxes {
      width: 100%;
      background-color: #F6F5ED;
      height: 10%; }
    .sidebar .boxes.hide {
      visibility: hidden;
      width: 0%;
      height: 0%; }
    .sidebar .boxes2 {
      width: 100%;
      height: 80%;
      background-color: #F6F5ED; }
    .sidebar .boxes2.hide2 {
      height: 100%; }
    .sidebar .boxes3 {
      width: 100%;
      height: 10%;
      background-color: #F6F5ED; }
    .sidebar .boxes3.hide {
      visibility: hidden;
      width: 0%;
      height: 0%; }
    .sidebar .lukkBox {
      position: relative;
      top: 10%;
      left: 6%;
      width: 88%;
      height: auto;
      background-color: white;
      text-align: center;
      border-radius: 4px;
      box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.1);
      padding-top: 10px;
      padding-bottom: 10px;
      visibility: hidden;
      cursor: pointer; }
    .sidebar .lukkBox > * {
      font: normal normal 300 12px/15px InterstateRegular; }
    .sidebar .lukkBox.visible {
      visibility: visible; }
    .sidebar .NotBox {
      border: 1px;
      width: 90%;
      background-color: #F6F5ED;
      height: 100%;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      grid-column-gap: 10px;
      -webkit-column-gap: 10px;
              column-gap: 10px; }
  div .focus {
    height: -webkit-min-content;
    height: -moz-min-content;
    height: min-content;
    max-height: 5000px;
    width: 100%;
    cursor: default; }
  div .hide {
    max-height: 0px;
    visibility: hidden;
    margin-bottom: 0px; }
  div .hidePanel {
    max-height: 0px;
    visibility: hidden;
    margin-bottom: 0px;
    transition: visibility 0s linear 0.5s, max-height 0.5s, margin-bottom 0.5s 0s; } }

.panel {
  transition: visibility 0s linear 0s, max-height 0.5s, margin-bottom 0.5s 0s;
  border-radius: 4px;
  box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.1);
  background-color: white;
  color: #33434B;
  max-height: 100px;
  width: 100%;
  margin-bottom: 10px;
  overflow: hidden;
  cursor: pointer;
  position: relative; }
  .panel .title {
    font: normal normal bold 20px/24px InterstateRegularCompressed;
    letter-spacing: 0px;
    color: #333333;
    text-transform: uppercase;
    opacity: 1;
    margin-left: 10px;
    margin-top: 5px;
    justify-self: start;
    position: relative; }
  .panel .description {
    max-height: 0px;
    margin-left: 10px;
    word-wrap: break-word;
    visibility: hidden;
    transition: visibility 0.1s ease 0s, max-height 0.4s; }
  .panel .descriptionShow {
    height: auto;
    max-height: 5000px;
    visibility: visible;
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 20px;
    transition: visibility 0.1s ease 0s, max-height 0.4s; }
  .panel .description > * {
    font: normal normal normal 13px/15px InterstateRegular !important; }
  .panel .teaser {
    justify-self: start;
    margin-left: 10px;
    font: normal normal normal 13px/15px InterstateRegular; }
  .panel .link {
    margin-left: 10px;
    padding-bottom: 10px;
    float: left;
    position: relative;
    font: normal normal 300 12px/15px InterstateRegular; }
  .panel .timestamp {
    font: normal normal normal 0.8125rem/0.9375rem InterstateRegular;
    letter-spacing: 0px;
    align-self: end;
    justify-self: end;
    color: #839097;
    margin-right: 5px;
    margin-bottom: 5px; }
  .panel .pubdate {
    text-align: left;
    font: normal normal 300 11px/14px InterstateRegular;
    letter-spacing: 0px;
    color: #333333;
    opacity: 1;
    position: absolute;
    bottom: 0;
    left: 0;
    margin-left: 10px;
    margin-bottom: 10px; }
  .panel .close {
    position: absolute;
    bottom: 0;
    right: 0;
    margin-right: 10px;
    margin-bottom: 10px;
    font: normal normal 300 12px/15px InterstateRegular;
    cursor: pointer; }

.list .caption {
  font-size: 40px;
  font-family: InterstateBoldCompressed;
  color: #33434B;
  line-height: 48px;
  margin-bottom: 40px; }

.list .filterTitle {
  float: left;
  font: normal normal 300 14px/16px InterstateRegular;
  color: #33434B;
  margin-left: 10px;
  margin-bottom: 25px;
  margin-top: 19px; }

.list .filterBttn {
  float: left;
  margin-left: 10px;
  margin-bottom: 25px;
  margin-top: 10px;
  cursor: pointer; }

.list .filterBttn:active {
  background-color: #214C6F; }

.list .filterBttnSvg {
  width: 10px;
  height: auto;
  float: right;
  margin: 1px; }

.list .filterBttnTxt {
  float: left; }

.list .active {
  background-color: #cce0ff; }

.list .item.expand {
  max-height: 5000px;
  transition: max-height 1s; }

.list .item {
  background-color: #F6F5ED;
  margin-bottom: 15px;
  box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.1);
  display: grid;
  grid-template-columns: 250px 250px 0px;
  grid-template-rows: auto;
  grid-template-areas: "title publisert tab" "teaser teaser tab" "innhold innhold tab";
  width: 500px;
  max-height: 100px;
  transition: max-height 1s;
  overflow: hidden;
  cursor: pointer; }
  .list .item .sSvg {
    width: 20px;
    height: auto;
    margin: auto; }
  .list .item .hover {
    margin-top: 20px;
    display: block;
    grid-area: tab;
    visibility: hidden;
    justify-self: center;
    align-self: start; }
  .list .item .hover * {
    display: block;
    justify-self: center;
    font: normal normal bold 12px/11px InterstateRegular; }
  .list .item .sTitle {
    grid-area: title;
    margin-top: 15px;
    margin-left: 15px;
    font: normal normal bold 20px/24px InterstateRegularCompressed;
    letter-spacing: 0px;
    color: #333333;
    text-transform: uppercase;
    overflow: hidden; }
  .list .item .sPublisert {
    grid-area: publisert;
    margin-top: 15px;
    margin-right: 15px;
    text-align: right;
    font: normal normal 300 11px/14px InterstateRegular;
    letter-spacing: 0px;
    color: #333333; }
  .list .item .sTeaser {
    grid-area: teaser;
    margin-left: 15px;
    font: normal normal normal 13px/15px InterstateRegular; }
  .list .item .sInnhold {
    grid-area: innhold;
    width: 470px;
    margin-left: 15px;
    margin-right: 15px;
    margin-bottom: 20px;
    word-wrap: break-word; }
  .list .item .sInnhold > * {
    font: normal normal normal 13px/15px InterstateRegular !important; }

.list .item:hover > .hover {
  transition: visibility 0s linear 0.3s;
  visibility: visible; }

.list .item:hover {
  transition: width ease 0.3s, max-height ease 0.5s;
  width: 600px;
  grid-template-columns: 250px 250px 100px;
  background-color: #cce0ff; }
