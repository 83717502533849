.button {
  border: none;
  background-color: transparent;
  padding: 1.6rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  svg{
    height: 35px;
    path{
      fill: white;
    }
  }
}

.bar {
  display: block;
  height: 4px;
  width: 2.5rem;
  background-color: white;
  border-radius: 4px;
  transform-origin: 4px 0;
  transition: all .2s ease-in-out;

  &:not(:last-child) {
    margin-bottom: 5px;
  }
}

.button.button--open {

  .bar:nth-child(1) {
    transform: rotate(45deg);
  }

  .bar:nth-child(2) {
    display: none;
  }

  .bar:nth-child(3) {
    transform: rotate(-45deg) translate(-4px);
  }
}

.button-text {
  color: white;
  font-size: 1.6rem;
  font-weight: bold;
  padding-left: 0.8rem;
}