@import "../../../colors";

.wrapper {
  display: inline-block;
  fill: $waves-80;
  width: 2.1rem;
  height: auto;

  svg {
    width: 100%;
    height: auto
  }

  &.round {
    height: 2.1rem;
    background-color: $waves-80;
    fill: white;
    border-radius: 1000px;
    padding: 0.8rem;
    box-sizing: content-box;
  }
}