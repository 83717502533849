@import '../../../colors';

.button {
  border: none;
  padding: 0;
  font-size: 1.4rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: white;
  background-color: transparent;


  &[aria-disabled="true"] {
    opacity: .5;
    cursor: default;

    &:hover, &:focus {
      color: $waves-60;

      .icon {
        fill: $waves-60;;
      }
    }
  }
}

.icon {
  fill: $waves-60;
  margin-right: 0.8rem;
}

.button:hover, .button:focus {
  color: $waves-80;

  .icon {
    fill: $waves-80
  }
}
