@import '../../colors';

.input {
  width: 1px;
  height: 1px;
  border: none;
  position: absolute;
  left: -5000px;
}

.label {
  box-sizing: border-box;
  transition: all .2s ease-in-out;
  white-space: nowrap;
  border: 1px dotted #214C6F;
  background-color: white;
  color: #214C6F;
  padding: 8px 16px;
  font-size: 13px;
  font-family: 'InterstateRegular';
  border-radius: 3px;
  display: inline-block;
  cursor: pointer;
}

.label-wrapper {
}

.input:focus + .label, .label:hover {
  background-color: $petroleum-10;
}

.input:checked + .label {
  border: 1px solid #214C6F;
  background-color: #214C6F;
  color: #fff;
}


