$text-color: #2C393F;
$main-background-color: white;

$petroleum-80-shadow: #27343A;
$petroleum-100: #33434B;
$petroleum-80: #5D6B71;
$petroleum-60: #839097;
$petroleum-40: #C4CED4;
$petroleum-20: #DFE2E2;
$petroleum-10: #F8F8F8;

$waves-100: #214C6F;
$waves-80: #15587B;
$waves-60: #1585BB;
$waves-40: #B3CEE3;
$waves-20: #D2E1EC;

$sun-100: #EB8B2D;
$sun-80: #F3B229;
$sun-60: #FFDA1A;
$sun-40: #FFEB95;
$sun-20: #FFF5BC;
$sun-10: #F9F8F3;

$rose-100: #651215;
$rose-80: #9B1D23;
$rose-60: #C4232A;
$rose-40: #F2BBBD;
$rose-20: #F8D4CC;

@mixin transparentColorMixin($color, $alpha) {
  background-color: rgba(red($color), green($color), blue($color), $alpha);
}

$grey-background: #5D6A70;
$light-blue: #0077AA;
$lighter-blue: #377EB8;
$light-grey:#90999E;
$light-cream: #F6F5ED;
$ns-dark: #333333;


$ns-blue01: #5c7fff;
$ns-blue02: #a1c5ff;
$ns-blue03: #cce0ff;
$ns-blue04: #e3eeff;
$ns-blue05: #2d3e80;
$ns-blue06: #151d3b;

$ns-orage01: #ff5c00;
$ns-orage02: #ff8c45;
$ns-orage03: #b34400;

$ns-sand01: #e0d1b8;
$ns-sand02: #e8decf;
$ns-sand03: #d1bfa1;