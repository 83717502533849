
@import '../../colors.scss';

.description{
    font-family: InterstateBold;
    font-size: 22px;
}

.location{
    font-family: InterstateRegular;
    font-size: 17px;
    color: $ns-dark;
}

.status{
    font-family: InterstateLight;
    font-size: 15px;
    color:$petroleum-100;
    text-align: right;
}

.id{
    font-family: InterstateBoldCompressed;
    font-size: 30px;
    color: $grey-background;
    text-align: right;
    margin-bottom: 8px !important;
}

.addedDate{
    text-align: left;
    font-family: InterstateLight;
    font-size: 14px;
    color:#33434B;
}

.waitingCase{
    border-right: 20px solid #F4B1BB !important;
}

.openCase{
    border-right: 20px solid#FFE77F !important;
}

.finishedCase{
    border-right: 20px solid #B3DC10 !important;
}