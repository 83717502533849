@import '../../colors.scss';

.brannbokNoteWrapper {
    margin-top: 30px;
    display: block;

    .brannbokHeaderBtn {
        display: block;
        padding: 20px 30px;
        margin-bottom: 30px;
        width: 100%;
        background-color: $ns-blue01;
        color: white;
        text-align: center;
        font-family: "InterstateRegular";
        font-size: 20px;
        border-radius: 4px;
        cursor: pointer;
    }


    .expandable {

        animation: fadeIn 0.5s;
        animation-fill-mode: forwards;
    }

    .hiddenNote {
        display: none;
    }

    .sectionHeader {
        font-size: 16px;
        margin-top: 20px;
        display: block;
        margin-bottom: 15px;
        color: $ns-blue05;
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}