@import "../../colors";

.list {
  list-style: none;
  padding: 0;

  .list-element {
    margin: 0 0 0.8rem;
    padding: 0.8rem;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid $waves-60;
    color: $waves-60;
    border-radius: 4px;
    font-size: 1.2rem;
    font-weight: bold;
    max-width: 100%;

    > a {
      color: $waves-60;
      white-space: nowrap;
      overflow: hidden;
      max-width: 100%;
      text-overflow: ellipsis;
    }

    > button {
      margin-left: 0.8rem;
      padding: 0;
      border-radius: 50%;
      border: none;
      display: inline-block;
      width: 2rem;
      height: 2rem;
      background-color: $waves-60;
      color: white;
      position: relative;

      &:hover {
        background-color: $waves-80;
      }

      &:after {
        content: "x";
        position: absolute;
        font-size: 1.6rem;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
      }
    }
  }

  &--inverted {
    .list-element {
      color: white;
      background-color: $waves-60;

      > a {
        color: white;
      }
    }
  }
}

@media screen and (min-width: 769px) {
  .list {
    .list-element {
      &:not(:last-child) {
        margin-right: 0.8rem;
      }
    }
  }
}