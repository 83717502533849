@import '../../colors';

.main-contact-info {
  text-align: center;
  border: 1px solid $petroleum-40;
  width: 100%;
  padding: 0.8rem;
  border-radius: 100px; // Something big just to make it completely round
  margin-bottom: 1.6rem;

  >a {
    font-size: 1.6rem;
    font-weight: bold;
    display: block;

    &:not(:last-child) {
      padding-bottom: 0.8rem;
    }
  }

  >h2 {
    text-transform: uppercase;
    padding-bottom: 0.8rem;
    margin: 0;
    font-size: 1.4rem;
  }
}

.no-wrap {
  white-space: nowrap;
}

.kundesenter-info {
  background-color: rgba(196, 206, 212, 0.5) !important;

  svg {
    bottom: 0
  }
}

.sortableTableHeader {
  cursor: pointer;
}

.building-contacts-table,
.contract-contacts-table {
  margin: 0.8rem 0 1.6rem 0;
  width: 100%;
}

.queueTable {
  width: 100%;
}

@media screen and (min-width: 769px) {
  .main-contact-info {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 1.6rem;

    >a,
    >h2 {
      padding-bottom: 0 !important;
    }
  }
}


.formBtnWrapper {
  text-align: center;

  .contactFormBtn {
    background-color: #333334;
    font-size: 13px;
    font-family: InterstateRegular;
    border-radius: 4px;
    padding: 10px 20px;
    color: white;
    margin-top: 32px;
    display: inline-block;
    cursor: pointer;
  }

  .contactFormCancel {
    font-size: 11px;
    font-family: InterstateRegular;
    background-color: #869197;
    border-radius: 4px;
    padding: 10px 20px;
    color: white;
    margin-top: 15px;
    display: inline-block;
    cursor: pointer;
    margin-right: 10px;
  }
}

.addContactWrapper {
  text-align: left;
  margin-right: 18px;
  margin-bottom: 100px;
  margin-top: 15px;
}

.buttonTd {
  width: 100px
}

.formInput {
  width: 365px;
  height: 24px;
  margin-right: 20px;
  border: 1px #333333 solid;
  display: block;
  font-family: InterstateLight;
}

.contactNewForm {
  padding: 144px 52px 56px 52px;

  .contactFormHeader {
    color: #333333;
    font-family: InterstateBold;
    font-size: 22px;
    margin-bottom: 15px;
    display: inline-block;
  }

  label {
    font-size: 11px;
    font-family: InterstateLight;
    color: #333333;
    margin-bottom: 4px;
    display: inline-block;
  }

  .formRow {
    margin-bottom: 15px;
  }
}

.disabledBtn {
  background-color: #cacaca;
  cursor: text;
}

.toBeDeleted {
  text-decoration-line: line-through;
}

.warningMessage {
  font-size: 14px;
  font-weight: 400;
  background: #FFE77F;
  min-height: 32px;
  width: 100%;
  display: flex;
  padding: 12px;
  margin-top: 22px;
  color: #C60018;
  border-radius: 4px;
  border: solid 1px #FFE77F;
  font-family: InterstateRegular;

  span {
    font-family: InterstateRegular;
    padding-left: 10px;
  }
}

.warningMessageForm {
  font-size: 11px;
  background: #FFE77F;
  width: 365px;
  padding: 6px;
  margin-top: 10px;
  color: #C60018;
  border-radius: 4px;
  border: solid 1px #FFE77F;
  font-family: InterstateRegular;
}

.rolesWarningMessage {
  font-size: 16px;
  font-weight: 400;
  background: rgb(255, 244, 206);
  min-height: 32px;
  width: 100%;
  display: flex;
  padding: 12px;
  margin-top: 22px;
  color: #ff5555;
}

.contactRowActionBtnTD {
  visibility: hidden;
}

.tableRowWrapper td {
  // background-color: #F6F5ED;
  padding: 1.6rem;
}

.tableRowWrapper:hover {
  background-color: #cce0ff;

  .contactRowActionBtnTD {
    visibility: visible;

    span {
      // font-size: 12px;
      font-family: InterstateBold;
      cursor: pointer;
    }
  }
}

.roleName {
  position: relative;
  display: inline-block;
  text-transform: capitalize;
}

.roleName>.roleNameTooltip {
  visibility: hidden;
  width: 180px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 150%;
  left: 50%;
  margin-left: -90px;
  text-transform: none;
}

.roleName>.roleNameTooltip::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
}

.roleName:hover>.roleNameTooltip {
  visibility: visible;
}