@import '../../colors';

@mixin move-label() {
  transform: translate(0, -1.2rem) scale(0.85);
  color: $petroleum-100;
  opacity: 1;
}

@mixin move-label-ie11() {
  transform: translate(0, -1.6rem) scale(0.85);
  color: $petroleum-100;
  opacity: 1;
}

.input-wrapper, .textarea-wrapper {
  background-color: white;
  margin-top: 0.8rem;
}
//IE-11 support, corrects placement of label


.input-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;

  .input {
    padding: 2rem 0 0.8rem 0;
  }

  .label {
    position: absolute;
    transform-origin: top left;
    transform: translate(0, 0.6rem) scale(1);
    display: inline-block;
    transition: all .1s ease-in-out;

    &[data-hasvalue=true] {
      @include move-label();
    }
  }

  .input:not([readonly]):focus + .label {
    @include move-label();
    color: $waves-60 !important;
  }
}

.textarea-wrapper {
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-start;
  align-items: flex-start;

  .label {
    padding-top: 1.6rem;
    box-sizing: border-box;
    display: block;
    width: 100%;
    padding-bottom: 0.8rem;
  }

  .input {
    resize: none;
    padding: 0.8rem;
    border: 1px solid $petroleum-100;
    border-radius: 4px;
  }
}

.input {
  box-sizing: border-box;
  width: 100%;
  border: none;
  background-color: transparent;
  font-size: 1.6rem;
  color: $petroleum-100;
  outline: none;
  padding: 1.6rem 3.2rem 1.6rem 3.2rem;

  &:not([readonly]) {
    border-bottom: 1px solid $petroleum-100;
  }

  &:not([readonly]):focus {
    color: $waves-60;
    border-color: $waves-60;
  }
}

.input:disabled, .input:disabled + .label {
  opacity: .5;
}

.input:not([readonly]):focus + .label {
  color: $waves-60;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {

  .input-wrapper {
    flex-direction: row-reverse;

    .input {
      padding: 2rem 0 0.8rem 0;
    }

    .label {
      &[data-hasvalue=true] {
        @include move-label-ie11();
      }
    }

    .input:not([readonly]):focus + .label {
      @include move-label-ie11();
      color: $waves-60 !important;
    }
  }
}