/* autoprefixer grid: autoplace */
@import '../../../variables';
@import '../../../colors';

.issue-list-section,
.empty-list {
  &:not(:last-child) {
    margin-bottom: 3.2rem;
  }
}

.issue-list-section {
  max-width: $narrowContentWidth;
}

.empty-list {
  text-align: center;

  header {
    margin-bottom: 1.6rem;
  }

  header>p {
    font-weight: bold;
  }

  .empty-list-hint {
    color: $waves-60;
    font-weight: bold;
  }
}

.viewIcon1,
.viewIcon2 {
  width: 28px;
  margin-right: 12px;
  cursor: pointer;
  position: absolute;
  transition: transform .2s;
}

.viewIcon2 {
  margin-left: 44px;
}

.viewIcon1:hover,
.viewIcon2:hover {
  transform: scale(1.2)
}

.activeViewIcon {
  width: 32px;
}

.contentHeader {
  font-family: InterstateBoldCompressed;
  font-size: 40px;
  display: block;
  margin-top: 43px;
  margin-bottom: 32px;
}

.solved-issues-list {
  .solved-issue {
    /*autoprefixer: off*/
    align-items: center;
  }

  .solved-issue {
    display: grid !important;
    grid-template-areas:
      "title    reportedDate"
      "location finishedDate";
    grid-template-columns: 1fr auto;
    grid-column-gap: 1.6rem;
    grid-template-rows: auto auto;

    .issue-location,
    .issue-finishedDate {
      margin: 0;
    }

    .issue-location,
    .issue-reportedDate,
    .issue-finishedDate {
      font-size: 1.2rem;
    }

    .issue-title,
    .issue-location {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .issue-title {
      grid-area: title;
      font-size: 1.6rem;
    }

    .issue-location {
      grid-area: location;
    }

    .issue-reportedDate {
      grid-area: reportedDate;
      justify-self: end;
    }

    .issue-finishedDate {
      grid-area: finishedDate;
      justify-self: end;
      color: #30E500;
    }
  }
}

.filter-options {
  margin-bottom: 50px;
}

.filter-option {
  margin-right: 0.8rem;
  display: inline-block;
}



@media screen and (min-width: 769px) {

  /*autoprefixer: off*/
  .solved-issues-list {
    /*autoprefixer: grid*/
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 0.8rem;
  }
}