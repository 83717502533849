@import '../../../variables';
@import '../../../colors';

@mixin listStyling() {
  list-style: none;
  margin: 0;
  padding: 0;
  text-align: center;

  > li {
    margin: 0.8rem 0 0;
    padding: 0;
    display: inline-block;

    &:not(:last-child) {
      margin-right: 0.8rem;
    }
  }
}

.wrapper {
  padding: 1.6rem;
  background-color: white;
  min-height: 100%;
  box-sizing: border-box;
  max-width: $narrowContentWidth;
  text-align: center;
}

.top-category-list {
  @include listStyling;
}

.sub-category-list {
  @include listStyling;

  margin-top: 0.8rem;
}

.full-width-toggle-button {
  width: 100%;

  > label {
    width: 100%;
    text-align: center;
  }
}

.heading{
  margin-top: 150px;
}

.heading, .heading2 {
  text-align: center;
  font-family: InterstateBoldCompressed;
  font-size: 40px;
  color:#333333;
}

.heading2{
  font-size: 22px;
  margin-top: 20px;
}

.show-all-categories-button {
  background-color: $waves-60;
  color: white;
  padding: 0.8rem 1.6rem;
  font-size: 1.6rem;
  border: none;
  border-radius: 4px;
}

.featured-section {
  margin-top: 3.2rem;
  padding-top: 3.2rem;
  border-top: 1px solid $petroleum-40;
}

.featured-list {
  list-style: none;
  margin: 0 auto;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 80%;

  > li:not(:first-child) {
    margin-top: 1.6rem;
  }
}

.infoMessage{
  line-height: 1.6em;
  padding: 20px;
  border-radius: 5px;
  background-color: #d2e36f;
  margin-top: 20px;
}

@media screen and (max-width: 450px) {
  .heading{
    margin-top: 15px;
  }
}